import React from 'react';
import { Stack, useMediaQuery, useTheme } from '@mui/material';

interface PageProps {
  title: string;
  children: React.ReactNode;
}

const Page: React.FC<PageProps> = ({ title, children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  
  return (
    <div style={{position: 'relative'}}>
    <Stack  className="section" sx={{
      background: '#fff',
      height: 'auto',
      flexShrink: 0,
      pt: '130px',
      pb: '0px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',

    }}>
      <h1 style={{color: '#0C082D',fontSize: '60px',margin: 0, width: '100%', textAlign: 'center'}}>{title}</h1>
  
    </Stack>
    <Stack sx={{px: '15%', mt:'30px', backgroundColor: 'white', width: '100%', minHeight:'100vh', height: 'auto'}}>
        {children}
      </Stack>
   
    </div>
  );
};

export default Page;
