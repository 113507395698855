import React from 'react';
import MockupSection from './MockupSection';
import styled, { keyframes } from 'styled-components';
import { useState, useEffect } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { Icon } from '@iconify/react';
import SectionBG from '../sectionBG.svg';
import bgellipse from './bgellipse.svg';

import Bg from '../bg.svg';
import { Stack } from '@mui/material';
function HeroSection() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    const [scale, setScale] = useState(1);
    useEffect(() => {
        const handleResize = () => {
            const newScale = Math.max(0.50, Math.min(1, window.innerWidth / 1920));
            setScale(newScale);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial scale

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const StyledButton = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: none;
    background:  #9F61DC;
    margin: 20px auto;
    color: #FFF;
    text-align: center;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    align-self: stretch;
    cursor: pointer;
        box-shadow: 0px 4px 37.4px 0px rgba(0, 0, 0, 0.22);

    
`;


    return (
        <div id="home" className="section" style={{
            height: 'auto',
            flexShrink: 0,
            paddingTop: isMobile ? '100px' : isTablet ? '120px' : '125px',

        }}>

            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 1,
                opacity: 1,
            }}>
                <img src={Bg} alt="bg" style={{ width: '100%', height: '100%' }} />
            </div>

            {/* <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 1,
                opacity: 0.12,
                background: 'linear-gradient(to right, #CC86ED, #E5D6BD)',
                filter: 'blur(60px)',
                backgroundColor: '#F7F7F7',
            }}>


            </div> */}


            <style>
                {`
                @keyframes fadeIn {
                    from {
                        opacity: 0;
                    }
                    to {
                        opacity: 1;
                }
            }
        `}
            </style>
            <div style={{
                zIndex: 200,
                position: 'relative',
            }}>
                <div style={{
                    paddingLeft:  '10%',
                    paddingRight:  '10%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems:!isDesktop ? 'center' : 'center',
                    justifyContent: !isDesktop ? 'center' : 'center',
                    height: 'auto',
                }}>



                    <h1 style={{
                        fontSize: isDesktop ? '62px' : '40px',
                        marginTop: '40px',
                        margin: 0,
                        marginBottom:!isDesktop? '25px' : '40px',
                        color: '#0C082D',
                        textAlign: 'center',
                        fontFamily: 'Public Sans',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: 'normal',
                        animation: 'fadeIn 1s ease-in-out',
                    }}>
Get work done <span style={{ background: 'linear-gradient(to right, #B64DDB, #7355AB)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>10x faster</span>,<br/>  with your AI voice assistant.
                    </h1>
                    <div style={{
                        margin: 0,
                        color: '#0C082D',
                        textAlign: 'center',
                        fontFamily: 'Public Sans',
                        fontSize: isMobile ? '16px' : '18px',
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        lineHeight: 1.6,
                        animation: 'fadeInUp 1s ease-in-out',
                        width:  isMobile ? '100%' : isTablet ? '80%' : '60%',
                    }}>
Zweelie handles tasks, calendars, CRM, emails, research and more with Emily, your <span style={{ color: '#000',fontWeight: 700 }}>emotion-aware assistant</span>, giving you time for what matters.                   </div>
                    <StyledButton
                        onClick={() => window.location.href = "https://app.zweelie.com/login"}
                        style={{
                            marginTop: !isDesktop ? '25px' : '30px',
                            width: !isDesktop ? '100%' : '320px',
                            height: !isDesktop ? '50px' : '65px',
                            fontSize: !isDesktop ? '18px' : '23px',
                            animation: 'fadeInUp 1s ease-in-out',
                            paddingLeft: !isDesktop ? '20px' : '20px',
                            paddingRight: !isDesktop ? '20px' : '20px',
                        }}>
                        Get More Done

                        
                    </StyledButton>


                    <div style={{
                        marginTop: !isDesktop ? '0px' : '5px',
                        color: '#676279',
                        textAlign: 'center',
                        fontFamily: 'Public Sans',
                        fontSize: !isDesktop ? '14px' : '17px',
                        width: '100%',
                        animation: 'fadeInUp 1s ease-in-out'

                    }}>
                        Try for free for 7 days                </div>

                    <div style={{
                        marginTop: !isDesktop ? '25px' : '15px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                        marginBottom: !isDesktop ? '20px' : '20px',
                        gap: '5px',
                        animation: 'fadeInUp 1s ease-in-out'
                    }}>
                        <span style={{
                            color: '#676279',
                            textAlign: 'center',
                            fontFamily: 'Public Sans',
                            fontSize: '18px',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            lineHeight: 'normal',
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: '5px',
                        }}>
                            Backed by
                        </span>
                        <LaunchLogo />
                    </div>



                </div>


                {isMobile ? <div style={{
                    width: '100%',
                    height: `${scale * 1055}px`,
                    overflow: 'hidden'

                }}>
                    <div style={{
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                        marginTop: '20px',
                        transform: `scale(0.60)`,
                        transformOrigin: 'top center',
                    }}>

                        <MockupSection isMobile={isMobile} />
                    </div>
                </div> : <div style={{
                    width: '100%',
                    height: `${scale * 850}px`,
                    overflow: 'hidden'


                }}>
                    <div style={{

                        width: '100%',
                        height: '100%',
                        position: 'relative',
                        marginTop: '20px',
                        transform: `scale(${scale})`,
                        transformOrigin: 'top center'
                    }}>

                        <MockupSection />
                    </div>
                </div>}


                <div style={{
        
                    color: '#0C082D',
                    paddingLeft: !isDesktop ? '30px' : '10%',
                    paddingRight: !isDesktop ? '30px' : '10%',
                    textAlign: 'center',
                  
                    paddingTop: '50px',
                    paddingBottom:  isDesktop ? '50px' : '0px',
                    position: 'relative',
                    
                }}>
                  <img src={bgellipse} alt="sectionBG" style={{ width: '100%', height: '100%', objectFit: 'cover',position: 'absolute', top: 0, left: 0, zIndex: -10 ,filter: 'blur(112px)'}} />

                  <span style={{ textAlign: isDesktop ? 'left' : 'center', color: '#0C082D', fontSize: '25px', fontWeight: 400 ,zIndex: 10}}>
                  Managing tasks and tools consume valuable time, <span style={{ background: 'linear-gradient(to right, #B64DDB, #7355AB)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>leaving less for what matters.</span>
                  </span>
                 
                </div>



            </div>
        </div>
    );
}

export default HeroSection;

const LaunchLogo = () => {
    return (
        <svg width="103" height="28" viewBox="0 0 103 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.976" d="M95.1514 0.000735586C95.6997 -0.0157575 96.107 0.246381 96.4011 0.658154C98.2686 3.66468 100.136 6.67121 102.003 9.67774C102.256 10.2282 102.148 10.7052 101.677 11.1087C98.5926 12.2084 95.4179 12.4976 92.1526 11.976C91.0339 11.7813 89.9445 11.4922 88.8847 11.1087C88.3342 10.5846 88.2876 10.0209 88.7446 9.41753C90.5111 6.62754 92.2544 3.82344 93.9735 1.00515C94.1929 0.644149 94.4571 0.326174 94.7671 0.0511362C94.9821 0.0101339 94.8545 0.0304047 95.1514 0.000735586ZM94.9539 4.47413C94.7447 4.57134 94.5271 4.67251 94.3002 4.77773C93.3459 5.61952 93.3152 6.48684 94.2069 7.37949C95.1789 7.95288 96.0351 7.82278 96.7746 6.98919C97.3087 5.4463 96.7018 4.60792 94.9539 4.47413Z" fill="#0C082D" fill-opacity="0.6" />
            <path opacity="0.977" d="M51.2579 11.7588C52.1598 11.7359 52.7667 12.1119 53.0787 12.8862C53.141 17.3092 53.141 21.7323 53.0787 26.1553C52.8116 26.8933 52.2513 27.2692 51.398 27.2827C50.9052 27.2661 50.4539 27.136 50.0441 26.8925C47.4712 23.8818 44.919 20.8609 42.3875 17.8296C42.3329 17.8478 42.2862 17.8767 42.2475 17.9163C42.2164 19.3618 42.1853 20.8072 42.1541 22.2526C41.7213 25.6901 39.6515 27.5837 35.9448 27.9332C33.7213 28.1855 31.6982 27.7229 29.8757 26.5456C28.5835 25.4024 27.852 24.0004 27.6814 22.3394C27.6192 19.304 27.6192 16.2685 27.6814 13.2331C28.0887 12.172 28.8824 11.7816 30.0624 12.0623C30.8762 12.3272 31.2964 12.8619 31.323 13.6667C31.3264 16.5321 31.3885 19.3941 31.5097 22.2526C31.9791 23.8584 33.115 24.6679 34.9178 24.681C36.9727 24.6659 38.1398 23.712 38.4192 21.819C38.4504 19.0727 38.4815 16.3264 38.5127 13.58C38.5801 12.4621 39.2183 11.884 40.4267 11.8455C40.9641 11.8498 41.4621 11.9799 41.9207 12.2358C44.4938 15.3257 47.0771 18.4045 49.6706 21.4721C49.7483 18.7262 49.7951 15.9799 49.8106 13.2331C49.9117 12.4042 50.3941 11.9129 51.2579 11.7588Z" fill="#0C082D" fill-opacity="0.6" />
            <path opacity="0.975" d="M19.2967 11.8236C20.3623 11.9188 20.9792 12.4479 21.4214 13.3237C23.3565 17.4679 25.3173 21.6018 27.3038 25.7255C27.4976 27.0164 26.9063 27.7392 25.5298 27.8937C24.7789 27.8631 24.2187 27.545 23.849 26.9397C23.451 26.076 23.0619 25.2087 22.682 24.3379C20.2548 24.2367 17.8272 24.2223 15.3989 24.2945C14.9609 25.1966 14.5407 26.1072 14.1384 27.0265C13.8732 27.5038 13.453 27.7784 12.8778 27.8504C9.26743 27.9082 5.65711 27.9082 2.0467 27.8504C1.21093 27.6811 0.728518 27.2041 0.599458 26.4194C0.53716 22.0831 0.53716 17.7468 0.599458 13.4104C0.81145 12.5083 1.41836 12.0169 2.42019 11.9361C3.40664 11.9808 4.01355 12.4434 4.24092 13.3237C4.28764 17.1106 4.30322 20.8977 4.28764 24.6848C6.65388 24.7138 9.01922 24.6848 11.3839 24.5981C13.2358 20.6954 15.0877 16.7928 16.9395 12.8901C17.3494 12.3487 17.8941 12.0018 18.5736 11.8493C18.981 11.8103 18.7403 11.8256 19.2967 11.8236ZM18.947 16.4458C18.207 17.9882 17.5222 19.5492 16.8929 21.129C18.3259 21.158 19.7576 21.129 21.188 21.0423C20.5098 19.5426 19.8405 18.0393 19.1805 16.5325C19.1179 16.4673 19.0401 16.4384 18.947 16.4458Z" fill="#0C082D" fill-opacity="0.6" />
            <path opacity="0.974" d="M62.9263 11.8481C64.9425 11.7599 66.8411 12.1502 68.622 13.019C70.0022 13.7563 70.2824 14.7537 69.4624 16.011C68.8031 16.5324 68.0561 16.6625 67.2214 16.4012C65.1177 14.8931 62.939 14.7773 60.6854 16.0543C58.9475 17.574 58.4028 19.4097 59.0513 21.5614C59.9916 23.8559 61.7813 24.8822 64.4203 24.6402C65.4937 24.5012 66.4275 24.0965 67.2214 23.4261C68.2876 22.9113 69.1747 23.0992 69.8826 23.9898C70.1134 24.6255 70.0512 25.2325 69.6958 25.811C68.2228 27.1063 66.4643 27.8146 64.4203 27.9359C60.7213 28.1765 57.8734 26.8611 55.8767 23.9898C54.3928 21.1552 54.4551 18.3509 56.0635 15.5773C57.7245 13.3389 60.0122 12.0957 62.9263 11.8481Z" fill="#0C082D" fill-opacity="0.6" />
            <path opacity="0.978" d="M73.1026 11.9343C74.0932 11.8948 74.7623 12.2996 75.1101 13.1485C75.1262 14.8171 75.173 16.4793 75.2501 18.1352C77.4597 18.1786 79.6695 18.1931 81.8795 18.1786C81.864 16.6461 81.8795 15.114 81.9262 13.5821C82.2727 12.1706 83.1909 11.6936 84.6808 12.1511C85.1524 12.387 85.4481 12.7484 85.5678 13.2352C85.63 17.6871 85.63 22.1391 85.5678 26.591C85.0451 27.794 84.127 28.1553 82.8133 27.6751C82.4129 27.4763 82.1483 27.1727 82.0196 26.7645C81.8852 25.005 81.8385 23.2416 81.8795 21.4742C79.6695 21.4597 77.4597 21.4742 75.2501 21.5176C75.173 23.2313 75.1262 24.9513 75.1101 26.6778C74.6364 27.6997 73.8116 28.0611 72.6357 27.7618C71.9258 27.4769 71.5367 26.9711 71.4686 26.2441C71.4063 22.0523 71.4063 17.8605 71.4686 13.6688C71.5739 12.719 72.1186 12.1408 73.1026 11.9343Z" fill="#0C082D" fill-opacity="0.6" />
            <path opacity="0.961" d="M95.2316 12.8828C97.1719 13.0802 97.9347 14.0631 97.5193 15.8315C96.8423 17.0018 96.142 18.1583 95.4184 19.3005C94.7256 18.2162 94.0561 17.1176 93.4109 16.005C92.8431 14.3538 93.4501 13.3131 95.2316 12.8828Z" fill="#0C082D" fill-opacity="0.6" />
        </svg>

    );
}