import React, { useEffect, useState } from 'react';
import WebMockup from './WebMockup';
import MobileMockup, { EventMessageItem, TaskMessageItem } from './MobileMockup';
import momentTz from 'moment-timezone';
import moment from 'moment';
import { useMediaQuery } from '@mui/material';
// Define the shape of a message
interface MessageItem {
    content_type: 'text' | 'link' | 'image' | 'map' | 'phone_number' | 'email_address' | 'email' | 'task' | 'event' | 'website' | 'contact' | 'flight' | 'location' | 'crm_record';
    content: any;
    created_at: string;
    sender: 'emily' | 'user';
}


export type { MessageItem };

const MockupSection = ({ isMobile = false }: { isMobile?: boolean }) => {
    const [messages, setMessages] = useState<MessageItem[]>([]);
    const [calendarEvents, setCalendarEvents] = useState<EventMessageItem[]>([]);
    const [tasks, setTasks] = useState<TaskMessageItem[]>([]);
    const [dateTime, setDateTime] = useState<Date>(new Date(new Date().setHours(9, 0, 0, 0)));
    const [calendarDateTime, setCalendarDateTime] = useState<Date>(new Date(new Date().setHours(9, 0, 0, 0)));

    const [view, setView] = useState<'home' | 'crm'>('home');


    const [crmRows, setCRMRows] = useState([
        {
            company: "Shopify", list: "customers", company_type: "Public", last_activity: {
                created_at: momentTz.tz('America/Toronto').subtract(1, 'days').format('YYYY-MM-DD'),
                type: "deal"
            }, status: {
                name: "Closed-Lost",
                color: "pastel_red"
            }, categories: ["E-commerce", "SAAS", "Internet"]
        },
        {
            company: "Apple", list: "customers", company_type: "Public", last_activity: {
                created_at: `${momentTz.tz('America/Toronto').subtract(3, 'days').format('YYYY-MM-DD')}T06:00:00-04:00`,
                type: "note"
            }, status: {
                name: "Closed-Won",
                color: "pastel_green"
            }, categories: ["Technology", "Computer Hardware", "Artificial Intelligence"]
        },
        {
            company: "Meta", list: "customers", company_type: "Public", last_activity: {
                created_at: `${momentTz.tz('America/Toronto').subtract(11, 'days').format('YYYY-MM-DD')}T10:00:00-04:00`,
                type: "deal"
            }, status: {
                name: "Closed-Lost",
                color: "pastel_red"
            }, categories: ["Technology", "Internet", "Artificial Intelligence"]
        },
        {
            company: "Warner Bros", list: "customers", company_type: "Public", last_activity: {
                created_at: `${momentTz.tz('America/Toronto').subtract(12, 'days').format('YYYY-MM-DD')}T09:00:00-04:00`,
                type: "note"
            }, status: {
                name: "Proposal Sent",
                color: "pastel_cyan"
            }, categories: ["Media", "Movies & TV", "Broadcasting"]
        },
        {
            company: "Nvidia", list: "customers", company_type: "Public", last_activity: {
                created_at: `${momentTz.tz('America/Toronto').subtract(13, 'days').format('YYYY-MM-DD')}T15:00:00-04:00`,
                type: "deal"
            }, status: {
                name: "In Discussion",
                color: "pastel_orange"
            }, categories: ["Technology", "Computer Hardware", "Artificial Intelligence"]
        },
        {
            company: "Airbnb", list: "customers", company_type: "Public", last_activity: {
                created_at: `${momentTz.tz('America/Toronto').subtract(15, 'days').format('YYYY-MM-DD')}T08:00:00-04:00`,
                type: "note"
            }, status: {
                name: "Interested",
                color: "pastel_pink"
            }, categories: ["Travel & Leisure", "Marketplace", "Internet"]
        },
        {
            company: "Google", list: "customers", company_type: "Public", last_activity: {
                created_at: `${momentTz.tz('America/Toronto').subtract(16, 'days').format('YYYY-MM-DD')}T07:00:00-04:00`,
                type: "deal"
            }, status: {
                name: "Prospect",
                color: "pastel_yellow"
            }, categories: ["Technology", "Cloud Services", "Internet"]
        },
        {
            company: "Sequoia Capital", list: "investors", company_type: "Private", last_activity: {
                created_at: `${momentTz.tz('America/Toronto').subtract(18, 'days').format('YYYY-MM-DD')}T16:00:00-04:00`,
                type: "deal"
            }, status: {
                name: "Closed-Won",
                color: "pastel_green"
            }, categories: ["Venture Capital", "Fundraising", "Investment"]
        },
        {
            company: "Stripe", list: "customers", company_type: "Public", last_activity: {
                created_at: `${momentTz.tz('America/Toronto').subtract(25, 'days').format('YYYY-MM-DD')}T12:00:00-04:00`,
                type: "note"
            }, status: {
                name: "Prospect",
                color: "pastel_yellow"
            }, categories: ["Payments", "Financial Services", "Web Services & Apps"]
        },
        {
            company: "Disney", list: "customers", company_type: "Public", last_activity: {
                created_at: `${momentTz.tz('America/Toronto').subtract(30, 'days').format('YYYY-MM-DD')}T12:00:00-04:00`,
                type: "note"
            }, status: {
                name: "Prospect",
                color: "pastel_yellow"
            }, categories: ["Media", "Movies & TV", "Broadcasting"]
        }
    ]);


    useEffect(() => {
        setCalendarDateTime(dateTime);
    }, [dateTime]);

    const addCalendarEvent = async (newEvent: EventMessageItem) => {
        setCalendarEvents((prevEvents: EventMessageItem[]) => [newEvent, ...prevEvents]);
    }

    const addTask = async (newTask: TaskMessageItem) => {
        setTasks((prevTasks: TaskMessageItem[]) => [newTask, ...prevTasks]);
    }

    const addCRMRecord = async (newCRMRecord: 'a16z' | 'microsoft') => {



        const records = {
            "a16z": {
                company: "A16z", list: "investors", company_type: "private", last_activity: {
                    created_at: `${momentTz.tz('America/Toronto').format('YYYY-MM-DDTHH:mm:ssZ')}`,
                    type: "note"
                }, status: {
                    name: "Prospect",
                    color: "pastel_yellow"
                }, categories: ["Venture Capital", "Fundraising", "Investment"]
            },
            "microsoft": {
                company: "Microsoft", list: "customers", company_type: "Public", last_activity: {
                    created_at: `${momentTz.tz('America/Toronto').format('YYYY-MM-DDTHH:mm:ssZ')}`,
                    type: "note"
                }, status: {
                    name: "Prospect",
                    color: "pastel_yellow"
                }, categories: ["Software", "Cloud Services", "Internet"]
            }
        }

        setCRMRows((prevCRMRecords: any[]) => [records[newCRMRecord], ...prevCRMRecords]);

    }

    const addMessage = async (newMessage: MessageItem) => {
        setMessages((prevMessages: MessageItem[]) => {
            const lastMessage = prevMessages[prevMessages.length - 1];
            const currentTime = new Date().getTime();
            const lastMessageTime = lastMessage ? new Date(lastMessage.created_at).getTime() : 0;

            if (lastMessage && lastMessage.content === newMessage.content && lastMessage.sender === 'emily' && (currentTime - lastMessageTime) < 5000) {
                return prevMessages; // Skip if the message is the same as the last content from Emily within 5 seconds
            }

            return [...prevMessages, newMessage];
        });
    };


    useEffect(() => {
        if (messages.length > 0) {
            if (messages[messages.length - 1].content_type === 'event' && messages[messages.length - 1].content.is_new) {
                addCalendarEvent(messages[messages.length - 1].content as EventMessageItem);
            }




            if (messages[messages.length - 1].content_type === 'task') {
                if (messages[messages.length - 1].content.is_new) {
                    addTask(messages[messages.length - 1].content as TaskMessageItem);
                } else {
                    setTasks((prevTasks: TaskMessageItem[]) => {

                        return prevTasks.map(task => {
                            if (task.task_id === messages[messages.length - 1].content.task_id) {
                                const updatedContent = messages[messages.length - 1].content;
                                const isContentDifferent = Object.keys(updatedContent).some(key => {
                                    const taskKey = key as keyof TaskMessageItem; // Type assertion
                                    return task[taskKey] !== updatedContent[taskKey];
                                });
                                return isContentDifferent ? { ...task, ...updatedContent } : task;
                            }
                            return task;
                        });
                    });

                }
            }
        }

    }, [messages]);




    useEffect(() => {

        addCalendarEvent({
            "is_new": true,
            "event_id": "47dce828-cb00-48ca-868e-f243eac4486e",
            "provider": "google_calendar",
            "updated_at": "2024-10-31T13:31:43.057Z",
            "status": "confirmed",
            "provider_event_id": "sotmuf4s3c1aorjb4ejflg0u5g",
            "title": "Follow up with Aaron",
            "start_time": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T11:00:00-04:00`,
            "end_time": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T11:30:00-04:00`,
            "display_timezone": "America/Toronto",
            "calendar_email": "alex@acmeinc.com",
            "description": "Follow up discussion",
            "all_day": false,
            "event_link": "https://google.com",
            "attendees": [
                {
                    "name": "Aaron Smith",
                    "email": "aaron.smith@acmeinc.com",
                    "is_me": false,
                    "response_status": "accepted"
                }
            ],
            "actions": [
                {
                    "type": "join",
                    "entry_point": "video",
                    "location": "https://meet.google.com/ags-etik-mgh",
                    "provider": "Google Meet"
                },
                {
                    "type": "join",
                    "entry_point": "phone",
                    "location": "tel:+1-778-747-5877",
                    "pin": "375531778",
                    "provider": "phone"
                }
            ],
            "creator": {
                "email": "alex@acmeinc.com"
            },
            "integration_id": "d7ae0d39-7a35-4f6d-ac1b-daf0bdf1c084"
        } as EventMessageItem);

        addCalendarEvent({
            "is_new": true,
            "event_id": "47dce828-cb00-48ca-868e-f243eac4486e",
            "provider": "google_calendar",
            "updated_at": "2024-10-31T13:31:43.057Z",
            "status": "confirmed",
            "provider_event_id": "sotmuf4s3c1aorjb4ejflg0u5g",
            "title": "Flight to Toronto from Montreal",
            "start_time": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T16:30:00-04:00`,
            "end_time": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T17:38:00-04:00`,
            "display_timezone": "America/Toronto",
            "calendar_email": "alex@acmeinc.com",
            "description": "Flight to Toronto from Montreal. AC895.",
            "all_day": false,
            "event_link": "https://www.google.com/calendar/event?eid=c290bXVmNHMzYzFhb3JqYjRlamZsZzB1NWcgYWxleEBhY21laW5jLmNvbQ",
            "attendees": [],
            "actions": [
                {
                    "type": "location",
                    "entry_point": "map",
                    "location": "Montréal Airport YUL",
                    "provider": "map"
                }
            ],
            "creator": {
                "email": "alex@acmeinc.com"
            },
            "integration_id": "d7ae0d39-7a35-4f6d-ac1b-daf0bdf1c084"
        } as EventMessageItem
        );

        addTask({
            is_new: true,
            task_id: "dkjqjhjlkjhdljkhlkj",
            workspace_id: "9854c563-7c2f-4e02-8fa9-932f3e99f08b",
            list_id: "f653bce2-6c83-4ba7-bff1-163ea4520def",
            parent_task_id: "",
            name: "Review advertising budget",
            description: "Review the advertising budget for the new product",
            "position": 1,
            "status": "2f9a61b1-1234-4e2a-a412-fb147ff716b2",
            "priority": "high",
            "due_date": `${momentTz.tz('America/Toronto').add(5, 'days').format('YYYY-MM-DD')}T14:00:00-04:00`,
            "time_estimate": "",
            "file_attachments": [],
            "created_by": {
                "user_id": "4a818a95-f2ba-4586-b4c3-83d2702f2557",
                "name": "Alex Williams"
            },
            "created_at": "2024-10-30T04:22:10.190Z",
            "updated_at": "2024-10-30T04:22:10.190Z",
            "team_id": "583b4c9e-2389-47c4-a6af-2bee83a89377",
            "team_name": "General",
            "list_name": "Tasks",
            "list_color": "pastel_olive",
            "status_name": "To Do",
            "status_color": "pastel_orchid",
            "team_color": "pastel_orange",
        } as TaskMessageItem);


        addTask({
            is_new: true,
            task_id: "iuyiuosysuyoiuyiou",
            workspace_id: "9854c563-7c2f-4e02-8fa9-932f3e99f08b",
            list_id: "f653bce2-6c83-4ba7-bff1-163ea4520def",
            parent_task_id: "",
            name: "Email Peter campaign results",
            description: "Email Peter to discuss the campaign results",
            "position": 1,
            "status": "2f9a61b1-1234-4e2a-a412-fb147ff716b2",
            "priority": "high",
            "due_date": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T13:20:00-04:00`,
            "time_estimate": "",
            "file_attachments": [],
            "created_by": {
                "user_id": "4a818a95-f2ba-4586-b4c3-83d2702f2557",
                "name": "Alex Williams"
            },
            "created_at": "2024-10-30T04:22:10.190Z",
            "updated_at": "2024-10-30T04:22:10.190Z",
            "team_id": "583b4c9e-2389-47c4-a6af-2bee83a89377",
            "team_name": "General",
            "list_name": "Tasks",
            "list_color": "pastel_olive",
            "status_name": "To Do",
            "status_color": "pastel_orchid",
            "team_color": "pastel_orange",
        } as TaskMessageItem);



        addTask({
            is_new: true,
            task_id: "dkjqjhjlkjhdljkhlkj",
            workspace_id: "9854c563-7c2f-4e02-8fa9-932f3e99f08b",
            list_id: "f653bce2-6c83-4ba7-bff1-163ea4520def",
            parent_task_id: "",
            name: "Set up a meeting with Garry",
            description: "Set up a meeting with Garry to discuss the new proposal",
            "position": 1,
            "status": "2f9a61b1-1234-4e2a-a412-fb147ff716b2",
            "priority": "high",
            "due_date": `${momentTz.tz('America/Toronto').subtract(1, 'days').format('YYYY-MM-DD')}T15:00:00-04:00`,
            "time_estimate": "",
            "file_attachments": [],
            "created_by": {
                "user_id": "4a818a95-f2ba-4586-b4c3-83d2702f2557",
                "name": "Alex Williams"
            },
            "created_at": "2024-10-30T04:22:10.190Z",
            "updated_at": "2024-10-30T04:22:10.190Z",
            "team_id": "583b4c9e-2389-47c4-a6af-2bee83a89377",
            "team_name": "General",
            "list_name": "Tasks",
            "list_color": "pastel_olive",
            "status_name": "To Do",
            "status_color": "pastel_orchid",
            "team_color": "pastel_orange",
        } as TaskMessageItem);


        addTask({
            "is_new": true,
            "task_id": "agfdgfgfdbwtrwrbt",
            "workspace_id": "9854c563-7c2f-4e02-8fa9-932f3e99f08b",
            "list_id": "f653bce2-6c83-4ba7-bff1-163ea4520def",
            "parent_task_id": "",
            "name": "Develop marketing strategy",
            "description": "Develop a marketing strategy tailored for the executive team",
            "position": 1,
            "status": "2f9a61b1-1234-4e2a-a412-fb147ff716b2",
            "priority": "high",
            "due_date": "",
            "time_estimate": "",
            "file_attachments": [],
            "created_by": {
                "user_id": "4a818a95-f2ba-4586-b4c3-83d2702f2557",
                "name": "Alex Williams"
            },
            "created_at": "2024-10-30T04:22:10.190Z",
            "updated_at": "2024-10-30T04:22:10.190Z",
            "team_id": "583b4c9e-2389-47c4-a6af-2bee83a89377",
            "team_name": "General",
            "list_name": "Tasks",
            "list_color": "pastel_olive",
            "status_name": "In Progress",
            "status_color": "pastel_blue",
            "team_color": "pastel_orange"
        } as TaskMessageItem);



        const timeline: any[] = [

            { content_type: 'text', content: 'Hello Alex! How can I assist you today?', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Search and create a CRM record for a US tech and investment company.', sender: 'user', created_at: new Date().toISOString() },
            { content_type: 'view_update', content: 'crm', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'add_crm_record', content: 'microsoft', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'add_crm_record', content: 'a16z', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: "I've created a CRM record for Microsoft and its CEO and a CRM record for A16z and its managing partner.", sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Find Apple in my CRM.', sender: 'user', created_at: new Date().toISOString() },
            {
                content_type: 'crm_record', content: {
                    company: "Apple", list: "customers", company_type: "Public", last_activity: {
                        created_at: '',
                        type: "note"
                    }, status: {
                        name: "Closed-Won",
                        color: "pastel_green"
                    },
                    description: "Apple is a multinational technology company that designs, manufactures, and markets smartphones, tablets, personal computers, and accessories.",
                    address: "Apple Park, Cupertino, CA 95014, USA"
                }, sender: 'emily', created_at: new Date().toISOString()
            },
            { content_type: 'text', content: 'Here is the CRM record for Apple.', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Please set up a project update meeting for friday at 10 AM?', sender: 'user', created_at: new Date().toISOString() },
            { content_type: 'view_update', content: 'home', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Sure! Would you like me it to be a google meet or in person meeting? Would you also like me to add any attendees?', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Yes, please add a google meet link and add Garry and Jane as attendees.', sender: 'user', created_at: new Date().toISOString() },
            { content_type: 'calender_date_time_update', content: `${momentTz.tz('America/Toronto').day(5).add(momentTz.tz('America/Toronto').day() >= 5 ? 7 : 0, 'days').format('YYYY-MM-DD')}T09:00:00-04:00`, sender: 'emily', created_at: new Date().toISOString() },
            {
                content_type: 'event', content: {
                    "is_new": true,
                    "event_id": "47dce828-cb00-48ca-868e-f243eac4486e",
                    "provider": "google_calendar",
                    "updated_at": "2024-10-31T13:31:43.057Z",
                    "status": "confirmed",
                    "provider_event_id": "sotmuf4s3c1aorjb4ejflg0u5g",
                    "title": "Project update meeting",
                    "start_time": `${momentTz.tz('America/Toronto').day(5).add(momentTz.tz('America/Toronto').day() >= 5 ? 7 : 0, 'days').format('YYYY-MM-DD')}T11:00:00-04:00`,
                    "end_time": `${momentTz.tz('America/Toronto').day(5).add(momentTz.tz('America/Toronto').day() >= 5 ? 7 : 0, 'days').format('YYYY-MM-DD')}T11:30:00-04:00`,
                    "display_timezone": "America/Toronto",
                    "calendar_email": "alex@acmeinc.com",
                    "description": "Project update discussion",
                    "all_day": false,
                    "event_link": "https://www.google.com/calendar/event?eid=c290bXVmNHMzYzFhb3JqYjRlamZsZzB1NWcgYWxleEBhY21laW5jLmNvbQ",
                    "attendees": [
                        {
                            "name": "Garry Smith",
                            "email": "garry.smith@acmeinc.com",
                            "is_me": false,
                            "response_status": "accepted"
                        },
                        {
                            "name": "Jane Whitfield",
                            "email": "jane.whitfield@acmeinc.com",
                            "is_me": false,
                            "response_status": "accepted"
                        }
                    ],
                    "actions": [
                        {
                            "type": "join",
                            "entry_point": "video",
                            "location": "https://meet.google.com/ags-etik-mgh",
                            "provider": "Google Meet"
                        },
                        {
                            "type": "join",
                            "entry_point": "phone",
                            "location": "tel:+1-778-747-5877",
                            "pin": "375531778",
                            "provider": "phone"
                        }
                    ],
                    "creator": {
                        "email": "alex@acmeinc.com"
                    },
                    "integration_id": "d7ae0d39-7a35-4f6d-ac1b-daf0bdf1c084"
                }, sender: 'emily', created_at: new Date().toISOString()
            },
            { content_type: 'text', content: 'Great! I set the meeting for friday at 10 AM with a google meet link. I have also added Garry and Jane as attendees.', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Thanks, Emily!', sender: 'user', created_at: new Date().toISOString() },
            { content_type: 'text', content: "What's my schedule for today looking like?", sender: 'user', created_at: new Date().toISOString() },
            { content_type: 'calender_date_time_update', content: `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T09:35:00-04:00`, sender: 'emily', created_at: new Date().toISOString() },

            {
                content_type: 'event', content: {
                    "is_new": false,
                    "event_id": "47dce828-cb00-48ca-868e-f243eac4486e",
                    "provider": "google_calendar",
                    "updated_at": "2024-10-31T13:31:43.057Z",
                    "status": "confirmed",
                    "provider_event_id": "sotmuf4s3c1aorjb4ejflg0u5g",
                    "title": "Follow up with Aaron",
                    "start_time": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T11:00:00-04:00`,
                    "end_time": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T11:30:00-04:00`,
                    "display_timezone": "America/Toronto",
                    "calendar_email": "alex@acmeinc.com",
                    "description": "Follow up discussion",
                    "all_day": false,
                    "event_link": "https://google.com",
                    "attendees": [
                        {
                            "name": "Aaron Smith",
                            "email": "aaron.smith@acmeinc.com",
                            "is_me": false,
                            "response_status": "accepted"
                        }
                    ],
                    "actions": [
                        {
                            "type": "join",
                            "entry_point": "video",
                            "location": "https://meet.google.com/ags-etik-mgh",
                            "provider": "Google Meet"
                        },
                        {
                            "type": "join",
                            "entry_point": "phone",
                            "location": "tel:+1-778-747-5877",
                            "pin": "375531778",
                            "provider": "phone"
                        }
                    ],
                    "creator": {
                        "email": "alex@acmeinc.com"
                    },
                    "integration_id": "d7ae0d39-7a35-4f6d-ac1b-daf0bdf1c084"
                }, sender: 'emily', created_at: new Date().toISOString()
            },
            {
                content_type: 'event', content: {
                    "is_new": false,
                    "event_id": "47dce828-cb00-48ca-868e-f243eac4486e",
                    "provider": "google_calendar",
                    "updated_at": "2024-10-31T13:31:43.057Z",
                    "status": "confirmed",
                    "provider_event_id": "sotmuf4s3c1aorjb4ejflg0u5g",
                    "title": "Flight to Toronto from Montreal",
                    "start_time": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T16:30:00-04:00`,
                    "end_time": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T17:38:00-04:00`,
                    "display_timezone": "America/Toronto",
                    "calendar_email": "alex@acmeinc.com",
                    "description": "Flight to Toronto from Montreal. AC895.",
                    "all_day": false,
                    "event_link": "https://www.google.com/calendar/event?eid=c290bXVmNHMzYzFhb3JqYjRlamZsZzB1NWcgYWxleEBhY21laW5jLmNvbQ",
                    "attendees": [],
                    "actions": [
                        {
                            "type": "location",
                            "entry_point": "map",
                            "location": "Montréal Airport YUL",
                            "provider": "map"
                        }
                    ],
                    "creator": {
                        "email": "alex@acmeinc.com"
                    },
                    "integration_id": "d7ae0d39-7a35-4f6d-ac1b-daf0bdf1c084"
                }, sender: 'emily', created_at: new Date().toISOString()
            },
            { content_type: 'time_update', content: `10:55`, sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'You have a meeting with Aaron today at 11 AM. It is a follow up meeting from yesterday. You will be meeting at the google meet link. You have no other meetings today. However you have your flight to Toronto in the afternoon.', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Please give me the google meet link for my upcoming meeting.', sender: 'user', created_at: new Date().toISOString() },
            { content_type: 'link', content: 'https://meet.google.com/ags-etik-mgh', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Here is the google meet link for your meeting with Aaron today at 11 AM. Is there anything else you need help with?', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'time_update', content: `11:30`, sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Can you create a task for me called “Client Proposal” with a due date of next Tuesday at 4 pm and high priority?', sender: 'user', created_at: new Date().toISOString() },
            {
                content_type: 'task', content: {
                    "is_new": true,
                    "task_id": "8a2a168b-c0cd-4026-9043-3b7370535e64",
                    "workspace_id": "9854c563-7c2f-4e02-8fa9-932f3e99f08b",
                    "list_id": "f653bce2-6c83-4ba7-bff1-163ea4520def",
                    "parent_task_id": "",
                    "name": "Client proposal",
                    "description": "Create a proposal for XYZ Corporation",
                    "position": 1,
                    "status": "2f9a61b1-1234-4e2a-a412-fb147ff716b2",
                    "priority": "high",
                    "due_date": `${momentTz.tz('America/Toronto').day(2).add(momentTz.tz('America/Toronto').day() >= 2 ? 7 : 0, 'days').format('YYYY-MM-DD')}T17:00:00.000-04:00`,
                    "time_estimate": "",
                    "file_attachments": [],
                    "created_by": {
                        "user_id": "4a818a95-f2ba-4586-b4c3-83d2702f2557",
                        "name": "Alex Williams"
                    },
                    "created_at": "2024-10-30T04:22:10.190Z",
                    "updated_at": "2024-10-30T04:22:10.190Z",
                    "team_id": "583b4c9e-2389-47c4-a6af-2bee83a89377",
                    "team_name": "General",
                    "list_name": "Tasks",
                    "list_color": "pastel_olive",
                    "status_name": "To Do",
                    "status_color": "pastel_orchid",
                    "team_color": "pastel_orange"
                }, sender: 'emily', created_at: new Date().toISOString()
            },

            { content_type: 'text', content: 'I have created the task called “Client proposal” with a due date of next Tuesday at 4 pm and high priority. Would you like me to add any assignees to the task, or any description?', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Yes, please add myself, Jane and Larry as assignees. Also, please add a description that says “Create a proposal for XYZ Corporation”.', sender: 'user', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'I have added yourself, Jane and Larry as assignees to the task and added the description. Have a productive day!', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'time_update', content: `12:00`, sender: 'emily', created_at: new Date().toISOString() },

            { content_type: 'text', content: 'Working on the proposal now. Please start time tracking.', sender: 'user', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Got it. Time tracking for the task called “Client proposal” has started. Just tell me when you want to stop it.', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'time_update', content: `12:30`, sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Stop time tracking.', sender: 'user', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Time tracking for the task called “Client proposal” has stopped. Did you want to add a description of what you have done so far?', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Yes, please add a description that says “Started working on the proposal”.', sender: 'user', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'I have added the description to the task.', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'time_update', content: `12:50`, sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Do I have any emails today from Steve?', sender: 'user', created_at: new Date().toISOString() },
            {
                content_type: 'email', content:
                {
                    "type": "search",
                    "provider": "gmail",
                    "provider_message_id": "y8y080y9",
                    "provider_thread_id": "y8y080y9",
                    "sender": "steve@acmeinc.com",
                    "date": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T14:00:00.000Z`,
                    "from": [
                        {
                            "address": "steve@acmeinc.com",
                            "name": "Steve Amerson"
                        }
                    ],
                    "to": [
                        {
                            "address": "alex@acmeinc.com",
                            "name": "Alex Williams"
                        }
                    ],
                    "cc": [],
                    "bcc": [],
                    "subject": "Great meeting with Doug",
                    "reply_to": [],
                    "text": "Hello Alex,\n\nI had a great meeting with Doug today. We discussed the proposal, he's thinking about it. I suggest you follow up with him tomorrow at 10 AM.\n\n",
                    "html": "<html><body>Hello Alex,<br><br>I had a great meeting with Doug today. We discussed the proposal, he's thinking about it. I suggest you follow up with him tomorrow at 10 AM.<br><br></body></html>",
                    "attachments": []
                }
                , sender: 'emily', created_at: new Date().toISOString()
            },

            {
                content_type: 'email', content:
                {
                    "type": "search",
                    "provider": "gmail",
                    "provider_message_id": "y8y080y9",
                    "provider_thread_id": "y8y080y9",
                    "sender": "steve@acmeinc.com",
                    "date": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T15:30:00.000Z`,
                    "from": [
                        {
                            "address": "steve@acmeinc.com",
                            "name": "Steve Amerson"
                        }
                    ],
                    "to": [
                        {
                            "address": "alex@acmeinc.com",
                            "name": "Alex Williams"
                        }
                    ],
                    "cc": [],
                    "bcc": [],
                    "subject": "Re: Task “Client Proposal”",
                    "reply_to": [],
                    "text": "Hi Alex,\n\nI received the task you created and assigned to me regarding the client proposal. I'll review the details and get back to you if I have any questions.\n\n",
                    "html": "<html><body>Hi Alex,<br><br>I received the task you created and assigned to me regarding the client proposal. I'll review the details and get back to you if I have any questions.<br><br></body></html>",
                    "attachments": []
                }
                , sender: 'emily', created_at: new Date().toISOString()
            },
            { content_type: 'text', content: 'You have 2 emails from Steve today. The first one is about the client meeting he had with Doug and the second one is about the task you created.', sender: 'emily', created_at: new Date().toISOString() },

            { content_type: 'text', content: 'Any action required from me?', sender: 'user', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'The only action item from the emails is to schedule a follow up meeting with Doug tomorrow at 10 AM. Shall I set up a meeting or create a task for it?', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Create the task.', sender: 'user', created_at: new Date().toISOString() },

            {
                content_type: 'task', content: {
                    "is_new": true,
                    "task_id": "uyoiuayoiusfhisudfhasdf",
                    "workspace_id": "9854c563-7c2f-4e02-8fa9-932f3e99f08b",
                    "list_id": "f653bce2-6c83-4ba7-bff1-163ea4520def",
                    "parent_task_id": "",
                    "name": "Follow up with Doug",
                    "description": "Follow up with Doug by 10 AM tomorrow",
                    "position": 1,
                    "status": "2f9a61b1-1234-4e2a-a412-fb147ff716b2",
                    "priority": "high",
                    "due_date": `${momentTz.tz('America/Toronto').add(1, 'days').format('YYYY-MM-DD')}T11:00:00-04:00`,
                    "time_estimate": "",
                    "file_attachments": [],
                    "created_by": {
                        "user_id": "4a818a95-f2ba-4586-b4c3-83d2702f2557",
                        "name": "Alex Williams"
                    },
                    "created_at": "2024-10-30T04:22:10.190Z",
                    "updated_at": "2024-10-30T04:22:10.190Z",
                    "team_id": "583b4c9e-2389-47c4-a6af-2bee83a89377",
                    "team_name": "General",
                    "list_name": "Tasks",
                    "list_color": "pastel_olive",
                    "status_name": "To Do",
                    "status_color": "pastel_orchid",
                    "team_color": "pastel_orange"
                }, sender: 'emily', created_at: new Date().toISOString()
            },
            { content_type: 'text', content: 'I have created the task called “Follow up with Doug” with a due date of tomorrow at 10 AM with high priority.', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'time_update', content: `13:00`, sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'What tasks do I have left for today?', sender: 'user', created_at: new Date().toISOString() },
            {
                content_type: 'task', content: {
                    "is_new": false,
                    "task_id": "6otuyoiuyouiyrqwrewtw",
                    "workspace_id": "9854c563-7c2f-4e02-8fa9-932f3e99f08b",
                    "list_id": "f653bce2-6c83-4ba7-bff1-163ea4520def",
                    "parent_task_id": "",
                    "name": "Client proposal",
                    "description": "Create a proposal for XYZ Corporation",
                    "position": 1,
                    "status": "2f9a61b1-1234-4e2a-a412-fb147ff716b2",
                    "priority": "high",
                    "due_date": `${momentTz.tz('America/Toronto').day(2).add(momentTz.tz('America/Toronto').day() >= 2 ? 7 : 0, 'days').format('YYYY-MM-DD')}T17:00:00.000-04:00`,
                    "time_estimate": "",
                    "file_attachments": [],
                    "created_by": {
                        "user_id": "4a818a95-f2ba-4586-b4c3-83d2702f2557",
                        "name": "Alex Williams"
                    },
                    "created_at": "2024-10-30T04:22:10.190Z",
                    "updated_at": "2024-10-30T04:22:10.190Z",
                    "team_id": "583b4c9e-2389-47c4-a6af-2bee83a89377",
                    "team_name": "General",
                    "list_name": "Tasks",
                    "list_color": "pastel_olive",
                    "status_name": "In Progress",
                    "status_color": "pastel_blue",
                    "team_color": "pastel_orange"
                }, sender: 'emily', created_at: new Date().toISOString()
            },
            {
                content_type: 'task', content: {
                    "is_new": false,
                    "task_id": "uyoiuayoiusfhisudfhasdf",
                    "workspace_id": "9854c563-7c2f-4e02-8fa9-932f3e99f08b",
                    "list_id": "f653bce2-6c83-4ba7-bff1-163ea4520def",
                    "parent_task_id": "",
                    "name": "Follow up with Doug",
                    "description": "Follow up with Doug by 10 AM tomorrow",
                    "position": 1,
                    "status": "2f9a61b1-1234-4e2a-a412-fb147ff716b2",
                    "priority": "high",
                    "due_date": `${momentTz.tz('America/Toronto').add(1, 'days').format('YYYY-MM-DD')}T11:00:00-04:00`,
                    "time_estimate": "",
                    "file_attachments": [],
                    "created_by": {
                        "user_id": "4a818a95-f2ba-4586-b4c3-83d2702f2557",
                        "name": "Alex Williams"
                    },
                    "created_at": "2024-10-30T04:22:10.190Z",
                    "updated_at": "2024-10-30T04:22:10.190Z",
                    "team_id": "583b4c9e-2389-47c4-a6af-2bee83a89377",
                    "team_name": "General",
                    "list_name": "Tasks",
                    "list_color": "pastel_olive",
                    "status_name": "To Do",
                    "status_color": "pastel_orchid",
                    "team_color": "pastel_orange"
                }, sender: 'emily', created_at: new Date().toISOString()
            },

            { content_type: 'text', content: 'Email Doug now, mentioning I heard you had a great call with Steve, do let me know if you have any questions regarding the proposal.', sender: 'user', created_at: new Date().toISOString() },
            {
                content_type: 'email', content:
                {
                    "type": "new",
                    "provider": "gmail",
                    "provider_message_id": "y8y080y10",
                    "provider_thread_id": "y8y080y10",
                    "sender": "alex@acmeinc.com",
                    "date": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T17:00:00.000Z`,
                    "from": [
                        {
                            "address": "alex@acmeinc.com",
                            "name": "Alex Williams"
                        }
                    ],
                    "to": [
                        {
                            "address": "doug@xyzinc.com",
                            "name": "Doug Smith"
                        }
                    ],
                    "cc": [],
                    "bcc": [],
                    "subject": "Re: Task “Client Proposal”",
                    "reply_to": [],
                    "text": "Hi Doug,\n\nI heard you had a great call with Steve. Do let me know if you have any questions regarding the proposal, \n\nBest regards\nAlex.\n\n",
                    "html": "<html><body>Hi Doug,<br><br>I heard you had a great call with Steve. Do let me know if you have any questions regarding the proposal, <br><br>Best regards <br>Alex.<br><br></body></html>",
                    "attachments": []
                }
                , sender: 'emily', created_at: new Date().toISOString()
            },

            { content_type: 'text', content: 'I have sent the email to Doug. Would you like to change the task status to completed?', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Yes, please.', sender: 'user', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'I have changed the task status to completed.', sender: 'emily', created_at: new Date().toISOString() },

            {
                content_type: 'task', content: {
                    "is_new": false,
                    "task_id": "uyoiuayoiusfhisudfhasdf",
                    "workspace_id": "9854c563-7c2f-4e02-8fa9-932f3e99f08b",
                    "list_id": "f653bce2-6c83-4ba7-bff1-163ea4520def",
                    "parent_task_id": "",
                    "name": "Follow up with Doug",
                    "description": "Follow up with Doug by 10 AM tomorrow",
                    "position": 1,
                    "status": "2f9a61b1-1234-4e2a-a412-fb147ff716b2",
                    "priority": "high",
                    "due_date": `${momentTz.tz('America/Toronto').add(1, 'days').format('YYYY-MM-DD')}T11:00:00-04:00`,
                    "time_estimate": "",
                    "file_attachments": [],
                    "created_by": {
                        "user_id": "4a818a95-f2ba-4586-b4c3-83d2702f2557",
                        "name": "Alex Williams"
                    },
                    "created_at": "2024-10-30T04:22:10.190Z",
                    "updated_at": "2024-10-30T04:22:10.190Z",
                    "team_id": "583b4c9e-2389-47c4-a6af-2bee83a89377",
                    "team_name": "General",
                    "list_name": "Tasks",
                    "list_color": "pastel_olive",
                    "status_name": "Completed",
                    "status_color": "pastel_green",
                    "team_color": "pastel_orange"
                }, sender: 'emily', created_at: new Date().toISOString()
            },
            { content_type: 'time_update', content: `13:40`, sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Leaving the office now, please check my flight status with Air Canada for today.', sender: 'user', created_at: new Date().toISOString() },
            {
                content_type: 'flight', content: {
                    "flight_date": "2024-11-05",
                    "flight_status": "delayed",
                    "departure": {
                        "airport": "Pierre Elliott Trudeau International",
                        "timezone": "America/Montreal",
                        "iata": "YUL",
                        "icao": "CYUL",
                        "terminal": "1",
                        "gate": "A51",
                        "delay": 11,
                        "scheduled": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T16:30:00+00:00`,
                        "estimated": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T16:30:00+00:00`,
                        "actual": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T16:41:00+00:00`,
                        "estimated_runway": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T16:41:00+00:00`,
                        "actual_runway": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T16:41:00+00:00`
                    },
                    "arrival": {
                        "airport": "Lester B. Pearson International",
                        "timezone": "America/Toronto",
                        "iata": "YYZ",
                        "icao": "CYYZ",
                        "terminal": "1",
                        "gate": "D43",
                        "baggage": "2",
                        "delay": null,
                        "scheduled": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T17:38:00+00:00`,
                        "estimated": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T17:38:00+00:00`,
                        "actual": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T17:38:00+00:00`,
                        "estimated_runway": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T17:38:00+00:00`,
                        "actual_runway": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T17:38:00+00:00`
                    },
                    "airline": {
                        "name": "Air Canada",
                        "iata": "AC",
                        "icao": "ACA"
                    },
                    "flight": {
                        "number": "895",
                        "iata": "AC895",
                        "icao": "ACA895",
                        "codeshared": null
                    },
                    "aircraft": {
                        "registration": "C-GFAJ",
                        "iata": "A333",
                        "icao": "A333",
                        "icao24": "C051E6"
                    },
                    "live": null
                }, sender: 'emily', created_at: new Date().toISOString()
            },
            { content_type: 'text', content: 'Your flight to Toronto with Air Canada for today is at 4:30 PM. Currently, it is delayed by 11 minutes. Departing from gate A51 from Terminal 1.', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Can you check if Sam Barnes replied to my last email?', sender: 'user', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Sam Barnes has replied to your last email regarding your visit to Toronto. He asked for your availability for tomorrow.', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Shall I look up your availability and send it to him?', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Yes, please.', sender: 'user', created_at: new Date().toISOString() },
            {
                content_type: 'email', content:
                {
                    "type": "reply",
                    "provider": "gmail",
                    "provider_message_id": "y8y080y12",
                    "provider_thread_id": "y8y080y12",
                    "sender": "alex@acmeinc.com",
                    "date": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T18:40:00.000Z`,
                    "from": [
                        {
                            "address": "alex@acmeinc.com",
                            "name": "Alex Williams"
                        }
                    ],
                    "to": [
                        {
                            "address": "sam@acmeinc.com",
                            "name": "Sam Barnes"
                        }
                    ],
                    "cc": [],
                    "bcc": [],
                    "subject": "Re: Meeting Availability",
                    "reply_to": [],
                    "text": "Hi Sam,\n\nHere are my available times for a meeting tomorrow morning:\n\n• 9:00 AM - 9:15 AM\n• 9:30 AM - 9:45 AM\n• 10:00 AM - 10:15 AM\n• 10:30 AM - 10:45 AM\n\nLet me know what works best for you,\n\nBest regards,\nAlex",
                    "html": "<html><body>Hi Sam,<br><br>Here are my available times for a meeting tomorrow morning:<br><br>• 9:00 AM - 9:15 AM<br>• 9:30 AM - 9:45 AM<br>• 10:00 AM - 10:15 AM<br>• 10:30 AM - 10:45 AM<br><br>Let me know what works best for you,<br><br>Best regards,<br>Alex</body></html>",
                    "attachments": []
                }
                , sender: 'emily', created_at: new Date().toISOString()
            },
            { content_type: 'text', content: 'I have sent Sam an email with your availability for tomorrow.', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'time_update', content: `14:20`, sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Can you suggest some good hotels in Toronto?', sender: 'user', created_at: new Date().toISOString() },
            {
                content_type: 'website', content: {
                    "url": "https://www.fairmont.com/royal-york-toronto/",
                    "title": "The Fairmont Royal York Toronto",
                    "summary": "The Fairmont Royal York Toronto is a luxury hotel located in downtown Toronto. The hotel offers a range of amenities including a fitness centre, a restaurant and a bar. The rooms are spacious and have a modern design. The hotel also offers a shuttle service to the airport. The hotel is a great choice for both business and leisure travellers.",
                    "image": "https://www.fairmont.com/assets/0/104/2793/2798/4415/4416/2a8bc888-2178-4066-9d11-f53108f1eb9b.jpg"
                }, sender: 'emily', created_at: new Date().toISOString()
            },
            {
                content_type: 'website', content: {
                    "url": "https://www.marriott.com/hotels/travel/yyzot-toronto-marriott-city-centre-hotel/",
                    "title": "Toronto Marriott City Centre Hotel",
                    "summary": "The Toronto Marriott City Centre Hotel is located in the heart of downtown Toronto. The hotel features modern rooms, a fitness center, an indoor pool, and a restaurant. It is conveniently located near major attractions such as the CN Tower and Ripley's Aquarium.",
                    "image": "https://cache.marriott.com/content/dam/marriott-renditions/YYZCC/yyzcc-exterior-0127-hor-pano.jpg?output-quality=70&interpolation=progressive-bilinear&downsize=1920px:*"
                }, sender: 'emily', created_at: new Date().toISOString()
            },
            {
                content_type: 'website', content: {
                    "url": "https://www.shangri-la.com/toronto/shangrila/",
                    "title": "Shangri-La Hotel Toronto",
                    "summary": "The Shangri-La Hotel Toronto offers luxurious accommodations in the city center. The hotel features elegant rooms, a spa, a fitness center, and multiple dining options. It is known for its exceptional service and prime location.",
                    "image": "https://static.prod.r53.tablethotels.com/media/hotels/slideshow_images_staged/large/1417684.jpg"
                }, sender: 'emily', created_at: new Date().toISOString()
            },
            { content_type: 'text', content: 'The Fairmont Royal York Hotel is in the heart of downtown Toronto.', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Show me on the map.', sender: 'user', created_at: new Date().toISOString() },
            { content_type: 'map', content: 'Fairmont Royal York Hotel, Toronto, Ontario.', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Show me an image.', sender: 'user', created_at: new Date().toISOString() },
            { content_type: 'image', content: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Hotel_vanc_2007.jpg', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'time_update', content: `14:55`, sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Can you suggest a good sushi restaurant in Toronto?', sender: 'user', created_at: new Date().toISOString() },
            { content_type: 'map', content: 'Yasu Toronto', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'map', content: 'Kibo Sushi House', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Please forward those options to Sam.', sender: 'user', created_at: new Date().toISOString() },
            {
                content_type: 'email', content: {

                    "type": "new",
                    "provider": "gmail",
                    "provider_message_id": "y8y080y13",
                    "provider_thread_id": "y8y080y13",
                    "sender": "alex@acmeinc.com",
                    "date": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T18:55:00.000Z`,
                    "from": [
                        {
                            "address": "alex@acmeinc.com",
                            "name": "Alex Williams"
                        }
                    ],
                    "to": [
                        {
                            "address": "sam@acmeinc.com",
                            "name": "Sam Barnes"
                        }
                    ],
                    "cc": [],
                    "bcc": [],
                    "subject": "Sushi Restaurant Suggestions",
                    "reply_to": [],
                    "text": "Hi Sam,\n\nHere are some good sushi restaurants in Toronto:\n\n• Yasu Toronto\n(https://www.yasu-sushibar.com/index.html)\n• Kibo Sushi House\n(https://kibosushi.com/)\n\n",
                    "html": "<html><body>Hi Sam,<br><br>Here are some good sushi restaurants in Toronto:<br><br>• Yasu Toronto<br>(<a href='https://www.yasu-sushibar.com/index.html'>https://www.yasu-sushibar.com/index.html</a>)<br>• Kibo Sushi House<br>(<a href='https://kibosushi.com/'>https://kibosushi.com/</a>)<br><br></body></html>",
                    "attachments": []
                }, sender: 'emily', created_at: new Date().toISOString()
            },
            { content_type: 'text', content: 'Options sent to Sam. Need anything else for your trip to Toronto?', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: `Thanks, I'm all set for the trip. Can you check my last email from Eric Anderson?`, sender: 'user', created_at: new Date().toISOString() },
            { content_type: 'text', content: `Your last email from Eric Anderson was on ${momentTz.tz('America/Toronto').subtract(3, 'months').subtract(15, 'days').format('MMMM Do')} at 10:30 AM. He mentioned that he will review your proposal and get back to you. Do you want to follow up with him, it's been a while since he replied?`, sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Yes, please.', sender: 'user', created_at: new Date().toISOString() },
            { content_type: 'time_update', content: `15:10`, sender: 'emily', created_at: new Date().toISOString() },

            {
                content_type: 'email', content: {
                    "type": "new",
                    "provider": "gmail",
                    "provider_message_id": "y8y080y14",
                    "provider_thread_id": "y8y080y14",
                    "sender": "alex@acmeinc.com",
                    "date": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T19:10:00.000Z`,
                    "from": [
                        {
                            "address": "alex@acmeinc.com",
                            "name": "Alex Williams"
                        }
                    ],
                    "to": [
                        {
                            "address": "eric@acmeinc.com",
                            "name": "Eric Anderson"
                        }
                    ],
                    "cc": [],
                    "bcc": [],
                    "subject": "Re: Proposal Review",
                    "reply_to": [],
                    "text": "Hi Eric,\n\nI noticed you mentioned in your last email that you will review the proposal and get back to me.\n\nDo you need any more information from me regarding the proposal?\n\nPlease let me know.\n\nBest regards,\nAlex",
                    "html": "<html><body>Hi Eric,<br><br>I noticed you mentioned in your last email that you will review the proposal and get back to me.<br><br>Do you need any more information from me regarding the proposal?<br><br>Please let me know.<br><br>Best regards,<br>Alex</body></html>",
                    "attachments": []
                }, sender: 'emily', created_at: new Date().toISOString()
            },
            { content_type: 'text', content: 'I have sent Eric an email following up on the proposal you sent him.', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Amazing, Emily! Thank you!', sender: 'user', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'You are very welcome Alex!', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'time_update', content: `15:30`, sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Emily, do I have any spare time tomorrow before my flight back home?', sender: 'user', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Your schedule is clear after 10 am until your flight home at 4pm.', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Great, can you suggest things to do in Toronto for 2 hours?', sender: 'user', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'I can suggest a few good restaurants or attractions. Do you have any preferences?', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'No, you can decide.', sender: 'user', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'You can visit the CN Tower, a well-known landmark of Toronto. It is located in downtown Toronto. It is a great place to see the city from up high.', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: "You can also visit the Ripley's Aquarium of Canada, situated across from the CN Tower. Shall I add those locations to your calendar?", sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Yes, please.', sender: 'user', created_at: new Date().toISOString() },
            {
                content_type: 'event', content: {
                    "is_new": true,
                    "event_id": "47dce828-cb00-48ca-868e-f243eac4486e",
                    "provider": "google_calendar",
                    "updated_at": "2024-10-31T13:31:43.057Z",
                    "status": "confirmed",
                    "provider_event_id": "sotmuf4s3c1aorjb4ejflg0u5g",
                    "title": "Visit CN Tower",
                    "start_time": `${momentTz.tz('America/Toronto').add(1, 'days').format('YYYY-MM-DD')}T12:00:00-04:00`,
                    "end_time": `${momentTz.tz('America/Toronto').add(1, 'days').format('YYYY-MM-DD')}T13:00:00-04:00`,
                    "display_timezone": "America/Toronto",
                    "calendar_email": "alex@acmeinc.com",
                    "description": "Visit the CN Tower in Toronto.",
                    "all_day": false,
                    "event_link": "https://google.com",
                    "attendees": [],
                    "actions": [
                        {
                            "type": "location",
                            "entry_point": "map",
                            "location": "CN Tower",
                            "provider": "map"
                        },
                    ],
                    "creator": {
                        "email": "alex@acmeinc.com"
                    },
                    "integration_id": "d7ae0d39-7a35-4f6d-ac1b-daf0bdf1c084"
                }, sender: 'emily', created_at: new Date().toISOString()
            },

            {
                content_type: 'event', content: {
                    "is_new": true,
                    "event_id": "47dce828-cb00-48ca-868e-f243eac4486e",
                    "provider": "google_calendar",
                    "updated_at": "2024-10-31T13:31:43.057Z",
                    "status": "confirmed",
                    "provider_event_id": "sotmuf4s3c1aorjb4ejflg0u5g",
                    "title": "Visit Ripley Aquarium of Canada",
                    "start_time": `${momentTz.tz('America/Toronto').add(1, 'days').format('YYYY-MM-DD')}T13:30:00-04:00`,
                    "end_time": `${momentTz.tz('America/Toronto').add(1, 'days').format('YYYY-MM-DD')}T14:30:00-04:00`,
                    "display_timezone": "America/Toronto",
                    "calendar_email": "alex@acmeinc.com",
                    "description": "Visit the Ripley Aquarium of Canada in Toronto.",
                    "all_day": false,
                    "event_link": "https://google.com",
                    "attendees": [],
                    "actions": [
                        {
                            "type": "location",
                            "entry_point": "map",
                            "location": "Ripley's Aquarium of Canada",
                            "provider": "map"
                        },
                    ],
                    "creator": {
                        "email": "alex@acmeinc.com"
                    },
                    "integration_id": "d7ae0d39-7a35-4f6d-ac1b-daf0bdf1c084"
                }, sender: 'emily', created_at: new Date().toISOString()
            },

            { content_type: 'text', content: "I have added the CN Tower and Ripley's Aquarium of Canada to your calendar for tomorrow from 11 AM to 1:30 PM, leaving you time to head to the airport shortly after.", sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'time_update', content: `15:40`, sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'How many contacts do I have at the Acme Toronto office?', sender: 'user', created_at: new Date().toISOString() },
            {
                content_type: 'contact',
                content: {
                    "display_name": "Margaret Landry",
                    "email": "margaret.landry@acmeinc.com"
                },
                sender: 'emily', created_at: new Date().toISOString()
            },
            {
                content_type: 'contact',
                content: {
                    "display_name": "Zoey Smith",
                    "email": "zoey.smith@acmeinc.com"
                },
                sender: 'emily', created_at: new Date().toISOString()
            },
            { content_type: 'text', content: 'You have 2 contacts at the Acme Toronto office. The first one is Margaret Landry and the second one is Zoey Smith.', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'time_update', content: `16:10`, sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'I won’t be able to make it to the meeting tomorrow with Amber, can you reschedule it for next tuesday at 2:00 PM?', sender: 'user', created_at: new Date().toISOString() },
            {
                content_type: 'event', content: {
                    "is_new": false,
                    "event_id": "47dce828-cb00-48ca-868e-f243eac4486e",
                    "provider": "google_calendar",
                    "updated_at": "2024-10-31T13:31:43.057Z",
                    "status": "confirmed",
                    "provider_event_id": "sotmuf4s3c1aorjb4ejflg0u5g",
                    "title": "Tour of the new US office",
                    "start_time": `${momentTz.tz('America/Toronto').day(2).add(momentTz.tz('America/Toronto').day() >= 2 ? 7 : 0, 'days').format('YYYY-MM-DD')}T14:30:00-04:00`,
                    "end_time": `${momentTz.tz('America/Toronto').day(2).add(momentTz.tz('America/Toronto').day() >= 2 ? 7 : 0, 'days').format('YYYY-MM-DD')}T15:30:00-04:00`,
                    "display_timezone": "America/Toronto",
                    "calendar_email": "alex@acmeinc.com",
                    "description": "This is a tour of the new US office.",
                    "all_day": false,
                    "event_link": "https://google.com",
                    "attendees": [
                        {
                            "name": "Lily Andrews",
                            "email": "lily.andrews@acmeinc.com",
                            "is_me": false,
                            "response_status": "accepted"
                        }
                    ],
                    "actions": [
                        {
                            "type": "location",
                            "entry_point": "map",
                            "location": "Austin, TX",
                            "provider": "map"
                        },

                    ],
                    "creator": {
                        "email": "alex@acmeinc.com"
                    },
                    "integration_id": "d7ae0d39-7a35-4f6d-ac1b-daf0bdf1c084"
                }, sender: 'emily', created_at: new Date().toISOString()
            },
            { content_type: 'text', content: 'You have an event already scheduled for tuesday at 1:30 PM to 2:30 PM with Lily Andrews. Shall I reschedule your meeting with Amber for tuesday at 4:00 PM instead?', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Yes, please.', sender: 'user', created_at: new Date().toISOString() },

            {
                content_type: 'event', content: {
                    "is_new": true,
                    "event_id": "47dce828-cb00-48ca-868e-f243eac4486e",
                    "provider": "google_calendar",
                    "updated_at": "2024-10-31T13:31:43.057Z",
                    "status": "confirmed",
                    "provider_event_id": "sotmuf4s3c1aorjb4ejflg0u5g",
                    "title": "Campaign review with Amber",
                    "start_time": `${momentTz.tz('America/Toronto').day(2).add(momentTz.tz('America/Toronto').day() >= 2 ? 7 : 0, 'days').format('YYYY-MM-DD')}T17:00:00-04:00`,
                    "end_time": `${momentTz.tz('America/Toronto').day(2).add(momentTz.tz('America/Toronto').day() >= 2 ? 7 : 0, 'days').format('YYYY-MM-DD')}T18:00:00-04:00`,
                    "display_timezone": "America/Toronto",
                    "calendar_email": "alex@acmeinc.com",
                    "description": "This is a campaign review with Amber.",
                    "all_day": false,
                    "event_link": "https://google.com",
                    "attendees": [
                        {
                            "name": "Amber Smith",
                            "email": "amber.smith@acmeinc.com",
                            "is_me": false,
                            "response_status": "accepted"
                        }
                    ],
                    "actions": [
                        {
                            "type": "join",
                            "entry_point": "video",
                            "location": "https://meet.google.com/ags-etik-ldl",
                            "provider": "Google Meet"
                        },

                    ],
                    "creator": {
                        "email": "alex@acmeinc.com"
                    },
                    "integration_id": "d7ae0d39-7a35-4f6d-ac1b-daf0bdf1c084"
                }, sender: 'emily', created_at: new Date().toISOString()
            },
            { content_type: 'text', content: 'I have rescheduled the meeting with Amber for tuesday at 4:00 PM.', sender: 'emily', created_at: new Date().toISOString() },
            { content_type: 'text', content: 'Thanks, Emily!', sender: 'user', created_at: new Date().toISOString() },
            { content_type: 'time_update', content: `16:35`, sender: 'emily', created_at: new Date().toISOString() },

        ];
        const sendMessages = async () => {
            for (let i = 0; i < timeline.length; i++) {
                const item = timeline[i];
                const delay = i === 0 ? 0 : 1800; // First message immediately, others after
                await new Promise(resolve => setTimeout(resolve, delay));

                if (item.content_type === 'time_update') {
                    setDateTime((prevDateTime) => {
                        const [hours, minutes] = item.content.split(':').map(Number);
                        const updatedDateTime = new Date(prevDateTime);
                        updatedDateTime.setHours(hours, minutes);
                        return updatedDateTime;
                    });
                } else if (item.content_type === 'calender_date_time_update') {
                    setCalendarDateTime(new Date(item.content));
                } else if (item.content_type === 'view_update') {
                    setView(item.content);
                } else if (item.content_type === 'add_crm_record') {
                    addCRMRecord(item.content as any);
                } else {
                    addMessage({
                        ...item,
                        created_at: new Date().toISOString()
                    });
                }
            }
        };

        sendMessages();
    }, []);

    return (
        <section style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 0, // Initially hide the section
            animation: `fadeInUp 1s ease-out 0.5s forwards`, // Apply the animation with a 3.5s delay and maintain the final state
        }}>
            {isMobile ? <div style={{ position: 'relative' }}>
                <div className="web-mockup" style={{ position: 'relative', left: '-195px', width: '500px', overflow: 'hidden', }}>
                    <WebMockup calendarEvents={calendarEvents} tasks={tasks} dateTime={dateTime} calendarDateTime={calendarDateTime} view={view} crmRows={crmRows} />
                </div>
                <div className="mobile-mockup" style={{ position: 'absolute', right: '-109px', top: '-20px', zIndex: 10 }}>
                    <MobileMockup messages={messages} dateTime={dateTime} />
                </div>
            </div> : <div style={{ position: 'relative' }}>
                <div className="web-mockup" style={{
                    position: 'relative', marginRight: '20px', left: '-175px', overflow: 'hidden', borderRadius: '8px',
                    border: '1px solid #DFE1E6',
                }}>
                    <WebMockup calendarEvents={calendarEvents} tasks={tasks} dateTime={dateTime} calendarDateTime={calendarDateTime} view={view} crmRows={crmRows} />
                </div>
                <div className="mobile-mockup" style={{ position: 'absolute', right: '-176px', top: '-20px', zIndex: 10 }}>
                    <MobileMockup messages={messages} dateTime={dateTime} />
                </div>
            </div>}
            <style>
                {`
                @keyframes fadeInUp {
                    from {
                        opacity: 0;
                        transform: translateY(20px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
                `}
            </style>
        </section>
    );
}

export default MockupSection;


