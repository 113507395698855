import { Table, TableHead, TableRow, TableCell, Chip, TableBody, Stack, Typography, Tooltip, Avatar, SvgIconProps, SvgIcon, Theme, CheckboxProps, createTheme, IconButton } from '@mui/material';
import './tableStyles.css';
import { useEffect, useState, useMemo } from 'react';
import { getColorByName } from './functions/PastelColors';
import { Icon } from '@iconify/react';
import moment from 'moment';


var capitalize = require('capitalize');

interface TaskTableProps {
    rows: any[];
}

interface TaskPriorityOption {
    label: string;
    value: string;
    color: string;
}

const TaskTable = ({ rows }: TaskTableProps) => {


    const [checkedRows, setCheckedRows] = useState<number[]>([]);


    const taskPriorityOptions: TaskPriorityOption[] = [
        { label: 'None', value: '', color: '' },
        { label: 'Low', value: 'low', color: 'pastel_green' },
        { label: 'Medium', value: 'medium', color: 'pastel_orange' },
        { label: 'High', value: 'high', color: 'pastel_red' },
    ];


    const columns = [
        { name: 'Name', key: 'person', icon: <Icon icon="mdi:format-text" /> },
        { name: 'Due Date', key: 'due_date', icon: <Icon icon="mdi:event-outline" /> },
        { name: 'Priority', key: 'priority', icon: <Icon icon="material-symbols:flag-2-outline-rounded" /> },
        { name: 'Time Tracking', key: 'time_tracking', icon: <Icon icon="mingcute:time-duration-line" /> },
        { name: 'Assignees', key: 'assignees', icon: <Icon icon="mdi:account-outline" /> },
    ];



    return (
        <div style={{ border: '1px solid #DFE1E6' }}>
            <Table className="table" stickyHeader sx={{ overflow: 'hidden' }} >
                <TableHead >

                    <TableRow>

                        {columns.map((column) => (
                            <TableCell size="small" style={{ fontWeight: 'bold', fontSize: '14px', color: '#637381' }} sx={{ paddingLeft: '13px' }}>
                                <Stack direction="row" alignItems="center" spacing={0.3} sx={{ flexShrink: 0 }}>{column.icon} <span style={{ paddingLeft: '5px' }}>{column.name} </span></Stack>
                            </TableCell>
                        ))}

                    </TableRow>
                </TableHead>
                <TableBody sx={{ backgroundColor: 'white' }}>
                    {rows.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell size="small" sx={{ paddingLeft: '10px' }}>
                                <Stack direction="row" alignItems="center" spacing={.9} sx={{ width: '100%' }}>
                                    {/* <CheckboxIcon /> */}
                                    {row.task_name && <span style={{ paddingLeft: '5px' }}>{row.task_name}</span>}
                                </Stack>
                            </TableCell>

                            <TableCell size="small" sx={{ paddingLeft: '10px' }}>
                                {row.due_date ? (
                                    <Chip
                                        label={moment(row.due_date).format('MMM D, YYYY [at] h:mm A')}
                                        size="small"
                                        sx={{
                                            cursor: 'default',
                                            backgroundColor: (() => {
                                                const now = moment();
                                                const dueDate = moment(row.due_date);
                                                if (dueDate.isBefore(now)) {
                                                    // Task is overdue
                                                    return getColorByName('pastel_red')?.backgroundColor;
                                                } else if (dueDate.isSame(now, 'day')) {
                                                    // Task is due today, but not overdue
                                                    return getColorByName('pastel_orange')?.backgroundColor;
                                                }
                                                // Task is due in the future
                                                return undefined;
                                            })()
                                        }}
                                    />
                                ) : (
                                    <Typography variant="body2" color="text.secondary">No due date</Typography>
                                )}
                            </TableCell>


                            <TableCell size="small" sx={{ paddingLeft: '10px' }}>
                                <Chip
                                    label={taskPriorityOptions.find(option => option.value === row.priority)?.label || 'None'}
                                    sx={{
                                        cursor: 'default',
                                        backgroundColor: getColorByName(taskPriorityOptions.find(option => option.value === row.priority)?.color ?? '')?.backgroundColor,
                                        color: getColorByName(taskPriorityOptions.find(option => option.value === row.priority)?.color ?? '')?.textColor
                                    }}
                                    size="small"
                                />
                            </TableCell>

                            <TimeTracker task={row} />

                            <TableCell size="small" sx={{ paddingLeft: '10px' }}>
                                <Stack direction="row" alignItems="center" spacing={0.3} sx={{ flexShrink: 0 }}>
                                    {row.assignees && row.assignees.map((assignee: any) => (
                                        <PersonAvatar personName={assignee.fullName} companyName={''} />
                                    ))}
                                </Stack>
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

export default TaskTable;

const ActivityCell = ({ content }: { content: any }) => {


    const [latestActivityIcon, setLatestActivityIcon] = useState('fluent:shifts-activity-20-filled');
    const [formattedlast_activityDate, setFormattedlast_activityDate] = useState('');

    useEffect(() => {
        if (content && typeof content === 'object' && 'created_at' in content) {
            const updateFormattedDate = () => {
                if (content.created_at) {
                    let icon = 'fluent:shifts-activity-20-filled';
                    if (content.type === 'note') {
                        icon = 'material-symbols:sticky-note-2-outline';
                    } else if (content.type === 'email') {
                        icon = 'ic:outline-email';
                    } else if (content.type === 'call') {
                        icon = 'mdi:call-outline';
                    } else if (content.type === 'sms') {
                        icon = 'material-symbols:sms-outline';
                    } else if (content.type === 'people') {
                        icon = 'mdi:user-outline';
                    } else if (content.type === 'deal') {
                        icon = 'mdi:deal-outline';
                    } else if (content.type === 'task') {
                        icon = 'material-symbols:task-outline';
                    }
                    setLatestActivityIcon(icon);
                    setFormattedlast_activityDate(moment(content.created_at).fromNow());
                }
            };

            updateFormattedDate();
            const now = moment();
            const createdAt = moment(content.created_at);
            const interval = now.diff(createdAt, 'minutes') < 1 ? 1000 : 60000; // Update every second if less than a minute, else every minute
            const intervalId = setInterval(updateFormattedDate, interval);

            return () => clearInterval(intervalId); // Cleanup interval on component unmount
        }
    }, [content]);

    return <div>
        <Stack direction="row" alignItems="center" alignContent="center" justifyContent="center" spacing={1} sx={{ width: '100%' }}>
            {latestActivityIcon && formattedlast_activityDate && <Icon icon={latestActivityIcon} color={'#637381'} />}
            {formattedlast_activityDate ? (
                <span style={{ flexGrow: 1, textOverflow: 'ellipsis', overflow: 'hidden' }}>
                    {formattedlast_activityDate}
                </span>
            ) : (
                <span style={{ flexGrow: 1, textOverflow: 'ellipsis', overflow: 'hidden', color: 'text.disabled' }}>
                    No activity
                </span>
            )}
        </Stack>
    </div>
}


const PersonAvatar = ({ personName, companyName }: { personName: string, companyName: string }) => {



    return (
        <Avatar src={''} alt={personName} sx={{ width: 20, height: 20, borderRadius: '50%', border: '0.5px solid #DCE0E4', padding: '2px', fontSize: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
            {personName.charAt(0).toUpperCase()}
        </Avatar>
    );
}




// Using for Checkbox
function CheckboxIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} >
            <path d="M17.9 2.318A5 5 0 0 1 22.895 7.1l.005.217v10a5 5 0 0 1-4.783 4.995l-.217.005h-10a5 5 0 0 1-4.995-4.783l-.005-.217v-10a5 5 0 0 1 4.783-4.996l.217-.004h10Zm-.5 1.5h-9a4 4 0 0 0-4 4v9a4 4 0 0 0 4 4h9a4 4 0 0 0 4-4v-9a4 4 0 0 0-4-4Z" fill="#637381" />
        </SvgIcon>
    );
}




// ----------------------------------------------------------------------

const TimeTracker = ({ task }: { task: any }) => {

    const [isTracking, setIsTracking] = useState(task.time_tracking.enabled || false);
    const [trackerLoading, setTrackerLoading] = useState(false);
    const [timeElapsed, setTimeElapsed] = useState(task.time_tracking.elapsed || '00:00:00');
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

    const formatTime = (seconds: number) => {
        const hrs = Math.floor(seconds / 3600).toString().padStart(2, '0');
        const mins = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
        const secs = (seconds % 60).toString().padStart(2, '0');
        return `${hrs}:${mins}:${secs}`;
    };

    const toggleTracking = (continueTracking: boolean) => {
        if (isTracking && !continueTracking) {
            if (intervalId) {
                clearInterval(intervalId);
                setIntervalId(null);
            }
            setTimeElapsed('00:00:00');
        } else {
            let initialSeconds = timeElapsed.split(':').reduce((acc: number, time: string) => (60 * acc) + +time, 0);
            const newIntervalId = setInterval(() => {
                initialSeconds += 1;
                setTimeElapsed(formatTime(initialSeconds));
            }, 1000);
            setIntervalId(newIntervalId);
        }

        if (!continueTracking) {
            setIsTracking(!isTracking);
        }
    };

    useEffect(() => {
        if (task.time_tracking.enabled) {
            toggleTracking(true);
        }
    }, [task]);

    return <TableCell size="small" sx={{ paddingLeft: '10px' }}>
        <Stack direction="row" alignItems="center" spacing={0.3} sx={{ flexShrink: 0 }}>
            <IconButton disableRipple onClick={() => toggleTracking(false)} size="small" disabled={trackerLoading} sx={{ }}>
                {isTracking ?
                <Icon icon="mdi:stop-circle" style={{ color: '#FF5631' }} /> :
                <Icon icon="mdi:play-circle" style={{ color: '#9F61DC' }} />
            }
        </IconButton>
            <span style={{ color: isTracking? 'black':'#637481' }}>{isTracking ? timeElapsed : 'Start'}</span>
        </Stack>
    </TableCell>
}