import React from 'react';
import Page from './components/Page';
import { Divider } from '@mui/material';

const FairUsePolicy = () => {
  return (
    <Page title="Fair use policy">
    <div style={{ textAlign: 'center', marginBottom: '20px' }}>
      <h3>{`Effective: Tue Nov 05, 2024`}</h3>
    </div>
    <Divider sx={{borderTop: '1px solid #DFE1E6', width: '100%'}} />

    <div style={{ padding: '20px' }}>

    <p>Our goal at <strong>Zweelie</strong> is to provide a productive, reliable, and user-friendly platform to help individuals and teams work efficiently and collaboratively. To ensure that our Services remain accessible, effective, and beneficial for all users, we’ve established this Fair Use Policy. By using <strong>Zweelie</strong> and <strong>Emily</strong>, you agree to comply with this policy, which is intended to outline responsible use and prevent misuse or abuse of our platform.</p>

<h3>1. Purpose of the Fair Use Policy</h3>
<p>This Fair Use Policy is designed to:</p>
<ul>
  <li>Promote responsible and respectful use of <strong>Zweelie</strong>’s platform and resources.</li>
  <li>Prevent activities that could negatively impact the quality, performance, or accessibility of our Services for other users.</li>
  <li>Protect <strong>Zweelie</strong>’s intellectual property and the security of our systems.</li>
</ul>

<h3>2. Acceptable Use</h3>
<p><strong>Zweelie</strong> and <strong>Emily</strong> are intended to help users manage tasks, collaborate with team members, organize schedules, and increase productivity. Acceptable use of our platform includes, but is not limited to:</p>
<ul>
  <li>Using the platform for legitimate business and personal productivity purposes.</li>
  <li>Interacting with <strong>Emily</strong> for task management, CRM, scheduling, and similar productivity-enhancing activities.</li>
  <li>Sharing and accessing workspace resources responsibly in a way that respects the privacy and confidentiality of other users.</li>
</ul>

<h3>3. Prohibited Use</h3>
<p>You may not use <strong>Zweelie</strong> or <strong>Emily</strong> in ways that violate our Terms of Service, applicable laws, or the integrity of our platform. Prohibited uses include:</p>
<ul>
  <li><strong>Abusive and Offensive Content:</strong> Uploading, sharing, or communicating any content that is vulgar, abusive, threatening, explicit, or otherwise inappropriate, including NSFW (not safe for work) content.</li>
  <li><strong>Unlawful Activities:</strong> Using the Services in any way that violates local, state, national, or international laws or regulations, including copyright infringement and unauthorized data collection.</li>
  <li><strong>Intellectual Property Violations:</strong> Reproducing, distributing, or using <strong>Zweelie</strong>’s copyrighted materials, trademarks, or other intellectual property without permission, as well as infringing on the intellectual property rights of others.</li>
  <li><strong>System Abuse:</strong> Introducing harmful software (such as viruses or malware), spamming, or attempting to overload <strong>Zweelie</strong>’s systems through DDoS attacks or other means.</li>
  <li><strong>Unauthorized Access:</strong> Attempting to access restricted parts of the Services or bypassing security features, including through reverse engineering, decompiling, or similar methods.</li>
  <li><strong>Data Harvesting:</strong> Engaging in unauthorized data scraping, data mining, or data harvesting, particularly for competitive or unauthorized AI training purposes.</li>
  <li><strong>Competing Products:</strong> Using <strong>Zweelie</strong> or <strong>Emily</strong> to develop, train, or support competing products or services.</li>
</ul>

<h3>4. Rate Limits and Resource Allocation</h3>
<p>To ensure a high-quality experience for all users, <strong>Zweelie</strong> may impose rate limits and resource constraints. Rate limits are designed to prevent excessive usage that may impact platform performance. If your usage consistently exceeds typical patterns or appears to strain our resources, we may contact you to discuss adjustments or impose usage limits.</p>

<h3>5. Consequences of Violating the Fair Use Policy</h3>
<p>We reserve the right to take appropriate action if we believe that your use of <strong>Zweelie</strong> or <strong>Emily</strong> violates this Fair Use Policy or disrupts the experience for others. Actions we may take include, but are not limited to:</p>
<ul>
  <li>Issuing a warning or reminder of our Fair Use Policy.</li>
  <li>Temporarily or permanently limiting your access to certain features of <strong>Zweelie</strong> or <strong>Emily</strong>.</li>
  <li>Suspending or terminating your account without prior notice if violations are severe or repeated.</li>
  <li>Reporting illegal activities to law enforcement authorities, if necessary.</li>
</ul>
<p>For minor, non-severe infractions, we may provide a notice before taking further action. In the event of a serious violation, such as an attack on our systems or a clear breach of legal requirements, we reserve the right to immediately terminate your access without notice.</p>

<h3>6. No Tolerance for Abuse Toward Staff</h3>
<p>We have a zero-tolerance policy regarding any abusive, threatening, or violent behavior toward <strong>Zweelie</strong> staff. Any such behavior may result in immediate termination of your account and potential reporting to appropriate authorities.</p>

<h3>7. Reliance on Outputs and Responsibility</h3>
<p>While <strong>Emily</strong> is designed to enhance productivity and provide helpful outputs, please remember that all outputs should be verified before reliance. As an evolving technology, <strong>Emily</strong> may occasionally produce inaccurate or incomplete results. By using <strong>Emily</strong>, you agree to independently confirm the accuracy of outputs before making any critical decisions.</p>

<h3>8. Reporting Violations</h3>
<p>If you believe another user is violating this Fair Use Policy, please contact us at [Contact Email Address]. We will review all reported violations and take appropriate action in line with our policies.</p>

<h3>9. Policy Updates</h3>
<p>We may update this Fair Use Policy periodically to reflect changes in our Services, technology, or legal requirements. We encourage you to review this policy regularly. Continued use of <strong>Zweelie</strong> and <strong>Emily</strong> after any updates indicates your acceptance of the updated Fair Use Policy.</p>

<h3>Contact Us</h3>
<p>If you have any questions about this Fair Use Policy or need further clarification, please reach out to us at:</p>
<p>Email: support@zweelie.com</p>



    </div>
    </Page>
  );
};

export default FairUsePolicy;
