import { Stack, Tooltip } from "@mui/material";

interface SidebarIconProps {
    icon: 'Home' | 'CRM' | 'Tasks' | 'Members' | 'Teams' | 'Billing';
    state: 'active' | 'inactive';
}

const SidebarIcon = ({ icon, state }: SidebarIconProps) => {

    const getIcon = () => {
        switch (icon) {
            case 'Home':
                return (
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.2681 5.38304L14.1385 8.86637V14.9119H12.5903V10.2674H7.94586V14.9119H6.39771V8.86637L10.2681 5.38304ZM10.2681 3.30078L2.52734 10.2674H4.84957V16.46H9.49401V11.8156H11.0422V16.46H15.6866V10.2674H18.0088L10.2681 3.30078Z"
                            fill={state === 'inactive' ? "#637381" : "#9F61DC"}
                        />
                    </svg>
                );
            case 'CRM':
                return (
                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.46 2.56641H4.84893C3.99745 2.56641 3.30078 3.26307 3.30078 4.11455V14.9516C3.30078 15.8031 3.99745 16.4997 4.84893 16.4997H16.46C17.3115 16.4997 18.0082 15.8031 18.0082 14.9516V4.11455C18.0082 3.26307 17.3115 2.56641 16.46 2.56641ZM16.46 4.11455V6.43678H4.84893V4.11455H16.46ZM12.5897 14.9516H8.7193V7.98492H12.5897V14.9516ZM4.84893 7.98492H7.17115V14.9516H4.84893V7.98492ZM14.1378 14.9516V7.98492H16.46V14.9516H14.1378Z" fill={state === 'inactive' ? "#637381" : "#9F61DC"}
                        />
                    </svg>

                );

                case 'Tasks':
                    return (
                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.0088 4.16663L9.17664 13.0066L5.89457 9.72449L6.98601 8.63304L9.17664 10.8237L16.9174 3.08293L18.0088 4.16663ZM16.2981 8.06797C16.3988 8.50919 16.4607 8.97363 16.4607 9.44582C16.4607 12.8672 13.6895 15.6384 10.2681 15.6384C6.84668 15.6384 4.07549 12.8672 4.07549 9.44582C4.07549 6.02441 6.84668 3.25323 10.2681 3.25323C11.4911 3.25323 12.6213 3.6093 13.5811 4.22082L14.6958 3.10615C13.4418 2.22371 11.9169 1.70508 10.2681 1.70508C5.9952 1.70508 2.52734 5.17293 2.52734 9.44582C2.52734 13.7187 5.9952 17.1866 10.2681 17.1866C14.541 17.1866 18.0088 13.7187 18.0088 9.44582C18.0088 8.52467 17.8385 7.64223 17.5444 6.82171L16.2981 8.06797Z" 
                            fill={state === 'inactive' ? "#637381" : "#9F61DC"}
                        />
                    </svg>

                    );
            case 'Members':
                return (
                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.94586 11.0661C6.13453 11.0661 2.52734 11.9718 2.52734 13.7754V15.13H13.3644V13.7754C13.3644 11.9718 9.7572 11.0661 7.94586 11.0661ZM4.33868 13.5819C4.9889 13.1329 6.56027 12.6143 7.94586 12.6143C9.33146 12.6143 10.9028 13.1329 11.553 13.5819H4.33868ZM7.94586 9.71149C9.43983 9.71149 10.6551 8.49619 10.6551 7.00223C10.6551 5.50827 9.43983 4.29297 7.94586 4.29297C6.4519 4.29297 5.2366 5.50827 5.2366 7.00223C5.2366 8.49619 6.4519 9.71149 7.94586 9.71149ZM7.94586 5.84112C8.58834 5.84112 9.10697 6.35975 9.10697 7.00223C9.10697 7.64471 8.58834 8.16334 7.94586 8.16334C7.30338 8.16334 6.78475 7.64471 6.78475 7.00223C6.78475 6.35975 7.30338 5.84112 7.94586 5.84112ZM13.3953 11.1126C14.2933 11.7628 14.9125 12.6297 14.9125 13.7754V15.13H18.0088V13.7754C18.0088 12.2117 15.2996 11.3216 13.3953 11.1126ZM12.5903 9.71149C14.0843 9.71149 15.2996 8.49619 15.2996 7.00223C15.2996 5.50827 14.0843 4.29297 12.5903 4.29297C12.1723 4.29297 11.7853 4.3936 11.4292 4.56389C11.9169 5.25282 12.2033 6.09656 12.2033 7.00223C12.2033 7.90789 11.9169 8.75164 11.4292 9.44056C11.7853 9.61086 12.1723 9.71149 12.5903 9.71149Z" 
                            fill={state === 'inactive' ? "#637381" : "#9F61DC"}
                        />
                    </svg>

                );
            case 'Teams':
                return (
                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.686 2.65625H4.84893C3.99745 2.65625 3.30078 3.35292 3.30078 4.2044V15.0414C3.30078 15.8929 3.99745 16.5896 4.84893 16.5896H15.686C16.5374 16.5896 17.2341 15.8929 17.2341 15.0414V4.2044C17.2341 3.35292 16.5374 2.65625 15.686 2.65625ZM14.1378 15.0414H6.39708V14.8557C7.49626 13.9732 8.84315 13.4933 10.2674 13.4933C11.6917 13.4933 13.0386 13.9732 14.1378 14.8557V15.0414ZM15.686 14.159C14.2926 12.7889 12.3807 11.9451 10.2674 11.9451C8.15423 11.9451 6.24226 12.7889 4.84893 14.159V4.2044H15.686V14.159ZM10.2674 10.397C11.7614 10.397 12.9767 9.18169 12.9767 7.68773C12.9767 6.19377 11.7614 4.97847 10.2674 4.97847C8.77349 4.97847 7.55819 6.19377 7.55819 7.68773C7.55819 9.18169 8.77349 10.397 10.2674 10.397ZM10.2674 6.52662C10.9099 6.52662 11.4286 7.04525 11.4286 7.68773C11.4286 8.33021 10.9099 8.84884 10.2674 8.84884C9.62497 8.84884 9.10634 8.33021 9.10634 7.68773C9.10634 7.04525 9.62497 6.52662 10.2674 6.52662Z" 
                            fill={state === 'inactive' ? "#637381" : "#9F61DC"}
                        />
                    </svg>

                );

            case 'Billing':
                return (
<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.4607 3.33984H4.07549C3.21627 3.33984 2.53508 4.02877 2.53508 4.88799L2.52734 14.1769C2.52734 15.0361 3.21627 15.725 4.07549 15.725H16.4607C17.3199 15.725 18.0088 15.0361 18.0088 14.1769V4.88799C18.0088 4.02877 17.3199 3.33984 16.4607 3.33984ZM16.4607 14.1769H4.07549V9.53244H16.4607V14.1769ZM16.4607 6.43614H4.07549V4.88799H16.4607V6.43614Z"
                            fill={state === 'inactive' ? "#637381" : "#9F61DC"}
                        />
                    </svg>
                );
            // Add other cases for 'crm', 'tasks', etc.
            default:
                return <></>;
        }
    }

    return (
        <Tooltip title={icon} placement="right" arrow sx={{
        }}>
            <Stack direction="row" alignItems="center" justifyContent="center" width="40px" height="40px" sx={{
                backgroundColor: state === 'active' ? '#F7F2FC' : '#fff',
            borderRadius: '6.76px',
        }}>
                {getIcon()}
            </Stack>
        </Tooltip>
    );
}

export default SidebarIcon;