import React from 'react';

interface LogoProps {
  color: 'black' | 'white';
}

const Logo = ({ color }: LogoProps) => {

  if (color === 'white') {
    return (
      <svg width="157" height="33" viewBox="0 0 157 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_472_2899)">
<path d="M29.5426 11.1715V9.30915L25.8115 5.5845H21.6403V3.93168L17.9092 0.207031H0V8.21794L3.73109 11.9426H9.70957L0.387684 21.3066L0 21.8275V23.684L3.73109 27.4087H7.90816V29.0673L11.6392 32.792H29.5426V24.781L25.8115 21.0564H19.8331L29.1608 11.6924L29.5426 11.1715ZM24.494 7.03653L9.23152 22.3687H24.494V27.7491H9.23152V22.3687H1.32337V22.2349L16.5917 6.90267H1.32337V1.52229H16.5917V6.90267H24.494V7.03653Z" fill="#CC86ED"/>
<path d="M24.491 22.3691H9.22852V27.7495H24.491V22.3691Z" fill="white"/>
<path d="M24.4939 6.90234V7.0362L9.22847 22.3684H1.32031V22.2346L16.5916 6.90234H24.4939Z" fill="white"/>
<path d="M16.5887 1.52344H1.32031V6.90382H16.5887V1.52344Z" fill="white"/>
<path d="M38.4902 21.9461L48.9693 10.9904H38.7059V7.14648H54.394V11.0864L43.9149 22.0422H54.5776V25.8861H38.4902V21.9461Z" fill="white"/>
<path d="M55.666 7.14648H59.9189L63.8948 20.0547L68.4246 7.14648H72.555L76.9944 20.0867L81.1249 7.14648H85.3457L79.0261 25.8832H75.021L70.4592 12.911L65.7749 25.8832H61.6766L55.666 7.14648Z" fill="white"/>
<path d="M85.1699 16.5629C85.1699 11.3018 89.2683 7.14648 94.5384 7.14648C99.9019 7.14648 103.817 11.0835 103.817 16.4057V17.9771H89.1459C89.7318 20.529 91.7343 22.2226 94.6929 22.2226C97.0365 22.2226 98.8234 20.9917 99.6862 19.0537L103.105 21.0237C101.534 23.9481 98.7622 25.8861 94.69 25.8861C89.0234 25.8861 85.1699 21.7628 85.1699 16.5629ZM89.3616 14.4997H99.5929C98.9749 12.07 97.124 10.81 94.5355 10.81C92.0404 10.81 90.099 12.2853 89.3616 14.4997Z" fill="white"/>
<path d="M105.088 16.5629C105.088 11.3018 109.186 7.14648 114.456 7.14648C119.82 7.14648 123.735 11.0835 123.735 16.4057V17.9771H109.064C109.65 20.529 111.652 22.2226 114.611 22.2226C116.954 22.2226 118.741 20.9917 119.604 19.0537L123.023 21.0237C121.452 23.9481 118.68 25.8861 114.608 25.8861C108.941 25.8861 105.088 21.7628 105.088 16.5629ZM109.28 14.4997H119.511C118.896 12.07 117.048 10.81 114.456 10.81C111.958 10.81 110.017 12.2853 109.28 14.4997Z" fill="white"/>
<path d="M125.592 2.01758H129.784V25.8844H125.592V2.01758Z" fill="white"/>
<path d="M136.534 7.07031H132.342V25.8827H136.534V7.07031Z" fill="white"/>
<path d="M138.354 16.5629C138.354 11.3018 142.452 7.14648 147.722 7.14648C153.086 7.14648 157 11.0835 157 16.4057V17.9771H142.329C142.915 20.529 144.918 22.2226 147.877 22.2226C150.22 22.2226 152.007 20.9917 152.87 19.0537L156.289 21.0237C154.718 23.9481 151.946 25.8861 147.874 25.8861C142.207 25.8861 138.354 21.7628 138.354 16.5629ZM142.545 14.4997H152.777C152.161 12.07 150.313 10.81 147.722 10.81C145.227 10.81 143.286 12.2853 142.545 14.4997Z" fill="white"/>
<path d="M136.534 2.01758H132.342V5.72477H136.534V2.01758Z" fill="#CC86ED"/>
</g>
<defs>
<clipPath id="clip0_472_2899">
<rect width="157" height="33" fill="white"/>
</clipPath>
</defs>
      </svg>
    );
  } else {
  return (
<svg width="157" height="33" viewBox="0 0 157 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_452_2876)">
<path d="M29.5426 11.1715V9.30915L25.8115 5.5845H21.6403V3.93168L17.9092 0.207031H0V8.21794L3.73109 11.9426H9.70957L0.387684 21.3066L0 21.8275V23.684L3.73109 27.4087H7.90816V29.0673L11.6392 32.792H29.5426V24.781L25.8115 21.0564H19.8331L29.1608 11.6924L29.5426 11.1715ZM24.494 7.03653L9.23152 22.3687H24.494V27.7491H9.23152V22.3687H1.32337V22.2349L16.5917 6.90267H1.32337V1.52229H16.5917V6.90267H24.494V7.03653Z" fill="#CC86ED"/>
<path d="M24.491 22.3691H9.22852V27.7495H24.491V22.3691Z" fill="white"/>
<path d="M24.4939 6.90234V7.0362L9.22847 22.3684H1.32031V22.2346L16.5916 6.90234H24.4939Z" fill="white"/>
<path d="M16.5887 1.52344H1.32031V6.90382H16.5887V1.52344Z" fill="white"/>
<path d="M38.4902 21.9461L48.9693 10.9904H38.7059V7.14648H54.394V11.0864L43.9149 22.0422H54.5776V25.8861H38.4902V21.9461Z" fill="black"/>
<path d="M55.666 7.14648H59.9189L63.8948 20.0547L68.4246 7.14648H72.555L76.9944 20.0867L81.1249 7.14648H85.3457L79.0261 25.8832H75.021L70.4592 12.911L65.7749 25.8832H61.6766L55.666 7.14648Z" fill="black"/>
<path d="M85.1699 16.5629C85.1699 11.3018 89.2683 7.14648 94.5384 7.14648C99.9019 7.14648 103.817 11.0835 103.817 16.4057V17.9771H89.1459C89.7318 20.529 91.7343 22.2226 94.6929 22.2226C97.0365 22.2226 98.8234 20.9917 99.6862 19.0537L103.105 21.0237C101.534 23.9481 98.7622 25.8861 94.69 25.8861C89.0234 25.8861 85.1699 21.7628 85.1699 16.5629ZM89.3616 14.4997H99.5929C98.9749 12.07 97.124 10.81 94.5355 10.81C92.0404 10.81 90.099 12.2853 89.3616 14.4997Z" fill="black"/>
<path d="M105.088 16.5629C105.088 11.3018 109.186 7.14648 114.456 7.14648C119.82 7.14648 123.735 11.0835 123.735 16.4057V17.9771H109.064C109.65 20.529 111.652 22.2226 114.611 22.2226C116.954 22.2226 118.741 20.9917 119.604 19.0537L123.023 21.0237C121.452 23.9481 118.68 25.8861 114.608 25.8861C108.941 25.8861 105.088 21.7628 105.088 16.5629ZM109.28 14.4997H119.511C118.896 12.07 117.048 10.81 114.456 10.81C111.958 10.81 110.017 12.2853 109.28 14.4997Z" fill="black"/>
<path d="M125.592 2.01758H129.784V25.8844H125.592V2.01758Z" fill="black"/>
<path d="M136.534 7.07031H132.342V25.8827H136.534V7.07031Z" fill="black"/>
<path d="M138.354 16.5629C138.354 11.3018 142.452 7.14648 147.722 7.14648C153.086 7.14648 157 11.0835 157 16.4057V17.9771H142.329C142.915 20.529 144.918 22.2226 147.877 22.2226C150.22 22.2226 152.007 20.9917 152.87 19.0537L156.289 21.0237C154.718 23.9481 151.946 25.8861 147.874 25.8861C142.207 25.8861 138.354 21.7628 138.354 16.5629ZM142.545 14.4997H152.777C152.161 12.07 150.313 10.81 147.722 10.81C145.227 10.81 143.286 12.2853 142.545 14.4997Z" fill="black"/>
<path d="M136.534 2.01758H132.342V5.72477H136.534V2.01758Z" fill="#CC86ED"/>
</g>
<defs>
<clipPath id="clip0_452_2876">
<rect width="157" height="33" fill="white"/>
</clipPath>
</defs>
      </svg>
    );
  }
}

export default Logo;