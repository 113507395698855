import React, { CSSProperties, useEffect, useState } from 'react';
import HeroSection from './components/home/HeroSection';
import './components/home/home.css';
import { Box, Button, Typography, Card, CardContent, CardMedia, useMediaQuery, useTheme, IconButton, TextField, InputAdornment, DialogActions, ListItemText, ListItem, List, DialogContent, Dialog, DialogTitle, Divider, Stack, } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import TaskProduct from './components/home/TaskProduct';
import CRMProduct from './components/home/CRMProduct';
import { Icon } from '@iconify/react';
import emailChat from './components/home/img/emailchat.svg';
import eventChat from './components/home/img/eventchat.svg';
import websiteChat from './components/home/img/websitechat.svg';
import { FlightMessage, EmailMessage, EventMessage, WebsiteMessage, EmailMessageItem, WebsiteMessageItem, EventMessageItem } from './components/home/MobileMockup';

import bgellipse from './components/home/bgellipse.svg';
import integrations from './components/home/img/integrations/integrations.svg';


import momentTz from 'moment-timezone';
import axios from 'axios';
const btnStyle: CSSProperties = {
    padding: '10px 20px',
    border: '1px solid white',
    borderRadius: '5px',
    backgroundColor: 'transparent',
    color: 'white',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    textAlign: 'center',
    fontFamily: 'Public Sans',
    fontSize: '19px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    height: '50px',
};

const btnPrimaryStyle = {
    ...btnStyle,
    backgroundColor: '#9F61DC',
    border: 'none',
};

const iconBtnStyle = {
    borderRadius: '4px',
    '&:hover': {
        borderRadius: '4px',
    },
    '& .MuiTouchRipple-root .MuiTouchRipple-child': {
        borderRadius: '4px',
    },
};

const Home = () => {
    return (
        <div style={{ backgroundColor: '#F7F7F7', }}>
            <HeroSection />
            <ProductsSection />
            <IntegrationsSection />
            <AllActionsSection />


            {/* <FeaturesSection /> */}
            {/* <ResponsiveGrid /> */}

            <PricingSection />
            <ContactSection />
            <FAQSection />

        </div>
    );
}

export default Home;

const ResponsiveGrid = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    return (
        <Stack id="product" className="section" direction="column" gap={0} sx={{ backgroundColor: '#F7F7F7', height: 'auto', maxWidth: '1443px', margin: '0 auto' }}>

            <Stack
                direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
                sx={{ paddingX: isMobile ? '1%' : '10%', width: '100%' }}
            >
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                        width: { xs: '100%', sm: '100%', md: '100%', lg: '50%' },
                        alignItems: 'center',
                        justifyContent: 'center',

                    }}
                >
                    <ProductGridImage type="website" />
                </Stack>
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{ width: { xs: '100%', sm: '100%', md: '100%', lg: '50%' }, padding: '20px', alignItems: 'center', justifyContent: 'center', }}
                >
                    <Stack direction="column" sx={{ width: '100%', maxWidth: '480px', gap: isMobile ? '20px' : '25px' }}>
                        <Typography variant="subtitle1" sx={{ textAlign: isMobile ? 'center' : 'left', fontSize: isMobile ? '14px' : '17px', color: '#637381', textTransform: 'uppercase' }}>
                            Speed Up Your Search
                        </Typography>
                        <Typography variant="h2" sx={{ textAlign: isMobile ? 'center' : 'left', fontSize: isMobile ? '30px' : '50px', color: '#212B36', fontWeight: 'bold' }}>
                            Emily Delivers Data When You Need It
                        </Typography>
                        <Typography variant="body1" sx={{ textAlign: isMobile ? 'center' : 'left', fontSize: isMobile ? '18px' : '22px', color: '#637381', fontWeight: '600' }}>
                            Quickly find the information you need, whether <Typography component="span" sx={{ fontWeight: 'bold', fontSize: isMobile ? '18px' : '22px', color: '#212B36' }}>researching leads, gathering contacts, or locating important data</Typography>. Emily delivers results in seconds.
                        </Typography>
                        <button onClick={() => {
                            window.location.href = 'https://app.zweelie.com/login';
                        }} style={{
                            ...btnPrimaryStyle,
                            width: isMobile ? '100%' : '200px'
                        }} >Try For Free</button>                    </Stack>
                </Stack>
            </Stack>

            <Stack
                direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'column-reverse', lg: 'row' }}
                sx={{ paddingX: isMobile ? '1%' : '10%', width: '100%', height: 'auto' }}
            >

                <Stack
                    direction="column"
                    spacing={2}
                    sx={{ width: { xs: '100%', sm: '100%', md: '100%', lg: '50%' }, padding: '20px', alignItems: 'center', justifyContent: 'center', }}
                >
                    <Stack direction="column" sx={{ width: '100%', maxWidth: '480px', gap: isMobile ? '20px' : '25px' }}>
                        <Typography variant="subtitle1" sx={{ textAlign: isMobile ? 'center' : 'left', fontSize: isMobile ? '14px' : '17px', color: '#637381', textTransform: 'uppercase' }}>
                            Smart Inbox Solutions
                        </Typography>
                        <Typography variant="h2" sx={{ textAlign: isMobile ? 'center' : 'left', fontSize: isMobile ? '30px' : '50px', color: '#212B36', fontWeight: 'bold' }}>
                            Emily’s All-in-One Email Assistant
                        </Typography>
                        <Typography variant="body1" sx={{ textAlign: isMobile ? 'center' : 'left', fontSize: isMobile ? '18px' : '22px', color: '#637381', fontWeight: '600' }}>
                            Let Emily <Typography component="span" sx={{ fontWeight: 'bold', fontSize: isMobile ? '18px' : '22px', color: '#212B36' }}>compose, send, reply, search, and summarize emails</Typography> to keep your inbox organized and efficient.
                        </Typography>
                        <button onClick={() => {
                            window.location.href = 'https://app.zweelie.com/login';
                        }} style={{
                            ...btnPrimaryStyle,
                            width: isMobile ? '100%' : '200px'
                        }} >Try For Free</button>                    </Stack>
                </Stack>
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                        width: { xs: '100%', sm: '100%', md: '100%', lg: '50%' },
                        alignItems: 'center',
                        justifyContent: 'center',

                    }}
                >


                    <ProductGridImage type="email" />
                </Stack>

            </Stack>


            <Stack
                direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
                sx={{ paddingX: isMobile ? '1%' : '10%', width: '100%', height: 'auto' }}
            >
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                        width: { xs: '100%', sm: '100%', md: '100%', lg: '50%' },
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >



                    <ProductGridImage type="event" />
                </Stack>
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{ width: { xs: '100%', sm: '100%', md: '100%', lg: '50%' }, padding: '20px', alignItems: 'center', justifyContent: 'center' }}
                >
                    <Stack direction="column" sx={{ width: '100%', maxWidth: '480px', gap: isMobile ? '20px' : '25px' }}>
                        <Typography variant="subtitle1" sx={{ textAlign: isMobile ? 'center' : 'left', fontSize: isMobile ? '14px' : '17px', color: '#637381', textTransform: 'uppercase' }}>
                            Effortless Scheduling
                        </Typography>
                        <Typography variant="h2" sx={{ textAlign: isMobile ? 'center' : 'left', fontSize: isMobile ? '30px' : '50px', color: '#212B36', fontWeight: 'bold' }}>
                            Emily Keeps You on Time
                        </Typography>
                        <Typography variant="body1" sx={{ textAlign: isMobile ? 'center' : 'left', fontSize: isMobile ? '18px' : '22px', color: '#637381', fontWeight: '600' }}>
                            Stay effortlessly on schedule with Emily, your intelligent assistant who<Typography component="span" sx={{ fontWeight: 'bold', fontSize: isMobile ? '18px' : '22px', color: '#212B36' }}> manages your calendar</Typography> to ensure you never miss an important event.
                        </Typography>
                        <button onClick={
                            () => {
                                window.location.href = 'https://app.zweelie.com/login';
                            }
                        } style={{
                            ...btnPrimaryStyle,
                            width: isMobile ? '100%' : '200px'
                        }} >Try For Free</button>
                    </Stack>
                </Stack>
            </Stack>

            <Stack sx={{ marginTop: isDesktop ? '50px' : '10px', padding: isDesktop ? '30px' : '20px', color: '#212B36', textAlign: isMobile ? 'center' : 'center', height: 'auto', fontSize: isMobile ? '14px' : '22px', fontWeight: isMobile ? '500' : '600', backgroundColor: '#F7F7F7', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                Invoicing, documents, contracts and more capabilities coming soon
            </Stack>

        </Stack>
    );
}

const ActionsSection = () => {
    const [open, setOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width: 768px)');
    const actions = [
        {
            icon: <Icon icon="mdi:compass-outline" width={42} height={42} color='#C3BAD5' />,
            title: 'Create an itinerary',
            description: 'Plan every detail effortlessly with a custom itinerary at your fingertips.',
        },
        {
            icon: <Icon icon="mdi:location-on-outline" width={42} height={42} color='#C3BAD5' />,
            title: 'Search locations',
            description: 'Find the perfect spot effortlessly with our easy location search tool.',
        },
        {
            icon: <Icon icon="stash:airplane" width={42} height={42} color='#C3BAD5' />,
            title: 'Look up flight status',
            description: 'Check real-time flight updates to stay informed on any schedule changes.',
        },
        {
            icon: <Icon icon="material-symbols:article-outline" width={42} height={42} color='#C3BAD5' />,
            title: 'Find articles and email them',
            description: 'Discover articles and easily share them via email in just a few clicks.',
        },
        {
            icon: <Icon icon="material-symbols:task-outline" width={42} height={42} color='#C3BAD5' />,
            title: 'Prioritize tasks with ease',
            description: 'Quickly set priorities and rearrange tasks to stay focused on what’s most important.',
        },
        {
            icon: <Icon icon="mdi:contact-outline" width={42} height={42} color='#C3BAD5' />,
            title: 'Search contacts',
            description: 'Quickly find and connect with the people you need using search contacts.',
        },
    ]

    return (
        <Stack sx={{ gap: '50px', height: 'auto', paddingY: isMobile ? '50px' : '50px', px: isMobile ? '30px' : '10%', background: 'linear-gradient(100deg, #110B34 0.42%, #7C5BB6 98.89%)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ color: 'white', fontSize: isMobile ? '25px' : '40px', fontWeight: '600', width: 'auto', maxWidth: '750px', textAlign: 'center' }}>
                Save yourself time
            </div>

            <Stack direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }} gap={isMobile ? '30px' : '30px'} sx={{ width: '100%', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                {/* {actions.map((action, index) => (
                    <ActionCard key={index} title={action.title} description={action.description} icon={action.icon} />
                ))} */}

            </Stack>


            <Stack sx={{ cursor: 'pointer', width: '100%', color: 'white', textAlign: 'center' }} onClick={() => setOpen(true)}>
                <span style={{ fontSize: '25px', fontWeight: '600' }}><span style={{ color: '#E49BFD' }}>Click here</span> to view a list of more actions available</span>
            </Stack>
            <FullListOfActionsDialog open={open} onClose={() => setOpen(false)} />
        </Stack>
    )
}


// const ActionCard = ({ title, description, icon }: { title: string, description: string, icon: React.ReactNode }) => {
//     const theme = useTheme();
//     const isMobile = useMediaQuery(theme.breakpoints.down('md'));

//     const [open, setOpen] = useState(false);
//     return (
//         <Stack direction={'column'} gap={'10px'} sx={{ textAlign: isMobile ? 'center' : 'left', p: '24px', height: isMobile ? 'auto' : '250px', width: { xs: '100%', sm: '100%', md: '100%', lg: '460px' }, borderRadius: '4px', border: '0.5px solid rgba(255, 255, 255, 0.50)', background: 'linear-gradient(109deg, rgba(198, 198, 198, 0.12) 2.94%, rgba(255, 255, 255, 0.12) 48.93%, rgba(153, 153, 153, 0.12) 98.76%)' }}>
//             <div style={{ flexShrink: 0 }}>{icon}</div>
//             <Stack sx={{ fontSize: isMobile ? '18px' : '25px', fontWeight: '600', color: '#fff' }}>
//                 {title}
//             </Stack>
//             <Stack sx={{ fontSize: isMobile ? '16px' : '22px', color: '#CAC6D5' }}>
//                 {description}
//             </Stack>
//         </Stack>
//     )
// }

const PricingSection = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const features = [
        { title: 'Up to 5 team members' },
        { title: 'Unlimited assistant hours' },
        { title: 'CRM and unlimited contacts' },
        { title: 'Time tracking' },
        { title: 'Task management' },
        { title: 'Contact management' },
        { title: 'Email management' },
        { title: 'Calendar management' },
        { title: 'Search contacts' },
        { title: 'Web research' },
        { title: 'Flight status lookup' },
        { title: 'iOS & Web app' },
        { title: 'Third party integrations' },
        { title: 'Priority customer support' },


    ]


    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
    return (
        <Stack id="pricing" className="section" sx={{ width: 'auto', backgroundColor: '#F7F7F7', paddingTop: isDesktop ? '0px' : '0', paddingBottom: isDesktop ? '50px' : '0px', px: isDesktop ? '15%' : '5%' }} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent={'center'}>

            <Stack direction={'column'} gap={isDesktop ? '25px' : '10px'}>
                <h1 style={{ textAlign: 'center', color: '#0C082D' }}>
                    Simple, <span style={{ background: 'linear-gradient(90deg, #B64CDB 0%, #7456AC 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>affordable</span> pricing
                </h1>
                <div style={{ fontSize: isDesktop ? '18px' : '22px', color: '#6A687E', textAlign: isDesktop ? 'center' : 'center', maxWidth: '890px' }}>
                    Gain full access to all features of Zweelie and Emily for only $19/month. Start your free 7-day trial today.
                </div>
            </Stack>

            <Stack sx={{
                mt: isDesktop ? '50px' : '30px',
                padding: isDesktop ? '35px' : '15px', borderRadius: '4px',
                width: isDesktop ? 'auto' : isTablet && !isMobile ? '45%' : '100%',

            }}
                gap={isDesktop ? '35px' : '35px'}
                direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
            >

                <Stack direction={'column'} gap={'25px'} sx={{
                    width: 'auto', height: '100%',
                    border: '2px solid transparent', // Set border to transparent
                    backgroundImage: 'linear-gradient(white, white), linear-gradient(to bottom right, #ECCEB3, #9F61DC)', // Two backgrounds
                    backgroundOrigin: 'border-box', // Ensures the gradient is applied to the border
                    backgroundClip: 'padding-box, border-box', // Clips the background to the border
                    padding: '20px',
                    borderRadius: '14px',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    flexGrow: 1
                }}>
                    <Stack sx={{ px: '20px', fontSize: '18px', color: '#110B34', textAlign: 'center', justifyContent: 'center', textTransform: 'uppercase', paddingY: '2px', border: '1px solid #DFE1E6', borderRadius: '4px', backgroundColor: 'white' }}>
                        Basic
                    </Stack>
                    <Stack sx={{ fontSize: '22px', color: '#110B34' }} gap={'5px'} direction={'row'} alignItems={'center'} justifyContent={'center'}>
                        <span style={{ fontSize: '30px', fontWeight: '600' }}>$19</span><span style={{ fontSize: '20px', color: '#637381' }}>/</span><span style={{ fontSize: '16px', color: '#637381' }}>month</span>
                    </Stack>
                    <button onClick={() => {
                        window.location.href = 'https://app.zweelie.com/login';
                    }} style={{
                        ...btnPrimaryStyle,
                        width: isDesktop ? '300px' : isTablet && !isMobile ? '100%' : '100%',

                    }}>Try Zweelie For Free</button>
                    <div style={{ fontSize: '16px', color: '#637381', textAlign: 'center' }}>
                        Or <a style={{ color: '#9F61DC', textDecoration: 'none' }} href="mailto:support@zweelie.com">contact us</a> for custom plans
                    </div>

                </Stack>

                <Stack direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }} sx={{ width: '100%' }} gap={{ xs: '10px', sm: '10px', md: '10px', lg: '35px' }}>
                    <Stack direction={'column'} gap={'10px'} sx={{ width: { xs: '100%', sm: '100%', md: '100%', lg: '50%' }, alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                        {features.slice(0, Math.ceil(features.length / 2)).map((feature, index) => (
                            <Stack direction={'row'} gap={'10px'} alignItems={'flex-start'} justifyContent={'flex-start'} sx={{ width: 'auto', minWidth: isMobile ? '100%' : '550px' }}>
                                <Icon icon="mdi:check-circle" color={'#7355AB'} width={isMobile ? '20px' : '30px'} height={isMobile ? '20px' : '30px'} style={{ flexShrink: 0 }} /> <span style={{ fontSize: isMobile ? '16px' : '20px', color: '#212B36' }}>{feature.title}</span>
                            </Stack>
                        ))}
                    </Stack>
                    <Stack direction={'column'} gap={'10px'} sx={{ width: { xs: '100%', sm: '100%', md: '100%', lg: '50%' }, alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                        {features.slice(Math.ceil(features.length / 2), features.length).map((feature, index) => (
                            <Stack direction={'row'} gap={'10px'} alignItems={'flex-start'} justifyContent={'flex-start'} sx={{ width: 'auto' }}>
                                <Icon icon="mdi:check-circle" color={'#7355AB'} width={isMobile ? '20px' : '30px'} height={isMobile ? '20px' : '30px'} /> <span style={{ fontSize: isMobile ? '16px' : '20px', color: '#212B36' }}>{feature.title}</span>
                            </Stack>
                        ))}
                    </Stack>
                </Stack>


            </Stack>

        </Stack>
    )
}

const ContactSection = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const sendEmail = () => {
        setSuccess(false);
        setError('');

        if (name === '' || email === '' || message === '') {
            setError('Please fill in all fields');
            return;
        }

        // make sure email is valid
        if (!email.includes('@')) {
            setError('Please enter a valid email');
            return;
        }

        setSubmitting(true);

        try {
            axios.post(`${process.env.REACT_APP_ZWEELIE_API_URL}/email/support`, {
                name: name,
                email: email,
                message: message
            })
            setSuccess(true);
            setName('');
            setEmail('');
            setMessage('');
        } catch (error) {
            console.log(error);
            setError('An error occurred. Please try again later.');
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <Stack id="contact" className="section" direction={!isDesktop ? 'column' : 'row'} paddingX={isDesktop ? '15%' : '5%'} pt={'0'} paddingBottom={'0'}>

            <Stack direction={'column'} width={isDesktop ? '55%' : '100%'} sx={{ paddingX: '0%', py: isDesktop ? '50px' : '30px', alignItems: 'flex-start', justifyContent: 'center' }}>

                <Stack direction={'column'} gap={'10px'} width={isDesktop ? '70%' : '100%'}>
                    <h1 style={{ textAlign: 'center', color: '#0C082D' }}>
                        Contact <span style={{ background: 'linear-gradient(90deg, #B64CDB 0%, #7456AC 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>us</span>
                    </h1>
                    <div style={{ fontSize: isMobile ? '18px' : '22px', color: '#6A687E', textAlign: isDesktop ? 'left' : 'center', maxWidth: '890px' }}>
                        We are here to help. Connect with our friendly team who is available 24/7
                        to help you get started with Zweelie.
                    </div>
                </Stack>
            </Stack>
            <Stack direction={'column'} width={isDesktop ? '45%' : '100%'} sx={{ alignItems: 'flex-start', justifyContent: 'center' }}>

                <Stack direction={'column'} spacing={2} sx={{ backgroundColor: 'white', padding: '35px', borderRadius: '4px', boxShadow: '0px 4px 22px 0px rgba(0, 0, 0, 0.06)', border: '1px solid #DFE1E6', width: '100%' }}>
                    <span style={{ fontSize: '25px', fontWeight: '600', color: '#0C082D' }}>Get In Touch</span>
                    <Stack direction={'row'} spacing={2}>
                        <TextField
                            size='small'
                            label="Name"
                            variant="outlined"
                            required
                            fullWidth
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            sx={{ '& .MuiOutlinedInput-root': { borderRadius: '4px' } }}
                        />
                        <TextField
                            size='small'
                            label="Email"
                            variant="outlined"
                            type='email'
                            required
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            sx={{ '& .MuiOutlinedInput-root': { borderRadius: '4px' } }}
                        />
                    </Stack>
                    <TextField
                        size='small'
                        label="Message"
                        variant="outlined"
                        required
                        fullWidth
                        multiline
                        rows={4}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        sx={{ '& .MuiOutlinedInput-root': { borderRadius: '4px' } }}
                    />
                    <button onClick={sendEmail} style={{ ...btnPrimaryStyle, width: '100%' }} disabled={submitting}>{submitting ? 'Sending...' : 'Send Message'}</button>
                    {error && <div style={{ fontSize: '16px', color: 'red', textAlign: 'center' }}>{error}</div>}
                    {success && <div style={{ fontSize: '16px', color: 'green', textAlign: 'center' }}>Message sent successfully!</div>}
                </Stack>

            </Stack>

        </Stack>

    )
}
const ContactCard = ({ title, description, icon, link, button_text }: { title: string, description: string, icon: React.ReactNode, link: string, button_text: string }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Stack direction={'column'} gap={isMobile ? '13px' : '10px'} sx={{ textAlign: isMobile ? 'center' : 'left', p: '24px', height: isMobile ? 'auto' : '250px', width: { xs: '100%', sm: '100%', md: '100%', lg: '460px' }, borderRadius: '4px', border: '0.5px solid rgba(255, 255, 255, 0.50)', background: 'linear-gradient(109deg, rgba(198, 198, 198, 0.12) 2.94%, rgba(255, 255, 255, 0.12) 48.93%, rgba(153, 153, 153, 0.12) 98.76%)' }}>
            <div style={{ flexShrink: 0 }}>{icon}</div>
            <Stack sx={{ textAlign: isMobile ? 'center' : 'left', fontSize: isMobile ? '18px' : '25px', fontWeight: '600', color: '#fff' }}>
                {title}
            </Stack>
            <Stack sx={{ textAlign: isMobile ? 'center' : 'left', fontSize: isMobile ? '16px' : '22px', color: '#CAC6D5' }}>
                {description}
            </Stack>
            <a href={link} style={{
                ...btnPrimaryStyle,
                backgroundColor: 'white',
                color: '#212B36',
                width: '100%',
                textDecoration: 'none',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

            }}>{button_text}</a>
        </Stack>
    )
}


const FAQSection = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const faq = [
        {
            title: 'What is Zweelie?',
            description: 'Zweelie is a productivity platform powered by a proactive AI assistant. Being proactive means anticipating needs and taking action in advance. It combines task management, CRM, calendar scheduling, and more, helping small teams and busy professionals save time, stay focused, and get work done efficiently.'
        },
        {
            title: 'What makes Zweelie different from other productivity tools?',
            description: 'Unlike traditional tools or chatbots, Zweelie’s AI assistant Emily is proactive and emotionally intelligent. It not only automates repetitive tasks but also adapts to your workflows, recognizes emotional cues, and provides tailored recommendations to keep you organized and productive.'
        },
        {
            title: 'How does Zweelie’s AI assistant help me save time?',
            description: 'Zweelie’s assistant automates tasks like creating CRM records, scheduling meetings, tracking team tasks, and composing emails. It works across tools seamlessly and even handles multiple actions with a single voice command, freeing you to focus on higher-value work.',
        },
        {
            title: 'Can Zweelie integrate with my existing tools?',
            description: 'Yes! Zweelie integrates effortlessly with your calendars, email, and other productivity tools, ensuring all your tasks, events, and CRM records are in sync for a unified workflow experience.'
        },
        {
            title: 'Is Zweelie available on mobile?',
            description: 'Yes, Zweelie is available on both web and iOS, giving you access to your CRM, tasks, schedules, and AI assistant on the go, whenever and wherever you need it.'
        },
        {
            title: 'Who is Zweelie for?',
            description: 'Zweelie is designed for busy professionals, small teams, and entrepreneurs who value time and efficiency and want to streamline their workflows, reduce manual tasks, and increase productivity.'
        },
        {
            "title": "Why is emotional intelligence important to Emily?",
            "description": "Emily’s emotional intelligence allows her to recognize cues like frustration, urgency, or fatigue, adapting her actions to support you effectively. This is crucial as stress and overwhelm can reduce focus and productivity. By understanding your emotions, Emily helps you stay organized, make better decisions, and avoid burnout—offering smarter, more human-like assistance."
        }

    ]

    return (
        <Stack id="faq" className="section" sx={{ backgroundColor: '#F7F7F7', paddingTop: isDesktop ? '50px' : '30px', paddingBottom: isDesktop ? '80px' : '30px', px: !isDesktop ? '5%' : '15%' }} alignItems={isMobile ? 'center' : 'center'} justifyContent={'center'}>

            <Stack direction={'column'} >
                <h1 style={{ textAlign: 'center', color: '#0C082D' }}>
                    Frequently asked <span style={{ background: 'linear-gradient(90deg, #B64CDB 0%, #7456AC 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>questions</span>
                </h1>

            </Stack>
            <Stack sx={{ width: '100%' }} direction={'column'} gap={isMobile ? '20px' : '30px'} mt={isMobile ? '30px' : '50px'} >

                {faq.map((faq, index) => (
                    <FAQCard key={index} title={faq.title} description={faq.description} />
                ))}

            </Stack>

        </Stack>
    )
}

const FAQCard = ({ title, description }: { title: string, description: string }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Stack direction={'column'} gap={'10px'} sx={{
            backgroundColor: 'white', padding: '15px', borderRadius: '4px',
            border: '1px solid #DFE1E6',
            width: '100%',
            cursor: 'pointer',
        }}
            onClick={() => setIsOpen(!isOpen)}
        >
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <div style={{ fontSize: isMobile ? '15px' : '20px', fontWeight: '600', color: '#212B36' }}>{title}</div>
                <IconButton onClick={() => setIsOpen(!isOpen)} sx={{
                    ...iconBtnStyle
                }}>
                    <Icon icon={isOpen ? "mdi:chevron-up" : "mdi:chevron-down"} color={'#212B36'} width={'20px'} height={'20px'} />
                </IconButton>
            </Stack>
            {isOpen && <div style={{ fontSize: isMobile ? '14px' : '20px', color: '#637381', whiteSpace: 'pre-line' }}>{description}</div>}
        </Stack>
    )
}



interface ProductGridImageProps {
    type: 'email' | 'website' | 'event'
}

const ProductGridImage = ({ type }: ProductGridImageProps) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const [scale, setScale] = useState(1);
    useEffect(() => {
        const handleResize = () => {
            const newScale = Math.max(0.80, Math.min(1, window.innerWidth / 1920));
            setScale(newScale);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial scale

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    return (
        <div style={{
            position: 'relative',
            width: isDesktop ? '571px' : 'auto',
            maxWidth: '571px',
            height: isDesktop ? '668px' : '465px',
            display: 'flex',
            justifyContent: isDesktop ? 'flex-start' : 'center', // Center content when not on desktop
            transform: isDesktop ? `scale(${scale})` : `scale(0.6) translateY(-120px)`

        }}>

            <ProductGridImageContent type={type} />

        </div>
    )
}

const ProductGridImageContent = ({ type }: { type: 'email' | 'website' | 'event' }) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const [imageSrc, setImageSrc] = useState('');

    let eventContent = {
        "is_new": false,
        "event_id": "47dce828-cb00-48ca-868e-f243eac4486e",
        "provider": "google_calendar",
        "updated_at": "2024-10-31T13:31:43.057Z",
        "status": "confirmed",
        "provider_event_id": "sotmuf4s3c1aorjb4ejflg0u5g",
        "title": "Flight to Toronto from San Francisco",
        "start_time": `${momentTz.tz('America/Toronto').add(1, 'days').format('YYYY-MM-DD')}T14:30:00-04:00`,
        "end_time": `${momentTz.tz('America/Toronto').add(1, 'days').format('YYYY-MM-DD')}T19:30:00-04:00`,
        "display_timezone": "America/Toronto",
        "calendar_email": "alex@acmeinc.com",
        "description": "This is a flight to Toronto from San Francisco.",
        "all_day": false,
        "event_link": "https://google.com",
        "attendees": [
            {
                "name": "Alex Williams",
                "email": "alex@acmeinc.com",
                "is_me": true,
                "response_status": "accepted"
            },
            {
                "name": "James Smith",
                "email": "james.smith@acmeinc.com",
                "is_me": false,
                "response_status": "accepted"
            }
        ],
        "actions": [
            {
                "type": "location",
                "entry_point": "map",
                "location": "YYZ, Toronto, ON",
                "provider": "map"
            },

        ],
        "creator": {
            "email": "alex@acmeinc.com"
        },
        "integration_id": "d7ae0d39-7a35-4f6d-ac1b-daf0bdf1c084"
    }


    let emailContent = {
        "type": "new",
        "provider": "gmail",
        "provider_message_id": "y8y080y12",
        "provider_thread_id": "y8y080y12",
        "sender": "alex@acmeinc.com",
        "date": `${momentTz.tz('America/Toronto').format('YYYY-MM-DD')}T13:30:00.000Z`,
        "from": [
            {
                "address": "alex@acmeinc.com",
                "name": "Alex Williams"
            }
        ],
        "to": [
            {
                "address": "henry@acmeinc.com",
                "name": "Henry Douglas"
            }
        ],
        "cc": [{
            "address": "amber.smith@acmeinc.com",
            "name": "Amber Smith"
        }
        ],
        "bcc": [],
        "subject": "Alex's availability for tomorrow",
        "reply_to": [],
        "text": "Hi Henry,\n\nHere are my available times for a meeting tomorrow morning:\n\n• 9:00 AM - 9:15 AM\n• 9:30 AM - 9:45 AM\n\nLet me know what works best for you.\n\nAlex",
        "html": "<html><body>Hi Henry,<br><br>Here are my available times for a meeting tomorrow morning:<br><br>• 9:00 AM - 9:15 AM<br>• 9:30 AM - 9:45 AM<br><br>Let me know what works best for you.<br><br>Alex</body></html>",
        "attachments": []
    }

    let websiteContent = {
        "url": "https://www.fairmont.com/royal-york-toronto/",
        "title": "The Fairmont Royal York Toronto",
        "summary": "The Fairmont Royal York Toronto is a luxury hotel located in downtown Toronto. The hotel offers a range of amenities including a fitness centre, a restaurant and a bar. The rooms are spacious and have a modern design. The hotel also offers a shuttle service to the airport. The hotel is a great choice for both business and leisure travellers.",
        "image": "https://www.fairmont.com/assets/0/104/2793/2798/4415/4416/2a8bc888-2178-4066-9d11-f53108f1eb9b.jpg"
    }

    useEffect(() => {
        setImageSrc(type === 'email' ? emailChat : type === 'event' ? eventChat : websiteChat);
    }, [type]);



    const [imgPosition, setImgPosition] = useState({})

    useEffect(() => {

        if (type === 'email') {
            setImgPosition({ ...isDesktop ? { left: '-30px' } : { left: '50%', transform: 'translateX(-50%)' } })
        } else if (type === 'event') {
            setImgPosition({ ...isDesktop ? { right: '0' } : { left: '50%', transform: 'translateX(-50%)' } })
        } else if (type === 'website') {
            setImgPosition({ ...isDesktop ? { right: '0' } : { left: '50%', transform: 'translateX(-50%)' } })
        }
    }, [type])

    return (
        <>
            <img src={imageSrc} alt="chat" style={{
                position: 'absolute',
                top: '0',
                ...isDesktop ? { left: '0' } : { left: '50%', transform: 'translateX(-50%)' }, // Center image when not on desktop
                height: 'auto',
                width: '550px'
            }} />

            <Stack sx={{
                width: '421px',
                height: 'auto',
                position: 'absolute',
                top: type === 'website' ? '280px' : '244px',
                ...isDesktop && imgPosition,
                backgroundColor: 'white',
                border: '1px solid #A064DC',
                padding: '18px',
                borderRadius: '24px',
                boxShadow: '0px 4px 25px 0px rgba(0, 0, 0, 0.10)',
                zIndex: 2,

            }}>
                {type === 'email' && <EmailMessage content={emailContent as EmailMessageItem} />}
                {type === 'event' && <EventMessage content={eventContent as EventMessageItem} />}
                {type === 'website' && <WebsiteMessage content={websiteContent as WebsiteMessageItem} />}
            </Stack>
        </>
    )
}
interface Actions {
    [key: string]: string[];
}

const FullListOfActionsDialog = ({ open, onClose }: { open: boolean; onClose: () => void }) => {

    let actions: Actions = {
        "General": [
            "Get current time, date, and timezone (including specific countries).",
            "Emily offers general life advice, both personal and professional.",
            "Emily detects your emotional tone and suggests actions based on it.",
            "Show images, map locations, phone numbers, links, and more."
        ],
        "CRM": [
            "Get details on CRM contacts.",
            "Create CRM contacts from emails, events, or other inputs or web searches.",
            "Assign a company to a CRM contact.",
            "Get contacts by company, status, or lists"
        ],
        "Team Management": [
            "Get details on teams and team members.",
            "Retrieve task lists and statuses by team."
        ],
        "Task Management": [
            "Get all tasks, including those that are overdue or for a specific period (today, week).",
            "Create, update, or delete tasks (name, status, priority, due date, etc.).",
            "Create multiple tasks or task lists at once.",
            "Move tasks between statuses or priorities.",
            "Track time spent on tasks, including starting/stopping time entries.",
            "Create tasks based on emails, search results, locations, or other inputs.",
            "Revert or undo task actions, except for deletions.",
            "Get time estimates and adjust them for tasks.",
            "Prioritize or re-prioritize multiple tasks at once."
        ],
        "Event Management": [
            "Access and manage all connected calendars.",
            "Get a schedule overview for today, tomorrow, or any specific date.",
            "Look up event details (title, location, time, attendees, duration).",
            "Check for upcoming events within specific time frames (next hour, morning, evening).",
            "Create, update, or delete events with confirmation and notify attendees.",
            "Suggest new times for events and reschedule them.",
            "Create events based on tasks or from search results.",
            "Find availability during specific time ranges.",
            "Add or replace attendees for an event.",
            "Move or shuffle multiple events.",
            "Block time ranges for specific durations.",
        ],
        "Contact Management": [
            "Look up contacts by name, email, or domain.",
            "Retrieve all contacts associated with a specific email domain."
        ],
        "Email Management": [
            "Search emails by sender or keyword.",
            "Check if an email was replied to or not.",
            "Summarize emails and create tasks or events from them.",
            "Compose, reply, reply all or forward emails.",
            "Add CC or BCC to an email.",
            "Suggest meeting times based on calendar availability.",
            "Send emails to a group of contacts and add details like links or flight information."
        ],
        "Lookup and Research": [
            "Search for locations, businesses, and services by various criteria (name, city, hours of operation, type of business).",
            "Look up flight statuses and create events based on flight details.",
            "Search the web and summarize results into tasks or events.",
            "Find and email recipes, images, or job openings."
        ]
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" sx={{ '& .MuiPaper-root': { borderRadius: '4px' } }}>
            <DialogTitle>More Actions Emily Can Perform</DialogTitle>
            <Divider />

            <DialogContent>
                {Object.keys(actions).map((category) => (
                    <div key={category} style={{ marginBottom: '20px' }}>
                        <div style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '10px' }}>{category}</div>
                        <ul>
                            {actions[category].map((action) => (
                                <li key={action} style={{ marginBottom: '5px' }}>{action}</li>
                            ))}
                        </ul>
                    </div>
                ))}

                <div style={{ marginTop: '20px', fontSize: '18px', fontWeight: 'bold' }}>More actions coming soon!</div>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button sx={{ borderRadius: '4px' }} onClick={onClose} color="inherit" variant="outlined">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
};





const AllActionsSection = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));


    const actions = [
        {
            "General": [
                {
                    icon: "tabler:calendar-time",
                    title: "Time and date",
                    description: "Get current time, date, and timezone details for any location."
                },
                {
                    icon: "mdi:emoticon-outline",
                    title: "Emotion-aware suggestions",
                    description: "Emily detects emotional tone and provides tailored recommendations."
                },
                {
                    icon: "mdi:image-outline",
                    title: "Visual support",
                    description: "Show maps, images, phone numbers, or links as needed."
                },
                {
                    icon: "material-symbols:dashboard-outline",
                    title: "Daily overview",
                    description: "Quickly see what tasks you have for the day and top leads."
                }
            ]
        },
        {
            "CRM": [
                {
                    icon: "material-symbols:table-outline",
                    title: "Quickly build CRM records",
                    description: "Create or update CRM records from emails, events, web and more."
                },
                {
                    icon: "material-symbols:assignment-add-outline",
                    title: "Streamline assignments",
                    description: "Assign companies or team members to records."
                },
                {
                    icon: "material-symbols:contacts-outline",
                    title: "Detailed insights",
                    description: "Retrieve contact details by company, status, or lists."
                },
                {
                    icon: "material-symbols:search-activity",
                    title: "Track activity",
                    description: "Record deals, notes, and more."
                }
            ],
        }, {
            "Tasks": [
                {
                    icon: "material-symbols:add-task",
                    title: "Create tasks quickly",
                    description: "Create tasks from emails, events, or web searches or other inputs."
                },
                {
                    icon: "mdi:bell-alert-outline",
                    title: "Comprehensive task tracking",
                    description: "Track time, adjust estimates, get overdue alerts, and more."
                },
                {
                    icon: "tabler:circles-relation",
                    title: "Manage relationships",
                    description: "Assign relationships between tasks, such as dependencies or blockers."
                },
                {
                    icon: "mdi:account-outline",
                    title: "Task Assignment",
                    description: "Reallocate tasks to various statuses and team members."
                }
            ]
        },
        {
            "Events": [
                {
                    icon: "mdi:calendar-outline",
                    title: "Complete schedule control",
                    description: "Access and manage all calendars in one place."
                },
                {
                    icon: "mdi:calendar-plus-outline",
                    title: "Quick event management",
                    description: "Create or reschedule events from emails, tasks, web, and more."
                },
                {
                    icon: "mdi:calendar-clock-outline",
                    title: "Availability finder",
                    description: "Discover and email free time slots to contacts."
                },
                {
                    icon: "mdi:calendar-search-outline",
                    title: "Create itineraries",
                    description: "Plan and organize detailed itineraries for events and meetings."
                },
            ]
        },
        {
            "Contacts": [
                {
                    icon: "mdi:contact-outline",
                    title: "Create contacts",
                    description: "Create or search for contacts from emails, events or other inputs."
                },
                {
                    icon: "material-symbols:person-search-outline",
                    title: "Instant lookup",
                    description: "Find contacts by name, email, or domain in seconds."
                },
                {
                    icon: "mdi:internet",
                    title: "Domain insights",
                    description: "Retrieve all contacts linked to a specific domain."
                },
                {
                    icon: "mdi:filter-outline",
                    title: "Quick filtering",
                    description: "Search and sort contacts based on relevance or need."
                }
            ]
        },
        {
            "Emails": [
                {
                    icon: "mdi:email-fast-outline",
                    title: "Compose emails",
                    description: "Compose (single recipient, cc, bcc), reply, reply all, or forward emails."
                },
                {
                    icon: "mdi:email-search-outline",
                    title: "Email insights",
                    description: "Search or summarize emails and create tasks or events from them."
                },
                {
                    icon: "mdi:email-plus-outline",
                    title: "Draft from web searches",
                    description: "Create emails from web search results."
                },
                {
                    icon: "material-symbols:event-note-outline",
                    title: "Meeting made easy",
                    description: "Suggest meeting times directly from your inbox."
                }
            ]
        },
        {
            "Lookup and research": [
                {
                    icon: "mdi:search-web",
                    title: "Web search",
                    description: "Search the web, summarize results into tasks, events, or emails."
                },
                {
                    icon: "mdi:location-on-outline",
                    title: "Localized searches",
                    description: "Search businesses, services, or locations by city, type, or industry."
                },
                {
                    icon: "mdi:text-search",
                    title: "Images, jobs, and more",
                    description: "Find images, upload for descriptions, search jobs, and more."
                },
                {
                    icon: "jam:plane",
                    title: "Flight tracking",
                    description: "Check flight statuses and create calendar events quickly."
                }
            ]
        }
    ]

    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    return (<Stack width="100%" px={'5%'} py={'50px'}>
        <h1 style={{ textAlign: 'center', color: '#0C082D' }}>Unleash <span style={{ background: 'linear-gradient(90deg, #B64CDB 0%, #7456AC 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>10x efficiency</span> with a proactive,<br /> action-ready assistant</h1>

        <Stack width="100%" spacing={2}>
            {actions.map((actionGroup, index) => {
                const groupTitle = Object.keys(actionGroup)[0];
                return (
                    <Stack key={index} width="100%" spacing={2}>
                        <span style={{ fontSize: '20px', fontWeight: 'bold', color: '#0C082D', width: '100%' }}>{groupTitle}</span>
                        <Stack direction="column" spacing={2} sx={{ width: '100%' }}>

                            <Stack width="100%" direction={!isDesktop ? 'column' : 'row'} spacing={2}>

                                <Stack key={`${groupTitle}-1`} direction="column" sx={{ width: !isDesktop ? '100%' : '50%' }}>
                                    <ActionCard action={actionGroup[groupTitle as keyof typeof actionGroup]?.[0]} />
                                </Stack>
                                <Stack key={`${groupTitle}-2`} direction="column" sx={{ width: !isDesktop ? '100%' : '50%' }}>
                                    <ActionCard action={actionGroup[groupTitle as keyof typeof actionGroup]?.[1]} />
                                </Stack>
                            </Stack>
                            <Stack width="100%" direction={!isDesktop ? 'column' : 'row'} spacing={2}>


                                <Stack key={`${groupTitle}-3`} direction="column" sx={{ width: !isDesktop ? '100%' : '50%' }}>
                                    <ActionCard action={actionGroup[groupTitle as keyof typeof actionGroup]?.[2]} />
                                </Stack>
                                <Stack key={`${groupTitle}-4`} direction="column" sx={{ width: !isDesktop ? '100%' : '50%' }}>
                                    <ActionCard action={actionGroup[groupTitle as keyof typeof actionGroup]?.[3]} />
                                </Stack>
                            </Stack>


                        </Stack>
                    </Stack>
                );
            })}
        </Stack>
    </Stack>)
}

const ActionCard = ({ action }: { action: any }) => {
    if (!action) {
        return null;
    }
    return (
        <Stack
            direction="row"
            spacing={2}
            sx={{
                backgroundColor: 'white',
                borderRadius: '4px',
                border: '1px solid #DFE1E6',
                padding: '20px',
                height: 'auto',

            }}>
            <Icon icon={action.icon} width={24} height={24} style={{ color: '#9F61DC', flexShrink: 0 }} />
            <Stack direction="column" alignItems="flex-start" >
                {action.title && <span style={{ fontSize: '18px', fontWeight: 'bold', margin: 0, color: '#0C082D' }}>{action.title}</span>}
                {action.description && <span style={{ fontSize: '14px', color: '#6A687E', margin: 0 }}>{action.description}</span>}
            </Stack>

        </Stack>
    )
}

const ProductsSection = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <Stack id="product" className="section" px={isDesktop ? '15%' : '12%'} pt={'50px'} direction={'column'} spacing={5} zIndex={200}>

            <Stack direction={isDesktop ? 'row' : 'column-reverse'} width={'100%'} spacing={isDesktop ? 0 : 6}>
                <Stack direction={'column'} width={isDesktop ? '50%' : '100%'} sx={{ boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)', borderRadius: '8px', overflow: 'visible' }}>
                    <TaskProduct />
                </Stack>
                <Stack direction={'column'} width={isDesktop ? '50%' : '100%'} alignItems={'left'} justifyContent={'center'} pl={isDesktop ? '50px' : 0}>

                    <h1 style={{ textAlign: isDesktop ? 'left' : 'center', color: '#0C082D' }}>Simplify your <span style={{ background: 'linear-gradient(90deg, #B64CDB 0%, #7456AC 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>task management</span></h1>
                    <span style={{ textAlign: isDesktop ? 'left' : 'center', color: '#6A687E', fontSize: '20px' }}>
                        Stay organized effortlessly with AI-powered task management, so you can focus on priorities and get more done.
                    </span>
                </Stack>

            </Stack>


            <Stack direction={isDesktop ? 'row' : 'column'} width={'100%'} spacing={isDesktop ? 0 : 6}>

                <Stack direction={'column'} width={isDesktop ? '50%' : '100%'} alignItems={'left'} justifyContent={'center'} pr={isDesktop ? '50px' : 0}>

                    <h1 style={{ textAlign: isDesktop ? 'left' : 'center', color: '#0C082D' }}>CRM built for the <span style={{ background: 'linear-gradient(90deg, #B64CDB 0%, #7456AC 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>future of work</span></h1>
                    <span style={{ textAlign: isDesktop ? 'left' : 'center', color: '#6A687E', fontSize: '20px' }}>
                        Enhance your relationship management by leveraging AI-driven research and tools to build stronger connections and achieve results faster.
                    </span>
                </Stack>
                <Stack direction={'column'} width={isDesktop ? '50%' : '100%'} sx={{ boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)', borderRadius: '8px', }}>
                    <CRMProduct />
                </Stack>
            </Stack>



        </Stack>
    )
}


const IntegrationsSection = () => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));


    return (
        <Stack id="integrations" className="section" direction={isDesktop ? 'row' : 'column'} paddingX={isDesktop ? '15%' : '5%'} pt={isDesktop ? '130px' : '10px'} pb={isDesktop ? '90px' : '10px'}  >

<Stack direction={'column'} width={isDesktop ? '55%' : '100%'} sx={{ paddingRight: isDesktop ? '50px' : 0, pb: isDesktop ? '50px' : '', pt: isDesktop ? '50px' : '50px', alignItems: 'flex-start', justifyContent: 'center' }}>

<Stack direction={'column'} gap={'10px'} width={'100%'}>
    <h1 style={{ textAlign: isDesktop ? 'left' : 'center', color: '#0C082D' }}>Integrations</h1>
    <span style={{ fontSize: isDesktop ? '22px' : '18px', color: '#6A687E', textAlign: isDesktop ? 'left' : 'center', width: '100%' }}>
        Discover seamless integrations with our platform, designed to enhance your workflow and productivity.
    </span>
</Stack>
</Stack>
            <Stack direction={'column'} width={isDesktop ? '45%' : '100%'} sx={{ alignItems: 'flex-start', justifyContent: 'center', position: 'relative' }}>

                <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: '100%', position: 'relative' }}>
                    <img src={bgellipse} alt="logo" style={{ width: 'auto', height: '400px', position: 'absolute', top: '50%', left: '50%', zIndex: 0, filter: 'blur(75px)', transform: 'translate(-50%, -50%)' }} />

                    <Box sx={{ mt: isDesktop? '20px':'80px', width: '250px', height: '250px', position: 'relative', backgroundImage: `url(${integrations})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} />
                </Stack>







            </Stack>
       


        </Stack>

    )
}