const pastelColors = [
    {"name": "pastel_lemon", "backgroundColor": "#f7f7d4", "textColor": "#666633"},
    {"name": "pastel_mint", "backgroundColor": "#d4f7d4", "textColor": "#336633"},
    {"name": "pastel_sky", "backgroundColor": "#d4e5f7", "textColor": "#334d66"},
    {"name": "pastel_plum", "backgroundColor": "#e5d4e5", "textColor": "#663366"},
    {"name": "pastel_rose", "backgroundColor": "#f7d4d4", "textColor": "#663333"},
    {"name": "pastel_peach", "backgroundColor": "#f7e5d4", "textColor": "#664233"},
    {"name": "pastel_lavender", "backgroundColor": "#e5d4f7", "textColor": "#663366"},
    {"name": "pastel_coral", "backgroundColor": "#f7d4e5", "textColor": "#66334d"},
    {"name": "pastel_aqua", "backgroundColor": "#d4f7e5", "textColor": "#33664d"},
    {"name": "pastel_beige", "backgroundColor": "#f7f7e5", "textColor": "#666633"},
    {"name": "pastel_lime", "backgroundColor": "#e5f7d4", "textColor": "#4d6633"},
    {"name": "pastel_pink", "backgroundColor": "#f7d4f7", "textColor": "#663366"},
    {"name": "pastel_gold", "backgroundColor": "#f7f2d4", "textColor": "#665c33"},
    {"name": "pastel_teal", "backgroundColor": "#d4f2f7", "textColor": "#336666"},
    {"name": "pastel_blue", "backgroundColor": "#d4dff7", "textColor": "#334d66"},
    {"name": "pastel_yellow", "backgroundColor": "#f7f7d4", "textColor": "#666633"},
    {"name": "pastel_green", "backgroundColor": "#d4f7e5", "textColor": "#33664d"},
    {"name": "pastel_cyan", "backgroundColor": "#d4f7f7", "textColor": "#336666"},
    {"name": "pastel_orange", "backgroundColor": "#f7e5d4", "textColor": "#664233"},
    {"name": "pastel_purple", "backgroundColor": "#e5d4f7", "textColor": "#663366"},
    {"name": "pastel_red", "backgroundColor": "#f7d4d4", "textColor": "#663333"},
    {"name": "pastel_silver", "backgroundColor": "#e5e5e5", "textColor": "#666666"},
    {"name": "pastel_magenta", "backgroundColor": "#f7d4e5", "textColor": "#66334d"},
    {"name": "pastel_lilac", "backgroundColor": "#f2d4f7", "textColor": "#664d66"},
    {"name": "pastel_burgundy", "backgroundColor": "#f7d4d4", "textColor": "#663333"},
    {"name": "pastel_olive", "backgroundColor": "#e5f7d4", "textColor": "#4d6633"},
    {"name": "pastel_charcoal", "backgroundColor": "#d4d4d4", "textColor": "#666666"},
    {"name": "pastel_indigo", "backgroundColor": "#d4d4f7", "textColor": "#333366"},
    {"name": "pastel_copper", "backgroundColor": "#f7d4c2", "textColor": "#664d33"},
    {"name": "pastel_turquoise", "backgroundColor": "#d4f7f2", "textColor": "#336659"},
    {"name": "pastel_crimson", "backgroundColor": "#f7c2c2", "textColor": "#663333"},
    {"name": "pastel_saffron", "backgroundColor": "#f7e5c2", "textColor": "#664233"},
    {"name": "pastel_cobalt", "backgroundColor": "#d4e5f7", "textColor": "#334d66"},
    {"name": "pastel_jade", "backgroundColor": "#d4f7e5", "textColor": "#33664d"},
    {"name": "pastel_mauve", "backgroundColor": "#e5d4f7", "textColor": "#663366"},
    {"name": "pastel_sage", "backgroundColor": "#d4f7d4", "textColor": "#336633"},
    {"name": "pastel_mocha", "backgroundColor": "#f7e5d4", "textColor": "#664233"},
    {"name": "pastel_slate", "backgroundColor": "#d4d4e5", "textColor": "#333366"},
    {"name": "pastel_ruby", "backgroundColor": "#f7d4e5", "textColor": "#66334d"},
    {"name": "pastel_amber", "backgroundColor": "#f7e5b2", "textColor": "#664d33"},
    {"name": "pastel_blush", "backgroundColor": "#f7d4d4", "textColor": "#663333"},
    {"name": "pastel_sand", "backgroundColor": "#f7f2d4", "textColor": "#665c33"},
    {"name": "pastel_moss", "backgroundColor": "#e5f7d4", "textColor": "#4d6633"},
    {"name": "pastel_ivory", "backgroundColor": "#f7f7e5", "textColor": "#666633"},
    {"name": "pastel_pearl", "backgroundColor": "#f7e5f7", "textColor": "#663366"},
    {"name": "pastel_honey", "backgroundColor": "#f7d4a2", "textColor": "#664d33"},
    {"name": "pastel_orchid", "backgroundColor": "#f7c2f7", "textColor": "#663366"},
    {"name": "pastel_pistachio", "backgroundColor": "#e5f7d4", "textColor": "#4d6633"},
    {"name": "pastel_periwinkle", "backgroundColor": "#d4d4f7", "textColor": "#333366"}
];

function getColorByName(name:string) {
    const color = pastelColors.find(color => color.name === name);
   
    if (color) {
        return { backgroundColor: color.backgroundColor, textColor: color.textColor };
    } else {
        return null; // or you can return a default color
    }
}

function getRandomPastelColorName() {
    const randomIndex = Math.floor(Math.random() * pastelColors.length);
    return pastelColors[randomIndex].name;
}

const nonPastelColors = [
    {"name": "vivid_red", "backgroundColor": "#ff0000", "textColor": "#ffffff"},
    {"name": "vivid_blue", "backgroundColor": "#0000ff", "textColor": "#ffffff"},
    {"name": "dark_green", "backgroundColor": "#006400", "textColor": "#ffffff"},
    {"name": "dark_yellow", "backgroundColor": "#9b870c", "textColor": "#ffffff"},
    {"name": "vivid_orange", "backgroundColor": "#ff7f00", "textColor": "#000000"},
    {"name": "vivid_purple", "backgroundColor": "#7f00ff", "textColor": "#ffffff"},
    {"name": "vivid_pink", "backgroundColor": "#ff007f", "textColor": "#ffffff"},
    {"name": "vivid_teal", "backgroundColor": "#00ff7f", "textColor": "#000000"},
    {"name": "vivid_cyan", "backgroundColor": "#00ffff", "textColor": "#000000"},
    {"name": "vivid_magenta", "backgroundColor": "#ff00ff", "textColor": "#ffffff"},
    {"name": "vivid_indigo", "backgroundColor": "#4b0082", "textColor": "#ffffff"},
    {"name": "vivid_amber", "backgroundColor": "#ffbf00", "textColor": "#000000"},
    {"name": "vivid_emerald", "backgroundColor": "#50c878", "textColor": "#000000"},
    {"name": "vivid_scarlet", "backgroundColor": "#ff2400", "textColor": "#ffffff"},
    {"name": "vivid_turquoise", "backgroundColor": "#40e0d0", "textColor": "#000000"},
    {"name": "vivid_gold", "backgroundColor": "#ffd700", "textColor": "#000000"},
    {"name": "vivid_silver", "backgroundColor": "#c0c0c0", "textColor": "#000000"},
    {"name": "vivid_bronze", "backgroundColor": "#cd7f32", "textColor": "#ffffff"},
    {"name": "vivid_coral", "backgroundColor": "#ff7f50", "textColor": "#000000"},
    {"name": "vivid_crimson", "backgroundColor": "#dc143c", "textColor": "#ffffff"},
    {"name": "vivid_olive", "backgroundColor": "#808000", "textColor": "#ffffff"},
    {"name": "vivid_maroon", "backgroundColor": "#800000", "textColor": "#ffffff"},
    {"name": "vivid_navy", "backgroundColor": "#000080", "textColor": "#ffffff"},
    {"name": "vivid_fuchsia", "backgroundColor": "#ff00ff", "textColor": "#ffffff"},
    {"name": "vivid_sapphire", "backgroundColor": "#0f52ba", "textColor": "#ffffff"},
    {"name": "vivid_ruby", "backgroundColor": "#e0115f", "textColor": "#ffffff"},
    {"name": "vivid_jade", "backgroundColor": "#00a86b", "textColor": "#000000"},
    {"name": "vivid_ochre", "backgroundColor": "#cc7722", "textColor": "#ffffff"},
    {"name": "vivid_umber", "backgroundColor": "#635147", "textColor": "#ffffff"},
    {"name": "vivid_denim", "backgroundColor": "#1560bd", "textColor": "#ffffff"},
    {"name": "vivid_ebony", "backgroundColor": "#555d50", "textColor": "#ffffff"},
    {"name": "vivid_forest", "backgroundColor": "#228b22", "textColor": "#ffffff"},
    {"name": "vivid_grape", "backgroundColor": "#6f2da8", "textColor": "#ffffff"},
    {"name": "vivid_hazel", "backgroundColor": "#8e7618", "textColor": "#ffffff"},
    {"name": "vivid_iris", "backgroundColor": "#5a4fcf", "textColor": "#ffffff"},
    {"name": "vivid_marine", "backgroundColor": "#013220", "textColor": "#ffffff"},
    {"name": "vivid_mocha", "backgroundColor": "#6f4e37", "textColor": "#ffffff"},
    {"name": "vivid_nectarine", "backgroundColor": "#ff6f61", "textColor": "#ffffff"},
    {"name": "vivid_ocean", "backgroundColor": "#0077be", "textColor": "#ffffff"},
    {"name": "vivid_peacock", "backgroundColor": "#33a1c9", "textColor": "#ffffff"},
    {"name": "vivid_pine", "backgroundColor": "#01796f", "textColor": "#ffffff"},
    {"name": "vivid_raspberry", "backgroundColor": "#e30b5d", "textColor": "#ffffff"},
    {"name": "vivid_sapphire", "backgroundColor": "#0f52ba", "textColor": "#ffffff"},
    {"name": "vivid_sienna", "backgroundColor": "#882d17", "textColor": "#ffffff"},
    {"name": "vivid_slate", "backgroundColor": "#708090", "textColor": "#ffffff"},
    {"name": "vivid_tomato", "backgroundColor": "#ff6347", "textColor": "#ffffff"},
    {"name": "vivid_ultramarine", "backgroundColor": "#3f00ff", "textColor": "#ffffff"},
    {"name": "vivid_vermilion", "backgroundColor": "#e34234", "textColor": "#ffffff"},
    {"name": "vivid_violet", "backgroundColor": "#8f00ff", "textColor": "#ffffff"},
    {"name": "vivid_wine", "backgroundColor": "#722f37", "textColor": "#ffffff"},
    {"name": "vivid_zinnia", "backgroundColor": "#ff7f50", "textColor": "#000000"}
];

export { pastelColors, getColorByName, getRandomPastelColorName, nonPastelColors };
