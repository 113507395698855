import React, { useEffect, useMemo, useRef, useState } from 'react';
import EmilySpeech from './EmilySpeech';
import { Icon } from '@iconify/react';
import { MessageItem } from './MockupSection';
import Chip from '@mui/material/Chip';
import { pastelColors, getColorByName } from './functions/PastelColors';
import moment from 'moment';
import momentTz from 'moment-timezone'; // Import moment-timezon
import { groupBy, size } from 'lodash'; // Make sure to import lodash
import pp from './pp.png';
import { Avatar, useMediaQuery, useTheme } from '@mui/material';
import axios from 'axios';
import timcook from './avatars/timcook.jpg';
import apple from './avatars/apple.png';
import applehq from './avatars/applehq.jpg';
interface MobileMockupProps {
    messages: MessageItem[];
    dateTime: Date;
}

const MobileMockup = ({ messages, dateTime }: MobileMockupProps) => {

    const messagesEndRef = useRef<HTMLDivElement>(null);

    const groupedMessages = useMemo(() => {

        let currentSpeaker = '';
        let currentGroup = 0;
        return groupBy(messages, (message: any, index: number) => {
            if (index === 0 || message.sender !== currentSpeaker) {
                currentSpeaker = message.sender;
                currentGroup++;
            }
            return `${currentGroup}-${message.from}`;
        });
    }, [messages]);



    return (
        <div style={{ position: 'relative', width: '391.53px', height: '807px', overflow: 'hidden', }}>
            <MobileFrame />
            <MobileStatusBar />
            <span style={{  zIndex: 1000, position: 'absolute', top: '29.5px', left: '57px', fontSize: '15px', fontWeight: 'bold' }}>
             {moment(dateTime).format('h:mm')} 
            </span>
            <MobileScreen groupedMessages={groupedMessages} />
        </div>
    )
}
export default MobileMockup

interface MobileScreenProps {
    groupedMessages: any;
}

const MobileScreen = ({ groupedMessages }: MobileScreenProps) => {
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const messageListRef = useRef<HTMLDivElement>(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    useEffect(() => {
        if (messageListRef.current) {
            messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }
    }, [groupedMessages]); // Ensure this effect only runs when groupedMessages changes

    return (
        <div style={{
            position: 'absolute', backgroundColor: '#F3EBFA', width: '369.22px', height: '782.15px', overflow: 'hidden',
            top: '50%', left: '50%', transform: 'translate(-50%, -50%)', borderRadius: '47.86px'
        }}>
            <div style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '370px',
                maxWidth: '370px',
                height: '90px',
                borderBottom: '1px solid #DFE1E6',
                backgroundColor: '#F3EBFA',
            }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', position: 'absolute', bottom: '8px', width: '100%', alignItems: 'center' }}>
                    <Icon icon="mdi:menu" color="#9457D2" width="24" height="24" style={{ marginLeft: '15px' }} />
                    <img src={pp} alt="profile" style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '15px' }} />
                </div>
            </div>
            <div ref={messageListRef}  className={isDesktop ? 'hide-scrollbar' : ''}  style={{ pointerEvents: isDesktop ?  'auto':'none', overflowY: 'auto', height: 'calc(100% - 220px)', padding: '10px 0', marginTop: '90px' }}>
                <MessageList groupedMessages={groupedMessages} />
                <div ref={messagesEndRef} />
            </div>
            <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                <EmilyBar />
            </div>
        </div>
    )
}

const EmilyBar = () => {
    return (
        <div style={{
            borderTop: '1px solid #DFE1E6',
            paddingTop: '20px',
            paddingBottom: '20px',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#F3EBFA',
        }}>
            <EmilySpeech startAnimation={true} />
        </div>
    )
}

const MobileStatusBar = () => {
    return (
        <div style={{
            position: 'absolute', overflow: 'hidden',
            top: '25px', left: '50%', transform: 'translateX(-50%)', borderRadius: '47.86px', zIndex: 1000
        }}>
      <svg width="293" height="31" viewBox="0 0 293 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="78" width="110" height="31" rx="15.5" fill="#030303"/>
<circle cx="171.62" cy="15.6202" r="9.62021" fill="#0E0B0F"/>
<circle cx="171.62" cy="15.6202" r="5.34456" fill="#161424"/>
<circle cx="171.621" cy="15.6202" r="3.20674" fill="#0F0F2A"/>
<circle cx="171.62" cy="14.5512" r="1.06891" fill="#393752"/>
<path opacity="0.35" d="M269 9.52754H286C287.918 9.52754 289.472 11.0822 289.472 13V18C289.472 19.9178 287.918 21.4725 286 21.4725H269C267.082 21.4725 265.528 19.9178 265.528 18V13C265.528 11.0822 267.082 9.52754 269 9.52754Z" stroke="#0D0D0E" stroke-width="1.05509"/>
<path opacity="0.4" d="M291 14V18.2203C291.849 17.8629 292.401 17.0314 292.401 16.1102C292.401 15.1889 291.849 14.3574 291 14" fill="#0D0D0E"/>
<path d="M267 13C267 11.8954 267.895 11 269 11H286C287.105 11 288 11.8954 288 13V18C288 19.1046 287.105 20 286 20H269C267.895 20 267 19.1046 267 18V13Z" fill="#0D0D0E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M250.5 11.5875C252.967 11.5876 255.339 12.5551 257.127 14.2898C257.261 14.4237 257.477 14.4221 257.609 14.286L258.896 12.9605C258.963 12.8915 259.001 12.798 259 12.7008C258.999 12.6035 258.961 12.5105 258.893 12.4423C254.201 7.85255 246.799 7.85255 242.107 12.4423C242.039 12.5105 242.001 12.6034 242 12.7007C241.999 12.7979 242.037 12.8914 242.104 12.9605L243.391 14.286C243.523 14.4223 243.739 14.424 243.873 14.2898C245.661 12.5549 248.034 11.5875 250.5 11.5875ZM250.536 15.6724C251.891 15.6723 253.198 16.1864 254.203 17.115C254.338 17.2467 254.552 17.2439 254.685 17.1085L255.97 15.7829C256.038 15.7134 256.075 15.6191 256.074 15.5211C256.073 15.4231 256.034 15.3295 255.965 15.2614C252.906 12.3568 248.169 12.3568 245.109 15.2614C245.04 15.3295 245.001 15.4231 245 15.5212C244.999 15.6192 245.037 15.7135 245.105 15.7829L246.39 17.1085C246.522 17.2439 246.736 17.2467 246.872 17.115C247.876 16.1871 249.182 15.6729 250.536 15.6724ZM253.15 18.3427C253.152 18.441 253.114 18.5357 253.045 18.6046L250.822 20.8948C250.756 20.9621 250.668 21 250.575 21C250.482 21 250.393 20.9621 250.328 20.8948L248.105 18.6046C248.036 18.5357 247.998 18.4409 248 18.3426C248.002 18.2443 248.044 18.1512 248.115 18.0853C249.535 16.8595 251.615 16.8595 253.035 18.0853C253.106 18.1513 253.148 18.2444 253.15 18.3427Z" fill="#0D0D0E"/>
<path d="M227 12C227 11.4477 227.448 11 228 11H229C229.552 11 230 11.4477 230 12V20C230 20.5523 229.552 21 229 21H228C227.448 21 227 20.5523 227 20V12Z" fill="#0D0D0E"/>
<path d="M232 10C232 9.44772 232.448 9 233 9H234C234.552 9 235 9.44772 235 10V20C235 20.5523 234.552 21 234 21H233C232.448 21 232 20.5523 232 20V10Z" fill="#0D0D0E"/>
<path d="M222 15.5C222 14.9477 222.448 14.5 223 14.5H224C224.552 14.5 225 14.9477 225 15.5V20C225 20.5523 224.552 21 224 21H223C222.448 21 222 20.5523 222 20V15.5Z" fill="#0D0D0E"/>
<path d="M217 18C217 17.4477 217.448 17 218 17H219C219.552 17 220 17.4477 220 18V20C220 20.5523 219.552 21 219 21H218C217.448 21 217 20.5523 217 20V18Z" fill="#0D0D0E"/>
</svg>


        </div>
    )
}


interface MessageListProps {
    groupedMessages: any;
}


interface MessageGroupProps {
    groupKey: string;
    messages: MessageItem[];
}

const MessageList: React.FC<MessageListProps> = ({ groupedMessages, }) => {
    return (
        <div style={{ padding: '0 15px' }}>
            {Object.entries(groupedMessages).map(([groupKey, messages], groupIndex) => (
                <MessageGroup key={groupIndex} groupKey={groupKey} messages={messages as MessageItem[]} />
            ))}
        </div>
    );
};






const MobileFrame = () => {
    return (
        <div style={{ position: 'absolute', }}>
            <svg width="392" height="807" viewBox="0 0 392 807" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_41_4814)">
                    <g clip-path="url(#clip1_41_4814)">
                        <path d="M394.405 328.352C394.405 329.41 393.548 330.267 392.491 330.267L389.619 330.267L389.619 235.495L392.491 235.495C393.548 235.495 394.405 236.352 394.405 237.409L394.405 328.352Z" fill="#F0ECE3" />
                        <path opacity="0.76" d="M394.405 328.352C394.405 329.41 393.548 330.267 392.491 330.267L389.619 330.267L389.619 235.495L392.491 235.495C393.548 235.495 394.405 236.352 394.405 237.409L394.405 328.352Z" fill="url(#paint0_linear_41_4814)" />
                        <path d="M394.405 328.352C394.405 329.41 393.548 330.267 392.491 330.267L389.619 330.267L389.619 235.495L392.491 235.495C393.548 235.495 394.405 236.352 394.405 237.409L394.405 328.352Z" fill="url(#paint1_linear_41_4814)" />
                        <path opacity="0.48" d="M394.405 328.352C394.405 329.41 393.548 330.267 392.491 330.267L389.619 330.267L389.619 235.495L392.491 235.495C393.548 235.495 394.405 236.352 394.405 237.409L394.405 328.352Z" fill="url(#paint2_linear_41_4814)" />
                        <g filter="url(#filter0_f_41_4814)">
                            <path d="M394.405 326.438L393.448 326.438L393.448 235.973C393.448 235.709 393.662 235.495 393.927 235.495V235.495C394.191 235.495 394.405 235.709 394.405 235.973L394.405 326.438Z" fill="#61605D" />
                        </g>
                    </g>
                    <g clip-path="url(#clip2_41_4814)">
                        <path d="M-2.87207 144.552C-2.87207 143.494 -2.01488 142.637 -0.957479 142.637H1.91441V172.313H-0.95748C-2.01488 172.313 -2.87207 171.456 -2.87207 170.399V144.552Z" fill="#F0ECE3" />
                        <path opacity="0.76" d="M-2.87207 144.552C-2.87207 143.494 -2.01488 142.637 -0.957479 142.637H1.91441V172.313H-0.95748C-2.01488 172.313 -2.87207 171.456 -2.87207 170.399V144.552Z" fill="url(#paint3_linear_41_4814)" />
                        <path d="M-2.87207 144.552C-2.87207 143.494 -2.01488 142.637 -0.957479 142.637H1.91441V172.313H-0.95748C-2.01488 172.313 -2.87207 171.456 -2.87207 170.399V144.552Z" fill="url(#paint4_linear_41_4814)" />
                        <path opacity="0.48" d="M-2.87207 144.552C-2.87207 143.494 -2.01488 142.637 -0.957479 142.637H1.91441V172.313H-0.95748C-2.01488 172.313 -2.87207 171.456 -2.87207 170.399V144.552Z" fill="url(#paint5_linear_41_4814)" />
                    </g>
                    <g clip-path="url(#clip3_41_4814)">
                        <path d="M-2.87207 201.989C-2.87207 200.932 -2.01488 200.075 -0.957479 200.075H1.91441V253.683H-0.95748C-2.01488 253.683 -2.87207 252.826 -2.87207 251.769V201.989Z" fill="#F0ECE3" />
                        <path opacity="0.76" d="M-2.87207 201.989C-2.87207 200.932 -2.01488 200.075 -0.957479 200.075H1.91441V253.683H-0.95748C-2.01488 253.683 -2.87207 252.826 -2.87207 251.769V201.989Z" fill="url(#paint6_linear_41_4814)" />
                        <path d="M-2.87207 201.989C-2.87207 200.932 -2.01488 200.075 -0.957479 200.075H1.91441V253.683H-0.95748C-2.01488 253.683 -2.87207 252.826 -2.87207 251.769V201.989Z" fill="url(#paint7_linear_41_4814)" />
                        <path opacity="0.48" d="M-2.87207 201.989C-2.87207 200.932 -2.01488 200.075 -0.957479 200.075H1.91441V253.683H-0.95748C-2.01488 253.683 -2.87207 252.826 -2.87207 251.769V201.989Z" fill="url(#paint8_linear_41_4814)" />
                    </g>
                    <g clip-path="url(#clip4_41_4814)">
                        <path d="M-2.87207 271.872C-2.87207 270.815 -2.01488 269.957 -0.957479 269.957H1.91441V323.566H-0.95748C-2.01488 323.566 -2.87207 322.709 -2.87207 321.651V271.872Z" fill="#F0ECE3" />
                        <path opacity="0.76" d="M-2.87207 271.872C-2.87207 270.815 -2.01488 269.957 -0.957479 269.957H1.91441V323.566H-0.95748C-2.01488 323.566 -2.87207 322.709 -2.87207 321.651V271.872Z" fill="url(#paint9_linear_41_4814)" />
                        <path d="M-2.87207 271.872C-2.87207 270.815 -2.01488 269.957 -0.957479 269.957H1.91441V323.566H-0.95748C-2.01488 323.566 -2.87207 322.709 -2.87207 321.651V271.872Z" fill="url(#paint10_linear_41_4814)" />
                        <path opacity="0.48" d="M-2.87207 271.872C-2.87207 270.815 -2.01488 269.957 -0.957479 269.957H1.91441V323.566H-0.95748C-2.01488 323.566 -2.87207 322.709 -2.87207 321.651V271.872Z" fill="url(#paint11_linear_41_4814)" />
                    </g>
                    <g clip-path="url(#clip5_41_4814)">
                        <rect width="391.534" height="807" rx="59.3523" fill="#E7E3BF" />
                        <g filter="url(#filter1_f_41_4814)">
                            <rect x="1.43594" y="1.43594" width="388.662" height="804.128" rx="57.9164" stroke="#C3C3C3" stroke-width="2.87189" />
                        </g>
                        <g filter="url(#filter2_f_41_4814)">
                            <rect x="0.478648" y="0.478648" width="390.576" height="806.043" rx="58.8737" stroke="#524B40" stroke-width="0.957295" />
                        </g>
                        <g filter="url(#filter3_f_41_4814)">
                            <rect x="5.26478" y="5.26515" width="381.004" height="795.512" rx="58.8737" stroke="white" stroke-width="0.957295" />
                        </g>
                        <rect x="5.55268" y="5.55232" width="380.429" height="794.938" rx="59.5438" stroke="white" stroke-width="0.382918" />
                        <rect x="304.42" width="5.74377" height="3.82918" fill="url(#paint12_linear_41_4814)" />
                        <rect y="83.2847" width="5.74377" height="3.82918" transform="rotate(-90 0 83.2847)" fill="url(#paint13_linear_41_4814)" />
                        <rect y="723.715" width="3.82918" height="5.74377" fill="url(#paint14_linear_41_4814)" />
                        <rect x="60.3096" y="803.171" width="4.78648" height="3.82918" fill="url(#paint15_linear_41_4814)" />
                        <rect x="387.704" y="723.715" width="3.82918" height="5.74377" fill="url(#paint16_linear_41_4814)" />
                    </g>
                    <g clip-path="url(#clip6_41_4814)">
                        <rect x="3.8291" y="3.82922" width="383.875" height="799.342" rx="55.5231" fill="#000100" />
                        <g filter="url(#filter4_f_41_4814)">
                            <rect x="5.74369" y="5.74381" width="380.046" height="795.512" rx="53.6085" stroke="url(#paint17_radial_41_4814)" stroke-width="3.82918" />
                        </g>
                    </g>
                </g>
                <defs>
                    <filter id="filter0_f_41_4814" x="391.534" y="233.58" width="4.78621" height="94.7722" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="0.957295" result="effect1_foregroundBlur_41_4814" />
                    </filter>
                    <filter id="filter1_f_41_4814" x="-3.82918" y="-3.82918" width="399.193" height="814.658" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="1.91459" result="effect1_foregroundBlur_41_4814" />
                    </filter>
                    <filter id="filter2_f_41_4814" x="-1.91459" y="-1.91459" width="395.363" height="810.829" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="0.957295" result="effect1_foregroundBlur_41_4814" />
                    </filter>
                    <filter id="filter3_f_41_4814" x="2.87154" y="2.87191" width="385.79" height="800.299" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="0.957295" result="effect1_foregroundBlur_41_4814" />
                    </filter>
                    <filter id="filter4_f_41_4814" x="-2.87197" y="-2.87184" width="397.277" height="812.744" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="3.35053" result="effect1_foregroundBlur_41_4814" />
                    </filter>
                    <linearGradient id="paint0_linear_41_4814" x1="392.012" y1="330.267" x2="392.012" y2="325.959" gradientUnits="userSpaceOnUse">
                        <stop />
                        <stop offset="1" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_41_4814" x1="389.619" y1="330.267" x2="392.969" y2="330.267" gradientUnits="userSpaceOnUse">
                        <stop />
                        <stop offset="1" stop-color="#AEAEAE" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_41_4814" x1="392.012" y1="239.803" x2="392.012" y2="235.495" gradientUnits="userSpaceOnUse">
                        <stop stop-opacity="0" />
                        <stop offset="1" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_41_4814" x1="-0.478832" y1="142.637" x2="-0.478832" y2="145.509" gradientUnits="userSpaceOnUse">
                        <stop />
                        <stop offset="1" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint4_linear_41_4814" x1="1.91441" y1="142.637" x2="-1.43613" y2="142.637" gradientUnits="userSpaceOnUse">
                        <stop />
                        <stop offset="1" stop-color="#AEAEAE" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint5_linear_41_4814" x1="-0.478832" y1="167.237" x2="-0.478832" y2="172.313" gradientUnits="userSpaceOnUse">
                        <stop stop-opacity="0" />
                        <stop offset="1" />
                    </linearGradient>
                    <linearGradient id="paint6_linear_41_4814" x1="-0.478832" y1="200.075" x2="-0.478832" y2="203.904" gradientUnits="userSpaceOnUse">
                        <stop />
                        <stop offset="1" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint7_linear_41_4814" x1="1.91441" y1="200.075" x2="-1.43613" y2="200.075" gradientUnits="userSpaceOnUse">
                        <stop />
                        <stop offset="1" stop-color="#AEAEAE" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint8_linear_41_4814" x1="-0.478832" y1="249.854" x2="-0.478832" y2="253.683" gradientUnits="userSpaceOnUse">
                        <stop stop-opacity="0" />
                        <stop offset="1" />
                    </linearGradient>
                    <linearGradient id="paint9_linear_41_4814" x1="-0.478832" y1="269.957" x2="-0.478832" y2="273.787" gradientUnits="userSpaceOnUse">
                        <stop />
                        <stop offset="1" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint10_linear_41_4814" x1="1.91441" y1="269.957" x2="-1.43613" y2="269.957" gradientUnits="userSpaceOnUse">
                        <stop />
                        <stop offset="1" stop-color="#AEAEAE" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint11_linear_41_4814" x1="-0.478832" y1="319.737" x2="-0.478832" y2="323.566" gradientUnits="userSpaceOnUse">
                        <stop stop-opacity="0" />
                        <stop offset="1" />
                    </linearGradient>
                    <linearGradient id="paint12_linear_41_4814" x1="307.292" y1="0" x2="307.292" y2="3.82918" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#817E73" />
                        <stop offset="1" stop-color="#C3BEAC" />
                    </linearGradient>
                    <linearGradient id="paint13_linear_41_4814" x1="2.87189" y1="83.2847" x2="2.87189" y2="87.1139" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#817E73" />
                        <stop offset="1" stop-color="#C3BEAC" />
                    </linearGradient>
                    <linearGradient id="paint14_linear_41_4814" x1="0" y1="726.587" x2="3.82918" y2="726.587" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#817E73" />
                        <stop offset="1" stop-color="#C3BEAC" />
                    </linearGradient>
                    <linearGradient id="paint15_linear_41_4814" x1="62.7028" y1="807" x2="62.7028" y2="803.171" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#817E73" />
                        <stop offset="1" stop-color="#C3BEAC" />
                    </linearGradient>
                    <linearGradient id="paint16_linear_41_4814" x1="391.533" y1="726.587" x2="387.704" y2="726.587" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#817E73" />
                        <stop offset="1" stop-color="#C3BEAC" />
                    </linearGradient>
                    <radialGradient id="paint17_radial_41_4814" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(196.25 188.256) rotate(90) scale(327.441 366.781)">
                        <stop stop-color="#879194" />
                        <stop offset="1" />
                    </radialGradient>
                    <clipPath id="clip0_41_4814">
                        <rect width="391.534" height="807" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_41_4814">
                        <path d="M394.405 329.31C394.405 330.367 393.548 331.224 392.491 331.224L389.619 331.224L389.619 235.495L392.491 235.495C393.548 235.495 394.405 236.352 394.405 237.409L394.405 329.31Z" fill="white" />
                    </clipPath>
                    <clipPath id="clip2_41_4814">
                        <path d="M-2.87207 144.552C-2.87207 143.494 -2.01488 142.637 -0.957479 142.637H1.91441V172.313H-0.95748C-2.01488 172.313 -2.87207 171.456 -2.87207 170.399V144.552Z" fill="white" />
                    </clipPath>
                    <clipPath id="clip3_41_4814">
                        <path d="M-2.87207 201.989C-2.87207 200.932 -2.01488 200.075 -0.957479 200.075H1.91441V253.683H-0.95748C-2.01488 253.683 -2.87207 252.826 -2.87207 251.769V201.989Z" fill="white" />
                    </clipPath>
                    <clipPath id="clip4_41_4814">
                        <path d="M-2.87207 271.872C-2.87207 270.815 -2.01488 269.957 -0.957479 269.957H1.91441V323.566H-0.95748C-2.01488 323.566 -2.87207 322.709 -2.87207 321.651V271.872Z" fill="white" />
                    </clipPath>
                    <clipPath id="clip5_41_4814">
                        <rect width="391.534" height="807" rx="59.3523" fill="white" />
                    </clipPath>
                    <clipPath id="clip6_41_4814">
                        <rect x="3.8291" y="3.82922" width="383.875" height="799.342" rx="55.5231" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    )
}



interface MessageGroupProps {
    groupKey: string;
    messages: MessageItem[];
}

const LinkMessage: React.FC<{ content: string }> = ({ content }) => (
    <a href={content} target="_blank" rel="noopener noreferrer" style={{ color: 'rgb(159, 97, 220)' }}>{content}</a>
);

const ImageMessage: React.FC<{ content: string }> = ({ content }) => (
    <img src={content} alt="content" style={{ maxWidth: '100%' }} />
);

const MapMessage: React.FC<{ content: string }> = ({ content }) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const mapUrl = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${encodeURIComponent(content)}&maptype=satellite`;
    return (
        <iframe
            width="100%"
            height="300px"
            style={{ border: 0, pointerEvents: isDesktop ?  'auto':'none' }}
            loading="lazy"
            allowFullScreen
            src={mapUrl}
        ></iframe>
    );
};

const PhoneNumberMessage: React.FC<{ content: string }> = ({ content }) => (
    <a href={`tel:${content}`} style={{ color: 'rgb(159, 97, 220)' }}>{content}</a>
);

const TextMessage: React.FC<{ content: string }> = ({ content }) => (
    <span style={{ lineHeight: '1.4' }}>{content}</span>
);

interface TaskPriorityOption {
    label: string;
    value: string;
    color: string;
}
const taskPriorityOptions: TaskPriorityOption[] = [
    { label: 'None', value: '', color: '' },
    { label: 'Low', value: 'low', color: 'pastel_green' },
    { label: 'Medium', value: 'medium', color: 'pastel_orange' },
    { label: 'High', value: 'high', color: 'pastel_red' },
];


const TaskMessage: React.FC<{ content: TaskMessageItem }> = ({ content }) => {

    const findTaskPriorityOption = taskPriorityOptions.find((option) => option.value === content.priority);

    return (
        <>
            <h5 style={{ wordBreak: 'break-word', margin: 0, padding: 0 }}>{content.name}</h5>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '10px', flexWrap: 'wrap', gap: '5px' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                    <Icon icon="fluent:people-team-16-regular" style={{ marginRight: '8px', color: '#637381', width: '16px', height: '16px' }} />
                    <Chip sx={{ pointerEvents: 'none', backgroundColor: getColorByName(content.team_color)?.backgroundColor, color: getColorByName(content.team_color)?.textColor, fontSize: 'small', height: '32px', width: 'auto' }} label={content.team_name} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                    <Icon icon="mdi:format-list-bulleted" style={{ marginRight: '8px', color: '#637381', width: '16px', height: '16px' }} />
                    <Chip sx={{ pointerEvents: 'none', backgroundColor: getColorByName(content.list_color)?.backgroundColor, color: getColorByName(content.list_color)?.textColor, fontSize: 'small', height: '32px', width: 'auto' }} label={content.list_name} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                    <Icon icon="mdi:circle-outline" style={{ marginRight: '8px', color: '#637381', width: '16px', height: '16px' }} />
                    <Chip sx={{ pointerEvents: 'none', backgroundColor: getColorByName(content.status_color)?.backgroundColor, color: getColorByName(content.status_color)?.textColor, fontSize: 'small',height: '32px', width: 'auto' }} label={content.status_name} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                    <Icon icon="material-symbols:flag-2-outline-rounded" style={{ marginRight: '8px', color: '#637381', width: '16px', height: '16px' }} />
                    <Chip sx={{ pointerEvents: 'none', backgroundColor: getColorByName(findTaskPriorityOption?.color ?? '')?.backgroundColor, color: getColorByName(findTaskPriorityOption?.color ?? '')?.textColor, height: '32px', width: 'auto' }} label={findTaskPriorityOption?.label} />
                </div>
            </div>
            {content.due_date && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '10px' }}>
                <Icon icon="mdi:event-outline" style={{ marginRight: '8px', color: '#637381', width: '16px', height: '16px' }} />
                <span style={{ fontSize: 'small', color: '#637381' }}>{moment(content.due_date).format('dddd, MMMM D, YYYY [at] h:mm A')}</span>
            </div>}
            {content.description && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '10px' }}>
                <Icon icon="mdi:text" style={{ marginRight: '8px', color: '#637381', width: '16px', height: '16px', flexShrink: 0 }} />
                <span style={{ fontSize: 'small', color: '#637381' }}>{content.description}</span>
            </div>}
        </>
    );
};


const EventMessage: React.FC<{ content: EventMessageItem }> = ({ content }) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const providerLogo = {
        google_calendar: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.5em"
                height="1.5em"
                viewBox="0 0 256 256"
            >
                <path fill="#fff" d="M195.368 60.632H60.632v134.736h134.736z" />
                <path
                    fill="#ea4335"
                    d="M195.368 256L256 195.368l-30.316-5.172l-30.316 5.172l-5.533 27.73z"
                />
                <path
                    fill="#188038"
                    d="M0 195.368v40.421C0 246.956 9.044 256 20.21 256h40.422l6.225-30.316l-6.225-30.316l-33.033-5.172z"
                />
                <path
                    fill="#1967d2"
                    d="M256 60.632V20.21C256 9.044 246.956 0 235.79 0h-40.422q-5.532 22.554-5.533 33.196q0 10.641 5.533 27.436q20.115 5.76 30.316 5.76T256 60.631"
                />
                <path fill="#fbbc04" d="M256 60.632h-60.632v134.736H256z" />
                <path fill="#34a853" d="M195.368 195.368H60.632V256h134.736z" />
                <path
                    fill="#4285f4"
                    d="M195.368 0H20.211C9.044 0 0 9.044 0 20.21v175.158h60.632V60.632h134.736z"
                />
                <path
                    fill="#4285f4"
                    d="M88.27 165.154c-5.036-3.402-8.523-8.37-10.426-14.94l11.689-4.816q1.59 6.063 5.558 9.398c2.627 2.223 5.827 3.318 9.566 3.318q5.734 0 9.852-3.487c2.746-2.324 4.127-5.288 4.127-8.875q0-5.508-4.345-8.994c-2.897-2.324-6.535-3.486-10.88-3.486h-6.754v-11.57h6.063q5.608 0 9.448-3.033c2.56-2.02 3.84-4.783 3.84-8.303c0-3.132-1.145-5.625-3.435-7.494c-2.29-1.87-5.188-2.813-8.708-2.813c-3.436 0-6.164.91-8.185 2.745a16.1 16.1 0 0 0-4.413 6.754l-11.57-4.817c1.532-4.345 4.345-8.185 8.471-11.503s9.398-4.985 15.798-4.985c4.733 0 8.994.91 12.767 2.745c3.772 1.836 6.736 4.379 8.875 7.613c2.14 3.25 3.2 6.888 3.2 10.93c0 4.126-.993 7.613-2.98 10.476s-4.43 5.052-7.327 6.585v.69a22.25 22.25 0 0 1 9.398 7.327c2.442 3.284 3.672 7.208 3.672 11.79c0 4.58-1.163 8.673-3.487 12.26c-2.324 3.588-5.54 6.417-9.617 8.472c-4.092 2.055-8.69 3.1-13.793 3.1c-5.912.016-11.369-1.685-16.405-5.087m71.797-58.005l-12.833 9.28l-6.417-9.734l23.023-16.607h8.825v78.333h-12.598z"
                />
            </svg>
        ),
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                }}
            >
                <div
                    style={{
                        width: '15px',
                        height: '15px',
                        borderRadius: '4px',
                        backgroundColor: content.status === 'confirmed' ?'#35B37E' : '#c5000f',
                        marginRight: '8px',
                    }}
                ></div>
                <h5 style={{ margin: 0, wordBreak: 'break-word' }}>{content.title}</h5>
            </div>
            <div
                style={{
                    textAlign: 'left',
                    color: '#637381',
                    fontSize: '0.8em',
                    marginTop: '10px',
                }}
            >
                {content.all_day
                    ? 'All Day Event'
                    : `${moment(content.start_time).format('dddd, MMMM D, YYYY ⋅ h:mm A')}${moment(content.start_time).isSame(content.end_time, 'day')
                        ? ` - ${moment(content.end_time).format('h:mm A')}`
                        : ''
                    }`}
            </div>
            <div style={{ marginTop: '5px' }}>
                {content.actions.length > 0 && content.actions
                    .sort((a, b) => (a.entry_point === 'map' ? 1 : b.entry_point === 'map' ? -1 : 0))
                    .map((action: EventAction) => {
                        if (action.type === 'join' && action.entry_point === 'video') {
                            return (
                                <div key={action.location} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Icon icon="logos:google-meet" style={{ marginRight: '8px', width: '16px', height: '16px' }} />
                                        <button
                                            style={{ paddingLeft: '10px', paddingRight: '10px', borderRadius: '4px', height: '27px', border: 'none', background: '#9F61DC', color: 'white', fontSize: '14px', fontWeight: 'normal', textTransform: 'capitalize', fontFamily: 'Public Sans' }}
                                        >
                                            Join with Google Meet
                                        </button>
                                    </div>
                                    <button style={{ border: 'none', background: 'none', fontSize: '14px', fontWeight: 'normal', paddingLeft: '10px', paddingRight: '10px', borderRadius: '4px', height: '27px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                        <Icon icon={'mdi:content-copy'} color='#9F61DC' />
                                    </button>
                                </div>
                            );
                        } else if (action.entry_point === 'phone') {
                            return <div key={action.location} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '10px' }}>
                                <Icon icon="mdi:phone-outline" style={{ marginRight: '8px', color: '#637381' }} />
                                <button style={{ paddingLeft:'10px', paddingRight:'10px', borderRadius: '4px', height: '27px', border: 'none', background: '#9F61DC', color: 'white', fontSize: '14px', fontWeight: 'normal', textTransform: 'capitalize', fontFamily: 'Public Sans'  }} >Join By Phone</button>
                            </div>
                        } else if (action.entry_point === 'map' && !action.location.includes('Google Meet (instructions in description)')) {
                            const mapUrl = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${encodeURIComponent(action.location)}&maptype=satellite`;
                            return (
                                <div key={action.location} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '5px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                        <Icon icon="logos:google-maps" style={{ marginRight: '8px', color: '#637381', width: '16px', height: '16px' }} />
                                        <button style={{paddingLeft:'10px', paddingRight:'10px', borderRadius: '4px', height: '27px', border: 'none', background: '#9F61DC', color: 'white', fontSize: '14px', fontWeight: 'normal', textTransform: 'capitalize', fontFamily: 'Public Sans' }} >{action.location}</button>
                                    </div>
                                    <iframe
                                        width="100%"
                                        height="180px"
                                        style={{ border: 0, pointerEvents: isDesktop ?  'auto':'none' }}
                                        loading="lazy"
                                        allowFullScreen
                                        src={mapUrl}
                                    ></iframe>
                                </div>
                            );
                        }
                    })}
            </div>


            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '10px' }}>
                <Icon icon="mdi:people-outline" style={{ marginRight: '8px', color: '#637381' }} />
                <span style={{ fontSize: 'small', color: '#637381', fontWeight: 'bold', marginRight: '4px' }}>
                    {content.attendees.length} attendee{content.attendees.length !== 1 ? 's' : ''}
                </span>
                {content.attendees.filter((attendee: EventAttendee) => attendee.response_status === 'needs_action').length > 0 &&
                    <span style={{ fontSize: 'small', color: '#637381', marginLeft: '4px' }}>
                        {`(${content.attendees.filter((attendee: EventAttendee) => attendee.response_status === 'needs_action').length} awaiting response)`}
                    </span>
                }
            </div>



            {content.attendees.length > 0 && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '10px', marginLeft: '20px' }}>
                {content.attendees.map((attendee: EventAttendee, index: number) => {
                    return <div key={attendee.email} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: index === 0 ? '0px' : '10px' }}>
                        <Avatar style={{ marginRight: '8px', height: '20px', width: '20px' }}>
                <span style={{
                  backgroundColor: attendee.response_status === 'accepted' ? '#35B37E' : attendee.response_status === 'declined' ? '#c5000f' : 'gray',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  width: '100%',
                  borderRadius: '50%',
                  fontSize: 'small',
                  fontWeight: 'normal'
                }}>
                  {attendee.name ? attendee.name.charAt(0).toUpperCase() : attendee.email.charAt(0).toUpperCase()}
                </span>
              </Avatar>
                        <span style={{ fontSize: 'small', color: '#637381', fontWeight: '' }}>
                            {attendee.name ? attendee.name : attendee.email}
                            {attendee.is_me && ' (You)'}
                        </span>
                    </div>
                })}
            </div>}

            {content.description && (
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '10px' }}>
                    <Icon icon="mdi:text" style={{ marginRight: '8px', color: '#637381', flexShrink: 0 }} />
                    <span style={{ fontSize: 'small', color: '#637381' }}>
                        {content.description.split(' ').map((word, index, words) => {
                            const urlPattern = /^(https?:\/\/[^\s]+)/;
                            const rescheduleCancelPattern = /(Reschedule:|Cancel:)$/;
                            const reschedulingPattern = /rescheduling/;

                            if (urlPattern.test(word)) {
                                const nextWord = words[index + 1];
                                const isRescheduling = nextWord && reschedulingPattern.test(nextWord.toLowerCase());

                                return (
                                    <div key={index} style={{ marginBottom: '5px' }}>
                                        <a href={word.replace(rescheduleCancelPattern, '')} target="_blank" rel="noopener noreferrer" style={{ color: 'rgb(159, 97, 220)' }}>
                                            {word.replace(rescheduleCancelPattern, '')}
                                        </a>
                                        {isRescheduling && <div style={{ fontSize: 'small', color: '#637381', marginTop: '5px' }}>Reschedule:</div>}
                                    </div>
                                );
                            } else {
                                return <span key={index}>{word} </span>;
                            }
                        })}
                    </span>
                </div>
            )}

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon icon="logos:google-calendar" style={{ marginRight: '8px', color: '#637381', width: '16px', height: '16px' }} />
                    <span style={{ fontSize: 'small', color: '#637381' }}>{content.calendar_email}</span>
                </div>

            </div>
        </>
    );
};


interface TaskMessageItem {
    is_new: boolean;
    task_id: string;
    name: string;
    description: string;
    status_name: string;
    team_name: string;
    list_name: string;
    team_color: string;
    list_color: string;
    status_color: string;
    priority: "low" | "medium" | "high" | "";
    due_date: string;
}

interface EventAttendee {
    name: string;
    email: string;
    is_me: boolean;
    response_status: 'accepted' | 'declined' | 'needs_action';
}

interface EventAction {
    type: 'location' | 'join';
    entry_point: 'video' | 'map' | 'phone';
    location: string;
    pin?: string;
    provider: 'Google Meet' | 'phone' | 'map';

}

interface EventCreator {
    email: string;
}

interface EventMessageItem {
    event_id: string;
    is_new: boolean;
    status: 'confirmed' | 'cancelled';
    provider_event_id: string;
    provider: 'google_calendar';
    calendar_email: string;
    title: string;
    start_time: string;
    end_time: string;
    description: string;
    all_day: boolean;
    event_link: string;
    attendees: EventAttendee[];
    actions: EventAction[];
    creator: EventCreator;
}

export type { EventMessageItem,EventAction,TaskMessageItem, EmailMessageItem,WebsiteMessageItem };



interface EmailAddress {
    address: string;
    name: string;
}

interface EmailAttachment {
    content: {
        type: string;
        data: number[];
    },
    content_type: string;
    file_name: string;
    part_id: string;
    content_disposition: string;
    checksum: string;
    size: number;
    // Add any other fields you need from the attachment object
}

interface EmailMessageItem {
    type: 'new' | 'forward' | 'reply' | 'reply_all' | 'search';
    provider: 'gmail';
    provider_message_id: string;
    provider_thread_id: string;
    sender: string;
    date: string;
    from: EmailAddress[];
    to: EmailAddress[];
    cc: EmailAddress[];
    bcc: EmailAddress[];
    subject: string;
    reply_to: EmailAddress[];
    text: string;
    html: string;
    attachments: EmailAttachment[];
}


interface EmailMessageProps {
    content: EmailMessageItem;
}

const EmailMessage: React.FC<EmailMessageProps> = ({ content }: EmailMessageProps) => {
    function preprocessHTML(html: any) {
        // Create a new DOM parser
        const parser = new DOMParser();
        // Parse the HTML into a document
        const doc = parser.parseFromString(html, 'text/html');

        // Get all anchor tags in the document
        const links = doc.querySelectorAll('a');

        // Set target="_blank" on each link
        links.forEach(link => {
            link.target = '_blank';
            link.rel = 'noopener noreferrer'; // Recommended for security reasons
        });

        // Serialize the document back to HTML string
        return doc.documentElement.innerHTML;
    }

    const [processedHTML, setProcessedHTML] = useState<string>('');
    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        setProcessedHTML(preprocessHTML(content.html));
    }, [content.html]);

    useEffect(() => {
        const iframe = iframeRef.current;
        if (iframe) {
            const onLoad = () => {
                const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;
                if (iframeDocument) {
                    const height = iframeDocument.body.scrollHeight;
                    iframe.style.height = `${height + 30}px`;
                }
            };

            iframe.addEventListener('load', onLoad);
            return () => {
                iframe.removeEventListener('load', onLoad);
            };
        }
    }, [processedHTML]);
    return (
        <div >


            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon icon="logos:google-gmail" style={{ marginRight: '8px', color: '#637381', width: '16px', height: '16px' }} />
                    <span style={{ fontSize: 'small', color: '#637381' }}>{content.sender}</span>
                </div>
                {content.type && <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                    <Icon icon={content.type === 'reply_all' ? 'mdi:reply-all-outline' : content.type === 'forward' ? 'mdi:forward-outline' : content.type === 'new' ? 'mdi:email-sent-outline' : content.type === 'search' ? 'mdi:magnify-outline' : 'mdi:reply-outline'} style={{ marginRight: '4px', color: '#637381', width: '16px', height: '16px' }} />
                    <span style={{ fontSize: 'small', color: '#637381' }}>
                        {content.type === 'new' ? 'Sent email' : content.type === 'forward' ? 'Forwarded email' : content.type === 'reply' ? 'Email reply' : content.type === 'reply_all' ? 'Email reply to all' : content.type === 'search' ? 'Searched email' : content.type}
                    </span>
                </div>}
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                <Icon icon="mdi:calendar-outline" style={{ marginRight: '4px', color: '#637381', width: '16px', height: '16px' }} />
                <span style={{ fontSize: 'small', color: '#637381' }}>
                    {moment(content.date).format('dddd, MMMM D, YYYY [at] h:mm A')}
                </span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '10px' }}>
                <Icon icon="mdi:user-outline" style={{ marginRight: '4px', color: '#637381', width: '16px', height: '16px' }} />
                <span style={{ fontSize: 'small', color: '#637381', fontWeight: 'bold', marginRight: '4px' }}>
                    From:
                </span>
                {content.from.length > 0 &&
                    <span style={{ fontSize: 'small', color: '#637381' }}>
                        {content.from.map((recipient: EmailAddress) => recipient.name || recipient.address).join(', ')}
                    </span>
                }
            </div>
            {content.to.length > 0 && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '10px' }}>
                <Icon icon="mdi:account-arrow-right-outline" style={{ marginRight: '4px', color: '#637381', width: '16px', height: '16px' }} />
                <span style={{ fontSize: 'small', color: '#637381', fontWeight: 'bold', marginRight: '4px' }}>
                    To:
                </span>
                {content.to.length > 0 &&
                    <span style={{ fontSize: 'small', color: '#637381' }}>
                        {content.to.map((recipient: EmailAddress) => recipient.name || recipient.address).join(', ')}
                    </span>
                }
            </div>}

            {content.cc.length > 0 && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '10px' }}>
                <Icon icon="material-symbols:group-outline" style={{ marginRight: '4px', color: '#637381', width: '16px', height: '16px' }} />
                <span style={{ fontSize: 'small', color: '#637381', fontWeight: 'bold', marginRight: '4px' }}>
                    Cc:
                </span>
                {content.cc.length > 0 &&
                    <span style={{ fontSize: 'small', color: '#637381', marginLeft: '4px' }}>
                        {content.cc.map((recipient: EmailAddress) => recipient.name || recipient.address).join(', ')}
                    </span>
                }
            </div>}

            {content.bcc.length > 0 && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '10px' }}>
                <Icon icon="mdi:eye-off-outline" style={{ marginRight: '4px', color: '#637381', width: '16px', height: '16px' }} />
                <span style={{ fontSize: 'small', color: '#637381', fontWeight: 'bold', marginRight: '4px' }}>
                    Bcc:
                </span>
                {content.bcc.length > 0 &&
                    <span style={{ fontSize: 'small', color: '#637381' }}>
                        {content.bcc.map((recipient: EmailAddress) => recipient.name || recipient.address).join(', ')}
                    </span>
                }
            </div>
            }

            <div style={{ borderBottom: '1px solid #e0e0e0', margin: '10px 0' }}></div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '10px' }}>
                <Icon icon="mdi:information-box-outline" style={{ marginRight: '4px', color: '#637381', width: '16px', height: '16px', flexShrink: 0 }} />
                <h6 style={{ margin: 0 }}>
                    {content.subject}
                </h6>
            </div>
            {content.html && (
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '10px' }}>
                    <Icon icon="mdi:text" style={{ marginRight: '4px', color: '#637381', width: '16px', height: '16px', flexShrink: 0 }} />
                    <iframe
                        ref={iframeRef}
                        style={{ width: '100%', border: 'none', overflow: 'auto' }}
                        srcDoc={processedHTML}
                        title="Email Content"
                        scrolling='no'
                    />
                </div>
            )}
            {content.attachments && content.attachments.length > 0 && (
                <div style={{ borderBottom: '1px solid #e0e0e0', margin: '10px 0' }}></div>
            )}
            {content.attachments && content.attachments.length > 0 && (
                <>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '10px' }}>
                        <Icon icon="mdi:attachment" style={{ marginRight: '4px', color: '#637381', width: '16px', height: '16px' }} />
                        <span style={{ fontSize: 'small', color: '#637381' }}>
                            {content.attachments.length} {content.attachments.length > 1 ? 'attachments' : 'attachment'}
                        </span>

                    </div>
                    {/* {content.attachments.map((attachment: EmailAttachment, attachmentIndex: number) => (
              <IonButton key={attachmentIndex} size="small" style={{ marginRight: '4px' }}
              onClick={() => {
                // Convert the data array to Uint8Array or ArrayBuffer
                const byteArray = new Uint8Array(attachment.content.data);
  
                // Create a Blob from the byte array
                const blob = new Blob([byteArray], { type: attachment.content_type });
                const url = URL.createObjectURL(blob);
  
                window.open(url, '_blank', 'noopener,noreferrer');
  
              }}
              >
                <Icon icon="mdi:download" style={{ marginRight: '4px', width: '16px', height: '16px' }} />
                {attachment.file_name}
              </IonButton>
            ))} */}
                </>
            )}

        </div>
    );
};

interface WebsiteMessageItem {
    title: string;
    url: string;
    summary: string;
    author: string;
    image: string;
}

interface WebsiteMessageProps {
    content: WebsiteMessageItem;
}

const WebsiteMessage: React.FC<WebsiteMessageProps> = ({ content }: WebsiteMessageProps) => {
    const [isImageAccessible, setIsImageAccessible] = useState<boolean>(false);

    useEffect(() => {
        if (content.image) {
            const img = new Image();
            img.src = content.image;
            img.onload = () => setIsImageAccessible(true);
            img.onerror = () => setIsImageAccessible(false);
        }
    }, [content.image]);

    return (
        <div
            style={{ cursor: content.url ? 'pointer' : 'default' }}
            onClick={() => {
                if (content.url) {
                    window.open(content.url, '_blank', 'noopener,noreferrer');
                }
            }}
        >
            {content.title && (
                <h6 style={{ margin: '0' }}>
                    {content.title}
                </h6>
            )}

            {isImageAccessible && (
                <div style={{ width: '100%', maxHeight: '200px', overflow: 'hidden', marginTop: '10px' }}>
                    <img src={content.image} alt={content.title} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </div>
            )}

            {content.summary && (
                <div style={{ marginTop: '10px', fontSize: 'small', color: '#637381' }}>
                    {content.summary}
                </div>
            )}

            {content.url && (
                <div style={{ marginTop: '10px', maxWidth: '100%', wordBreak: 'break-word' }}>
                    <a href={content.url} target="_blank" rel="noopener noreferrer" style={{ color: 'rgb(159, 97, 220)', textDecoration: 'none' }}>
                        {content.url}
                    </a>
                </div>
            )}
        </div>
    );
};

interface ContactItem {
    display_name: string;
    email: string;
}

interface ContactMessageProps {
    content: ContactItem;
}

const ContactMessage: React.FC<ContactMessageProps> = ({ content }: ContactMessageProps) => {
    return <div>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Icon icon="mdi:account-outline" style={{ marginRight: '4px', color: '#637381', width: '16px', height: '16px' }} />
            {content.display_name ? (
                <h6 style={{ fontSize: 'small', color: '#637381', margin: 0 }}>
                    {content.display_name}
                </h6>
            ) : (
                <a href={`mailto:${content.email}`} style={{ fontSize: 'small', color: 'rgb(159, 97, 220)', textDecoration: 'none' }}>
                    {content.email}
                </a>
            )}
        </div>

        {content.email && content.display_name && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '10px' }}>
            <Icon icon="mdi:email-outline" style={{ marginRight: '4px', color: '#637381', width: '16px', height: '16px' }} />
            <span style={{ fontSize: 'small', color: '#637381' }}>
                <a href={`mailto:${content.email}`} style={{ color: 'rgb(159, 97, 220)', textDecoration: 'none' }}>{content.email}</a>
            </span>
        </div>}
    </div>;
};


interface FlightItem {
    flight_date: string;
    flight_status: string;
    departure: {
      airport: string;
      timezone: string;
      iata: string;
      icao: string;
      terminal: string;
      gate: string;
      delay: number | null;
      scheduled: string;
      estimated: string;
      actual: string;
      estimated_runway: string;
      actual_runway: string;
    };
    arrival: {
      airport: string;
      timezone: string;
      iata: string;
      icao: string;
      terminal: string;
      gate: string;
      baggage: string;
      delay: number | null;
      scheduled: string;
      estimated: string;
      actual: string;
      estimated_runway: string;
      actual_runway: string;
    };
    airline: {
      name: string;
      iata: string;
      icao: string;
    };
    flight: {
      number: string;
      iata: string;
      icao: string;
      codeshared: string | null;
    };
    aircraft: {
      registration: string;
      iata: string;
      icao: string;
      icao24: string;
    };
    live: any | null;
  }
  
  
  
  interface FlightMessageProps {
    content: FlightItem;
  }
  
  const FlightMessage: React.FC<FlightMessageProps> = ({ content }: FlightMessageProps) => {
  
    const [airlineLogo, setAirlineLogo] = useState<string | null>(null);
    const getAirlineLogo = async (airlineName: string) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_ZWEELIE_API_URL}enrichment/brand?name=${airlineName}`);
  
        const brandDomain = response.data.brand.domain;
  
        setAirlineLogo(`${process.env.REACT_APP_ZWEELIE_API_URL}enrichment/logo/${brandDomain}?size=100&format=png&greyscale=false`);
      } catch (error) {
        setAirlineLogo(null);
  
      }
    };
  
    useEffect(() => {
      if (content.airline.name) {
        getAirlineLogo(content.airline.name);      }
    }, [content.airline.name]);
  
  
    return <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%', textAlign: 'center' }}>
        {airlineLogo && <img src={airlineLogo} alt="Flight" style={{ width: '32px', height: '32px', borderRadius: '50%' }} />}
  
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <span style={{ color: '#637381', fontWeight: 'bold', fontSize: '14px' }}>
              {content.flight.iata} • {content.airline.name}
            </span>
  
          </div>
  
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', gap: '10px' }}>
            <span style={{ fontWeight: 'normal', fontSize: '13px', color: '#637381' }}>
              {content.departure.delay ? (content.departure.delay >= 60 ? `${Math.floor(content.departure.delay / 60)}h ${content.departure.delay % 60}m` : `${content.departure.delay}m`) : ''}
            </span>
            <Chip style={{
              pointerEvents: 'none',
              margin: '0',
              backgroundColor:
                content.flight_status === 'cancelled' ? getColorByName('pastel_red')?.backgroundColor :
                  content.flight_status === 'delayed' ? getColorByName('pastel_orange')?.backgroundColor :
                    content.flight_status === 'on-time' ? getColorByName('pastel_green')?.backgroundColor :
                      content.flight_status === 'scheduled' ? getColorByName('pastel_blue')?.backgroundColor :
                        content.flight_status === 'active' ? getColorByName('pastel_green')?.backgroundColor :
                          content.flight_status === 'landed' ? getColorByName('pastel_teal')?.backgroundColor :
                            content.flight_status === 'incident' ? getColorByName('pastel_purple')?.backgroundColor :
                              content.flight_status === 'diverted' ? getColorByName('pastel_brown')?.backgroundColor :
                                getColorByName('pastel_sky')?.backgroundColor,
              color:
                content.flight_status === 'cancelled' ? getColorByName('pastel_red')?.textColor :
                  content.flight_status === 'delayed' ? getColorByName('pastel_orange')?.textColor :
                    content.flight_status === 'on-time' ? getColorByName('pastel_green')?.textColor :
                      content.flight_status === 'scheduled' ? getColorByName('pastel_blue')?.textColor :
                        content.flight_status === 'active' ? getColorByName('pastel_green')?.textColor :
                          content.flight_status === 'landed' ? getColorByName('pastel_teal')?.textColor :
                            content.flight_status === 'incident' ? getColorByName('pastel_purple')?.textColor :
                              content.flight_status === 'diverted' ? getColorByName('pastel_brown')?.textColor :
                                getColorByName('pastel_sky')?.textColor
            }} label={content.flight_status ? content.flight_status.charAt(0).toUpperCase() + content.flight_status.slice(1) : 'Unknown'} />
          </div>
        </div>
  
      </div>
  
      <div style={{ marginTop: '15px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', }}>
          <Icon icon="mdi:arrow-top-right-thin-circle-outline" style={{ marginRight: '4px', width: '22px', height: '22px' }} />
          <span style={{width: '100%', fontSize: '15px'}}>
            {content.departure.iata} • {content.departure.airport}
          </span>
        </div>
  
        <div style={{ marginTop: '15px', color: '#637381', fontSize: '14px' }}>
          {moment.utc(content.departure.actual || content.departure.scheduled).format('ddd, MMM D, YYYY')}
        </div>
  
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: '10px', width: '100%', marginTop: '15px' }}>
  
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <span style={{ fontWeight: 'bold', fontSize: '15px' }}>
              {moment.utc(content.departure.actual || content.departure.scheduled).format('h:mm A')} {content.departure.timezone && momentTz.tz(content.departure.timezone).format('z')}
            </span>
            {content.departure.scheduled && content.departure.actual && <span style={{ marginLeft: '10px', fontWeight: 'small', fontSize: '11px', color: '#637381', textDecoration: 'line-through' }}>
              {moment.utc(content.departure.scheduled).format('h:mm A')}
  
            </span>}
          </div>
  
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginLeft: 'auto', gap: '10px' }}>
            <span style={{ fontWeight: 'small', fontSize: '13px', color: '#637381' }}>
              Terminal <span style={{ fontWeight: 'bold' }}>{content.departure.terminal ? content.departure.terminal : 'N/A'}</span>
            </span>
            <div style={{ backgroundColor: getColorByName('pastel_saffron')?.backgroundColor, padding: '5px', width: 'fit-content', borderRadius: '4px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginLeft: 'auto' }}>
              <Icon icon="mdi:arrow-up-right-bold-outline" style={{ marginRight: '5px', color: getColorByName('pastel_saffron')?.textColor, width: '18px', height: '17px' }} />
              <span style={{ fontSize: '13px', fontWeight: 'bold', color: getColorByName('pastel_saffron')?.textColor }}>
                {content.departure.gate ? content.departure.gate : 'N/A'}
              </span>
            </div>
          </div>
  
        </div>
  
  
  
      </div>
  
      <div style={{ borderBottom: '1px solid #DFE1E6', marginTop: '15px', marginBottom: '15px' }}></div>
  
  
      <div style={{ marginTop: '15px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', }}>
          <Icon icon="mdi:arrow-bottom-right-thin-circle-outline" style={{ marginRight: '4px', width: '22px', height: '22px' }} />
          <span style={{width: '100%', fontSize: '15px'}}>
            {content.arrival.iata} • {content.arrival.airport}
          </span>
        </div>
  
  
        <div style={{ marginTop: '15px', color: '#637381', fontSize: '14px' }}>
          {moment.utc(content.arrival.actual || content.arrival.scheduled).format('ddd, MMM D, YYYY')}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: '10px', width: '100%', marginTop: '15px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <span style={{ fontWeight: 'bold', fontSize: '15px' }}>
              {moment.utc(content.arrival.actual || content.arrival.scheduled).format('h:mm A')}  {content.arrival.timezone && momentTz.tz(content.arrival.timezone).format('z')}
            </span>
            {/* {content.arrival.scheduled && content.arrival.actual && <span style={{ marginLeft: '10px', fontWeight: 'small', fontSize: '11px', color: '#637381', textDecoration: 'line-through' }}>
              {moment.utc(content.arrival.scheduled).format('h:mm A')}
            </span>} */}
          </div>
  
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginLeft: 'auto', gap: '10px' }}>
            <span style={{ fontWeight: 'small', fontSize: '13px', color: '#637381' }}>
              Terminal <span style={{ fontWeight: 'bold' }}>{content.arrival.terminal ? content.arrival.terminal : 'N/A'}</span>
            </span>
  
            <div style={{ backgroundColor: getColorByName('pastel_saffron')?.backgroundColor, padding: '5px', width: 'fit-content', borderRadius: '4px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginLeft: 'auto' }}>
              <Icon icon="streamline:baggage" style={{ marginRight: '5px', color: getColorByName('pastel_saffron')?.textColor, width: '18px', height: '17px' }} />
              <span style={{ fontSize: '13px', fontWeight: 'bold', color: getColorByName('pastel_saffron')?.textColor }}>
                {content.arrival.baggage ? content.arrival.baggage : 'N/A'}
              </span>
            </div>
  
            <div style={{ backgroundColor: getColorByName('pastel_saffron')?.backgroundColor, padding: '5px', width: 'fit-content', borderRadius: '4px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginLeft: 'auto' }}>
              <Icon icon="mdi:arrow-up-right-bold-outline" style={{ marginRight: '5px', color: getColorByName('pastel_saffron')?.textColor, width: '18px', height: '17px' }} />
              <span style={{ fontSize: '13px', fontWeight: 'bold', color: getColorByName('pastel_saffron')?.textColor }}>
                {content.arrival.gate ? content.arrival.gate : 'N/A'}
              </span>
            </div>
          </div>
  
        </div>
  
  
      </div>
  
  
    </div>;
  };

const MessageGroup: React.FC<MessageGroupProps> = ({ groupKey, messages }: MessageGroupProps) => {
    const isEmily = messages[0].sender === 'emily';
    return (
        <div key={groupKey} style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: isEmily ? 'flex-start' : 'flex-end',
            marginBottom: '8px'
        }}>
            <div style={{ color: 'gray', fontSize: 'small', paddingBottom: '8px' }}>
                {isEmily ? 'Emily' : 'Me'}
            </div>
            {messages.map((message, messageIndex) => {
                let contentElement;
                switch (message.content_type) {
                    case 'link':
                        contentElement = <LinkMessage content={message.content} />;
                        break;
                    case 'image':
                        contentElement = <ImageMessage content={message.content} />;
                        break;
                    case 'map':
                        contentElement = <MapMessage content={message.content} />;
                        break;
                    case 'phone_number':
                        contentElement = <PhoneNumberMessage content={message.content} />;
                        break;
                    case 'event':
                        contentElement = <EventMessage content={message.content as EventMessageItem} />;
                        break;
                    case 'task':
                        contentElement = <TaskMessage content={message.content as TaskMessageItem} />;
                        break;
                    case 'email':
                        contentElement = <EmailMessage content={message.content as EmailMessageItem} />;
                        break;
                    case 'website':
                        contentElement = <WebsiteMessage content={message.content as WebsiteMessageItem} />;
                        break;
                    case 'contact':
                        contentElement = <ContactMessage content={message.content as ContactItem} />;
                        break;
                    case 'flight':
                        contentElement = <FlightMessage content={message.content as FlightItem} />;
                        break;
                    case 'text':
                        contentElement = <TextMessage content={message.content} />;
                        break;
                    case 'crm_record':
                        contentElement = <CRMRecordMessage content={message.content as CRMRecordItem} />;
                        break;
                    default:
                        contentElement = <TextMessage content={message.content} />;
                        break;
                }

                return (
                    <div key={`${groupKey}-${messageIndex}`} style={{
                        padding: '10px',
                        backgroundColor: isEmily ? 'white' : 'rgba(159, 97, 220, 0.6)',
                        border: '1px solid #DFE1E6',
                        borderRadius: '4px',
                        marginBottom: '8px',
                        textAlign: 'left',
                        // width: message.content_type === 'map' || message.content_type === 'email' || message.content_type === 'task' || message.content_type === 'event' || message.content_type === 'website' || message.content_type === 'flight' ? '100%' : isEmily ? 'auto' : 'fit-content',
                        maxWidth: '100%',
                        color: isEmily ? 'black' : 'white'
                    }}>
                        {contentElement}
                        {/* {message.content_type === 'map' && (
                <div style={{ marginTop: '10px', textAlign: 'left', display: 'flex', justifyContent: 'space-between' }}>
                  <button
                    href={`https://maps.google.com/?q=${message.content}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                    style={{ flex: 1, marginRight: '5px' }}
                  >
                    <IonIcon icon={logoGoogle} slot="start" />
                    Google Maps
                  </IonButton>
                  <IonButton
                    href={`http://maps.apple.com/?q=${message.content}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                    style={{ flex: 1, marginLeft: '5px' }}
                  >
                    <IonIcon icon={logoApple} slot="start" />
                    Apple Maps
                  </IonButton>
                </div>
              )} */}
                    </div>
                );
            })}
        </div>
    );
};

export  { FlightMessage ,EmailMessage,EventMessage,WebsiteMessage};


interface CRMRecordItem {
    content: any;
  }
  const CRMRecordMessage = ({ content }: CRMRecordItem) => {
    const [companyLogo, setCompanyLogo] = useState<string | null>(null);
  
    const [showCallActionSheet, setShowCallActionSheet] = useState(false);
  
    const [callButtons, setCallButtons] = useState<any[]>([]);
    const [showEmailActionSheet, setShowEmailActionSheet] = useState(false);
    const [emailButtons, setEmailButtons] = useState<any[]>([]);
  
    const [clickedImage, setClickedImage] = useState<string | null>(null);

  
    useEffect(() => {
 
  
      let buttons: any[] = [];
  
      if (content.office_number) {
        buttons.push({
          text: `Office: ${content.office_number}${content.office_extension ? `, ext: ${content.office_extension}` : ''}`, handler: () => {
            window.open(`tel:${content.office_number}${content.office_extension ? `,${content.office_extension}` : ''}`, '_blank');
          }
        });
      }
      if (content.mobile_number) {
        buttons.push({
          text: `Mobile: ${content.mobile_number}`, handler: () => {
            window.open(`tel:${content.mobile_number}`, '_blank');
          }
        });
      }
      if (content.phone) {
        buttons.push({
          text: `Phone: ${content.phone}`, handler: () => {
            window.open(`tel:${content.phone}`, '_blank');
          }
        });
      }
  
      setCallButtons(buttons);
  
      let emailButtons: any[] = [];
  
      if (content.email_addresses && content.email_addresses.length > 0) {
        for (let email of content.email_addresses) {
          emailButtons.push({
            text: email, handler: () => {
              window.open(`mailto:${email}`, '_blank');
            }
          });
        }
      }
  
      setEmailButtons(emailButtons);
  
    }, []);
  
 

    return (
      <>
      
  
  
<div style={{ display: 'flex', flexDirection: 'row', gap: '10px', position: 'relative' }}>
          <img src={applehq} style={{ width: '100%', height: '150px', objectFit: 'cover', objectPosition: 'center' }}  />
          <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', position: 'absolute', bottom: '-26px', left: '5px', backgroundColor: 'white', borderRadius: '50px' }}>
           <img src={apple} alt="Company" style={{ width: '52px', height: '52px', borderRadius: '50%', }} />
          </div>
  
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '23px'  }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', alignContent: 'center', justifyContent: 'space-between', height: '100%' }}>
            <h1 style={{ color: 'black', fontSize: '20px', fontWeight: 'bold', margin: '10px 0px 0px 0px', }}>Apple</h1>
          
           {content.status &&  <Chip size="small" label={content.status.name} style={{ pointerEvents: 'none', backgroundColor: getColorByName(content.status.color)?.backgroundColor, color: getColorByName(content.status.color)?.textColor,  }}/>}
          </div>

  
  
          {(content.address) && <p style={{ fontSize: '14px', margin: '0px', color: 'black' }}>
            {content.address && `${content.address}`}
          
          </p>}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'flex-start', marginTop: '10px' }}>
  
  
           <p style={{ fontSize: '14px', margin: '0px', color: 'black' }}>{content.description}</p>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                    <button style={{  paddingLeft:'10px', paddingRight:'10px', borderRadius: '4px', height: '27px', border: 'none', background: '#9F61DC', color: 'white', fontSize: '14px', fontWeight: 'normal', textTransform: 'capitalize', fontFamily: 'Public Sans'  }} >
              Call
            </button>
            <button style={{ paddingLeft:'10px', paddingRight:'10px', borderRadius: '4px', height: '27px', border: 'none', background: '#9F61DC', color: 'white', fontSize: '14px', fontWeight: 'normal', textTransform: 'capitalize', fontFamily: 'Public Sans'  }} >
              Email
            </button>
           <button style={{ paddingLeft:'10px', paddingRight:'10px', borderRadius: '4px', height: '27px', border: 'none', background: '#9F61DC', color: 'white', fontSize: '14px', fontWeight: 'normal', textTransform: 'capitalize', fontFamily: 'Public Sans'  }} >
              Website
            </button>
  
            <button style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft:'10px', paddingRight:'10px', borderRadius: '4px', height: '27px', border: 'none', background: '#9F61DC', color: 'white', fontSize: '14px', fontWeight: 'normal', textTransform: 'capitalize', fontFamily: 'Public Sans'  }} >
              <Icon icon={'mdi:linkedin'} style={{ width: '15px', height: '15px' }} />
            </button>
  
            <button style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft:'10px', paddingRight:'10px', borderRadius: '4px', height: '27px', border: 'none', background: '#9F61DC', color: 'white', fontSize: '14px', fontWeight: 'normal', textTransform: 'capitalize', fontFamily: 'Public Sans'  }}>
              <Icon icon={'pajamas:twitter'} style={{ width: '15px', height: '15px' }} />
            </button>
  
            <button style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft:'10px', paddingRight:'10px', borderRadius: '4px', height: '27px', border: 'none', background: '#9F61DC', color: 'white', fontSize: '14px', fontWeight: 'normal', textTransform: 'capitalize', fontFamily: 'Public Sans'  }} >
              <Icon icon={'simple-icons:crunchbase'} style={{ width: '15px', height: '15px' }} />
            </button>
          </div>
        </div>
  
  
      </>
    );
  };
  