import React, { useState, useEffect, memo } from 'react';
import './speech.css';

interface EmilySpeechProps {
  startAnimation?: boolean;
  fft?: number[];
}

const EmilySpeech: React.FC<EmilySpeechProps> = memo(({ startAnimation = false, fft = [] }) => {
  const [randomRotations, setRandomRotations] = useState([0, 0, 0, 0]);
  const [isAnimating, setIsAnimating] = useState(false);
  const [scale, setScale] = useState(1);
  const DEFAULT_SIZE = 1;
  const MAX_SIZE = 1.25;
  const MIN_SIZE = 0.90; // New constant for the minimum size

  useEffect(() => {
    setRandomRotations([
      Math.random() * 360,
      Math.random() * 360,
      Math.random() * 360,
      Math.random() * 360
    ]);
  }, []);

  useEffect(() => {
    setIsAnimating(startAnimation);
    if (!startAnimation) {
      setScale(MIN_SIZE); // Set scale to 5% smaller when not animating
    }
  }, [startAnimation]);

  useEffect(() => {
    if (fft.length > 0 && isAnimating) {
      const avgFft = fft.slice(0, 8).reduce((sum, val) => sum + val, 0) / 8;
      const newScale = Math.min(DEFAULT_SIZE + (avgFft * 0.5), MAX_SIZE);
      setScale(newScale);
    } else if (!isAnimating) {
      setScale(MIN_SIZE); // Ensure scale is set to 5% smaller when not animating
    } else {
      setScale(DEFAULT_SIZE);
    }
  }, [fft, isAnimating]);

  return (
    <div
      style={{
        position: 'relative',
        width: '80px',
        height: '80px',
        overflow: 'hidden',
        borderRadius: '50%',
        transform: `scale(${scale})`,
        transition: 'transform 0.1s ease-out',
      }}
      className={isAnimating ? "animated-shadow" : ""}
    >
      <div className="white-circle" style={{ position: 'absolute', top: 0, left: 0 }}></div>
      <div
        className="circle"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          transform: `rotate(${randomRotations[0]}deg)`,
          animation: isAnimating ? 'rotate1 7s linear infinite, fade1 10s ease-in-out infinite' : 'none',
        }}
      ></div>
      <div
        className="circle-2"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          transform: `rotate(${randomRotations[1]}deg)`,
          animation: isAnimating ? 'rotate2 5s linear infinite, fade2 8s ease-in-out infinite' : 'none',
        }}
      ></div>
      <div
        className="circle-3"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          transform: `rotate(${randomRotations[2]}deg)`,
          animation: isAnimating ? 'rotate3 6s linear infinite, fade3 12s ease-in-out infinite' : 'none',
        }}
      ></div>
      <div
        className="circle-4"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          transform: `rotate(${randomRotations[3]}deg)`,
          animation: isAnimating ? 'rotate4 4s linear infinite, fade4 9s ease-in-out infinite' : 'none',
        }}
      ></div>
      <style>
        {`
          .animated-shadow {
            animation: shadowChange 10s infinite;
          }
          @keyframes shadowChange {
            0%, 100% { box-shadow: 0 0 20px 2px #87C9FE, inset 0 0 20px 2px #87C9FE; }
            33% { box-shadow: 0 0 20px 2px #754FFF, inset 0 0 20px 2px #754FFF; }
            66% { box-shadow: 0 0 20px 2px #E49BFD, inset 0 0 20px 2px #E49BFD; }
          }
          .circle, .circle-2, .circle-3, .circle-4 {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 2px solid;
          }
          .circle {
            border-color: #FF5733;
            animation-delay: -2s, 0s;
          }
          .circle-2 {
            border-color: #33FF57;
            animation-delay: -1s, -3s;
          }
          .circle-3 {
            border-color: #3357FF;
            animation-delay: -3s, -6s;
          }
          .circle-4 {
            border-color: #FF33A1;
            animation-delay: -4s, -8s;
          }
          @keyframes rotate1 {
            from { transform: rotate(0deg); }
            to { transform: rotate(-360deg); }
          }
          @keyframes rotate2 {
            from { transform: rotate(120deg); }
            to { transform: rotate(480deg); }
          }
          @keyframes rotate3 {
            from { transform: rotate(-120deg); }
            to { transform: rotate(240deg); }
          }
          @keyframes rotate4 {
            from { transform: rotate(180deg); }
            to { transform: rotate(540deg); }
          }
          @keyframes fade1 {
            0%, 100% { opacity: 0.85; }
            50% { opacity: 1; }
          }
          @keyframes fade2 {
            0%, 100% { opacity: 1; }
            50% { opacity: 0.9; }
          }
          @keyframes fade3 {
            0%, 100% { opacity: 0.95; }
            50% { opacity: 0.8; }
          }
          @keyframes fade4 {
            0%, 100% { opacity: 0.9; }
            50% { opacity: 0.7; }
          }
        `}
      </style>
    </div>
  );
});

export default EmilySpeech;
