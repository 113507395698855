import React, { useEffect, useState, CSSProperties, useRef } from 'react';
import Logo from './Logo';
import styled from 'styled-components';
import { SvgIcon, useMediaQuery, useTheme } from '@mui/material'; // Import useMediaQuery from MUI
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link,useLocation,useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';


function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [logoColorChange, setLogoColorChange] = useState(false);

  // Use MUI's useMediaQuery to check for screen size
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = document.body.scrollHeight * (!isDesktop ? 0.005 : 0.01);
      const isScrolled = scrollPosition > threshold;
      setScrolled(isScrolled);
      setLogoColorChange(isScrolled || menuOpen);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [menuOpen]);

  // Close the menu when switching to non-mobile view
  useEffect(() => {
    if (isDesktop) {
      setMenuOpen(false);
    }
  }, [isDesktop]);

  const headerStyle: CSSProperties = {
    position: 'fixed',
    top: 0,
    width: '100%',
    maxWidth: '100vw',
    zIndex: 1000,
    color: 'white',
    backgroundColor: scrolled || menuOpen ? '#fff' : 'transparent',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingTop: !isDesktop ? '20px' : '15px',
    paddingBottom: !isDesktop ? '20px' : '15px',
    boxSizing: 'border-box',
    borderBottom: logoColorChange ? '1px solid #DFE1E6' : 'none',
  };

  const Nav = styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  `;

  const ulStyle = {
    display: !isDesktop ? 'none' : 'flex', // Hide on mobile
    listStyle: 'none',
    gap: '40px',
    margin: 0,
    padding: 0,
    marginLeft: '40px',
  };

  const linkStyle = {
    color: '#0C082D',
    textDecoration: 'none',
    fontWeight: 'normal',
    fontSize: '14px',
  };

  const buttonsStyle = {
    marginLeft: 'auto',
    display: 'flex',
    gap: '10px',
    marginRight: !isDesktop ? '40px' : '0', // Add margin-right on mobile
  };

  const btnStyle: CSSProperties = {
    padding: '10px 20px',
    border: '1px solid black',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    color: 'black',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    textAlign: 'center',
    fontFamily: 'Public Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    height: 'auto',
  };

  const btnPrimaryStyle = {
    ...btnStyle,
    backgroundColor: '#9F61DC',
    border: 'none',
    color: 'white',
  };

const btnAppleStyle = {
  ...btnStyle,
  backgroundColor: '#000',
  border: 'none',
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
  

  const drawerStyle: CSSProperties = {
    position: 'absolute',
    top: '100%',
    right: 0,
    backgroundColor: '#fff',
    width: '100%',
    padding: '20px',
    boxSizing: 'border-box',
    opacity: menuOpen ? 1 : 0, // Use opacity for fade effect
    visibility: menuOpen ? 'visible' : 'hidden', // Use visibility to hide the element when not open
    transition: 'opacity 0.3s ease, visibility 0.3s ease', // Add transition for smooth fade
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    height: '100vh',
  };

  const mobileLinkStyle = {
    ...linkStyle,
    display: 'block',
    marginBottom: '20px',
    fontWeight: 700,
    fontSize: '30px',
  };

  const mobileButtonStyle = {
    ...btnStyle,
    display: 'block',
    marginTop: '20px',
    width: '100%',
    backgroundColor: '#9F61DC',
    padding: '20px 20px',
    fontWeight: 700,
    fontSize: '22px',
    border: 'none',
    textDecoration: 'none',
    color: '#fff',
  };

  const appleMobileButtonStyle = {
    ...btnStyle,
    display: 'block',
    marginTop: '20px',
    width: '100%',
    backgroundColor: '#000',
    padding: '20px 20px',
    fontWeight: 700,
    fontSize: '22px',
    border: 'none',
    textDecoration: 'none',
    color: '#fff',
  };
  return (
    <header style={headerStyle}>
      <Nav>
       <a href="#" style={{ display: 'flex', justifyContent: 'center'}} onClick={(e) => {
        e.preventDefault();
        if (location.pathname !== "/") {
          navigate("/");
        } else {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        setMenuOpen(false);
       }}>
            <Logo color="black" />
       </a>
        <ul style={ulStyle}>
          <li><Link to="/#product" style={linkStyle}>Product</Link></li>
          <li><Link to="/#pricing" style={linkStyle}>Pricing</Link></li>
          <li><Link to="/#faq" style={linkStyle}>FAQ</Link></li>
          <li><Link to="/#contact" style={linkStyle}>Contact</Link></li>
        </ul>
        <div style={buttonsStyle}>
          {isDesktop && <a href="https://app.zweelie.com/login" style={{...btnStyle, textDecoration: 'none'}}>Log In</a>}
    {isDesktop &&<a target="_blank" href="https://testflight.apple.com/join/D9dnCU4K" style={{ ...btnAppleStyle, textDecoration: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' , width:'173.84px'}}>
                    <div style={{ marginRight: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24"><path fill="white" d="M17.05 20.28c-.98.95-2.05.8-3.08.35c-1.09-.46-2.09-.48-3.24 0c-1.44.62-2.2.44-3.06-.35C2.79 15.25 3.51 7.59 9.05 7.31c1.35.07 2.29.74 3.08.8c1.18-.24 2.31-.93 3.57-.84c1.51.12 2.65.72 3.4 1.8c-3.12 1.87-2.38 5.98.48 7.13c-.57 1.5-1.31 2.99-2.54 4.09zM12.03 7.25c-.15-2.23 1.66-4.07 3.74-4.25c.29 2.58-2.34 4.5-3.74 4.25"/></svg>

                    </div>
                    <span style={{ fontSize: '16px', fontWeight: 600 }}>Get TestFlight</span>
                </a>}
        </div>
       
        {!isDesktop && ( // Conditionally render the menu button
          <button style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer', display: 'flex', justifyContent: 'center'}} onClick={() => {
            setMenuOpen(!menuOpen);
          }}>
            {menuOpen ? <CloseIcon style={{ color: 'black', height: '30px', width: '30px' }} /> : <MenuIcon style={{ color: 'black', height: '30px', width: '30px' }} />}
          </button>
        )}
      </Nav>
      <div style={drawerStyle}>
      <Link to="/" style={mobileLinkStyle} onClick={() => setMenuOpen(false)}>Home</Link>
      <Link to="/#product" style={mobileLinkStyle} onClick={() => setMenuOpen(false)}>Product</Link>
      <Link to="/#pricing" style={mobileLinkStyle} onClick={() => setMenuOpen(false)}>Pricing</Link>
      <Link to="/#faq" style={mobileLinkStyle} onClick={() => setMenuOpen(false)}>FAQ</Link>
        <Link to="/#contact" style={mobileLinkStyle} onClick={() => setMenuOpen(false)}>Contact</Link>
        <a href="https://app.zweelie.com/login" style={mobileLinkStyle}>Log In</a>

        <a href="https://app.zweelie.com/login" style={mobileButtonStyle}>Get More Done</a>
        <a target="_blank" href="https://testflight.apple.com/join/D9dnCU4K" style={{ ...appleMobileButtonStyle, textDecoration: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' , width:'100%'}}>
                    <div style={{ marginRight: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24"><path fill="white" d="M17.05 20.28c-.98.95-2.05.8-3.08.35c-1.09-.46-2.09-.48-3.24 0c-1.44.62-2.2.44-3.06-.35C2.79 15.25 3.51 7.59 9.05 7.31c1.35.07 2.29.74 3.08.8c1.18-.24 2.31-.93 3.57-.84c1.51.12 2.65.72 3.4 1.8c-3.12 1.87-2.38 5.98.48 7.13c-.57 1.5-1.31 2.99-2.54 4.09zM12.03 7.25c-.15-2.23 1.66-4.07 3.74-4.25c.29 2.58-2.34 4.5-3.74 4.25"/></svg>

                    </div>
                    <span style={{ fontSize: '22px', fontWeight: 600 }}>Get TestFlight</span>
                </a>
      </div>
    </header>
  );
}


export default Header;

const LogoOnly = () => {
    return (
        <svg width="30" height="33" viewBox="0 0 30 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.5426 11.172V9.30964L25.8115 5.58499H21.6403V3.93217L17.9092 0.20752H0V8.21844L3.73109 11.9431H9.70957L0.387684 21.3071L0 21.828V23.6845L3.73109 27.4091H7.90815V29.0678L11.6392 32.7924H29.5426V24.7815L25.8115 21.0569H19.8331L29.1608 11.6928L29.5426 11.172ZM24.494 7.03702L9.23152 22.3692H24.494V27.7496H9.23152V22.3692H1.32337V22.2354L16.5917 6.90317H1.32337V1.52278H16.5917V6.90317H24.494V7.03702Z" fill="#CC86ED"/>
        <path d="M24.4911 22.3692H9.22862V27.7496H24.4911V22.3692Z" fill="white"/>
        <path d="M24.494 6.90317V7.03702L9.22862 22.3692L1.32046 22.3692V22.2354L16.5917 6.90317H24.494Z" fill="white"/>
        <path d="M16.5888 1.52278H1.32046V6.90317H16.5888V1.52278Z" fill="white"/>
        </svg>
    )
}