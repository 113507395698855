import React from 'react';
import Page from './components/Page';
import { Divider } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Page title="Privacy policy">
    <div style={{ textAlign: 'center', marginBottom: '20px' }}>
      <h3>{`Effective: Tue Nov 05, 2024`}</h3>
    </div>
    <Divider sx={{borderTop: '1px solid #DFE1E6', width: '100%'}} />

    <div style={{ padding: '20px' }}>

  
  <p>Zweelie is a platform designed to enhance productivity and streamline operations for businesses and individuals alike. Our tools and services are crafted to help users manage their tasks efficiently, collaborate seamlessly, and achieve their goals effectively. This privacy policy outlines the types of information we collect, including data related to <strong>Emily</strong>, and explains how we use, protect, and store your information.</p>
  
  <p>This policy is subject to change at any time, and we encourage you to review it periodically.</p>

  <h3>1. Collection of Personal Data</h3>
  <p>We never sell or share your data, we only use it to make you more productive within your workspace.</p>
  <p>We collect personal data in order to provide, improve, and personalize our services. This data may include:</p>
  <ul>
    <li><strong>Information You Provide Directly:</strong> When you sign up for Zweelie, you provide personal data such as your name, email address, phone number, and payment information.</li>
    <li><strong>Data Related to Emily’s Assistance:</strong> When you interact with Emily, we may collect information related to your tasks, calendar events, preferences, and any input you provide to enhance your productivity experience.</li>
    <li><strong>Device and Usage Data:</strong> We may collect technical information about your device, including IP address, browser type, and usage statistics, to improve performance and ensure a seamless experience.</li>
  </ul>
  <p>We only collect personal data that is necessary for the operation and enhancement of our platform, and we handle this data in accordance with applicable data protection laws.</p>

  <h3>2. Training of AI Models (Emily)</h3>
  <p>Emily does not use your personal data for training our AI models. All interactions with Emily are processed solely to provide real-time assistance and are not utilized to improve or train underlying algorithms. This approach helps to protect your privacy while still delivering a responsive and intelligent experience.</p>

  <h3>3. Encryption and Data Security</h3>
  <p>We prioritize the security of your personal information:</p>
  <ul>
    <li><strong>Data Encryption:</strong> All personal data, including interactions with Emily, is encrypted both at rest and in transit to prevent unauthorized access.</li>
    <li><strong>Data Protection Measures:</strong> While we implement strict security measures to safeguard your data, no method of data transmission or storage is 100% secure. We are continuously reviewing and updating our security protocols to protect your information as effectively as possible.</li>
  </ul>

  <h3>4. Uses of Personal Data</h3>
  <p>We use your personal data to:</p>
  <ul>
    <li>Provide and improve the Zweelie platform and all related tools and services.</li>
    <li>Facilitate interactions with Emily, our virtual assistant, to deliver a personalized and effective user experience.</li>
    <li>Ensure compliance with legal obligations and applicable data protection laws, including those in the state of Delaware, where Zweelie Inc. is incorporated.</li>
    <li>Enhance customer support, manage user accounts, and communicate important updates related to our services.</li>
  </ul>
  <p>We handle all personal data in compliance with relevant data protection regulations and ensure that your information is used only for legitimate purposes.</p>

  <h3>5. Permitted Uses Under Applicable Data Protection Laws</h3>
  <p>Our collection, use, and storage of personal data comply with data protection laws applicable to Zweelie, including but not limited to those in Delaware, USA. We do not process your personal data for any purposes that are not explicitly allowed by these laws, and we take steps to ensure your rights are respected and upheld.</p>

  <h3>6. Children’s Privacy</h3>
  <p>Our platform is not intended for children under the age of 18. We do not knowingly collect or process personal data from individuals under 18 years old. If we become aware that personal data from a child under 18 has been inadvertently collected, we will take steps to delete it as soon as possible. Parents or guardians who believe that their child may have provided us with personal data can contact us to request deletion.</p>

  <h3>7. Data Accessibility and Workspace Permissions</h3>
  <ul>
    <li><strong>Chat History with Emily:</strong> Chat history with Emily is encrypted and accessible only to the individual user within their workspace. This history can be recreated or revisited solely by the user who initiated the chat, ensuring privacy and exclusive access to those interactions.</li>
    <li><strong>Workspace Data Visibility:</strong> In a workspace setting, multiple team members may share access to certain tools and resources. However, data privacy is carefully managed:
      <ul>
        <li><strong>Chat History:</strong> Chat history with Emily remains private to the individual user and is not accessible to other team members within the workspace.</li>
        <li><strong>Other Data:</strong> Data other than chat history with Emily, such as tasks, events, or shared documents, is only visible to other team members if explicitly made available by the user or the workspace admin. Only data that the user or admin has designated as shared or accessible will be viewable by others in the workspace, ensuring control over information visibility.</li>
      </ul>
    </li>
  </ul>
  <p>This structure allows for collaborative work within a workspace while maintaining privacy over personal interactions with Emily and control over shared data.</p>

  <h3>Contact Us</h3>
  <p>If you have any questions or concerns about this privacy policy or our data practices, please contact us at:</p>
  <p>Email: support@zweelie.com</p>


  <p>This privacy policy is intended to provide clear and concise information about how Zweelie collects, uses, and protects your personal data. We are committed to safeguarding your privacy and ensuring a secure, trustworthy experience on our platform.</p>

   

    </div>
    </Page>
  );
};

export default PrivacyPolicy;
