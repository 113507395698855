import { Table, TableHead, TableRow, TableCell, Chip, TableBody, Stack, Typography, Tooltip, Avatar, SvgIconProps, SvgIcon, Theme, CheckboxProps, createTheme } from '@mui/material';
import './tableStyles.css';
import { useEffect, useState ,useMemo} from 'react';
import { getColorByName } from './functions/PastelColors';
import { Icon } from '@iconify/react';
import moment from 'moment';

import patrickcollison from './avatars/patrickcollison.jpg';
import sundarpichai from './avatars/sundarpichai.jpg';
import roelofbotha from './avatars/roelofbotha.jpg';
import brian from './avatars/brianchesky.jpg';
import jensenhuang from './avatars/jensenhuang.jpg';
import bobiger from './avatars/bobiger.jpg';
import markzuckerberg from './avatars/markzuckerberg.jpg';
import davidzaslav from './avatars/davidzaslav.jpg';
import timcook from './avatars/timcook.jpg';
import tobiaslutke from './avatars/tobiaslutke.jpg';
import benhorowitz from './avatars/benhorowitz.jpg';
import satyanadella from './avatars/satyanadella.jpg';

import apple from './avatars/apple.png';
import a16z from './avatars/a16z.png';
import airbnb from './avatars/airbnb.png';
import google from './avatars/google.png';
import microsoft from './avatars/microsoft.png';
import shopify from './avatars/shopify.png';
import nvidia from './avatars/nvidia.png';
import meta from './avatars/meta.png';
import sequoia from './avatars/sequoiacap.png';
import stripe from './avatars/stripe.png';
import warnerbros from './avatars/warnerbros.png';
import disney from './avatars/disney.png';

var capitalize = require('capitalize');

interface CRMTableProps {
    isProductCard?: boolean;
    rows: any[];
}

const CRMTable = ({ rows, isProductCard }: CRMTableProps) => {

        
    const [checkedRows, setCheckedRows] = useState<number[]>([]);

    const lists: {name: string, color: string}[] = [ {name: "Customers", color: "pastel_green"}, {name: "Investors", color: "pastel_blue"}];

  

    const columns = [
        { name: 'Company', key: 'company', icon: <CheckboxIcon /> },
        { name: 'List', key: 'list', icon: <Icon icon="material-symbols:list" /> },
        { name: 'Type', key: 'company_type', icon: <Icon icon="mdi:business" /> },
        { name: 'Status', key: 'status', icon: <Icon icon="mdi:progress-check" /> },
        { name: 'Last Activity', key: 'last_activity', icon: <Icon icon="fluent:shifts-activity-20-filled" /> },
        { name: 'Categories', key: 'categories', icon: <Icon icon="mdi:tag-outline" /> },
    ];

    
const categories = [
    {
      "name": "3D Printing",
      "color": "pastel_silver"
    },
    {
      "name": "Accounting",
      "color": "pastel_ruby"
    },
    {
      "name": "Aerospace & Defense",
      "color": "pastel_purple"
    },
    {
      "name": "Agriculture",
      "color": "pastel_mocha"
    },
    {
      "name": "Airlines",
      "color": "pastel_crimson"
    },
    {
      "name": "Alternative Medicine",
      "color": "pastel_lemon"
    },
    {
      "name": "Animation",
      "color": "pastel_ruby"
    },
    {
      "name": "Apparel & Footwear",
      "color": "pastel_mocha"
    },
    {
      "name": "Architecture",
      "color": "pastel_mauve"
    },
    {
      "name": "Artificial Intelligence",
      "color": "pastel_gold"
    },
    {
      "name": "Arts",
      "color": "pastel_indigo"
    },
    {
      "name": "Arts & Crafts",
      "color": "pastel_cobalt"
    },
    {
      "name": "Asset Management",
      "color": "pastel_ivory"
    },
    {
      "name": "Audio",
      "color": "pastel_silver"
    },
    {
      "name": "Automation",
      "color": "pastel_sage"
    },
    {
      "name": "Automotive",
      "color": "pastel_cobalt"
    },
    {
      "name": "B2B",
      "color": "pastel_indigo"
    },
    {
      "name": "B2C",
      "color": "pastel_lime"
    },
    {
      "name": "Banking & Mortgages",
      "color": "pastel_jade"
    },
    {
      "name": "Beverages",
      "color": "pastel_teal"
    },
    {
      "name": "Biotechnology",
      "color": "pastel_ivory"
    },
    {
      "name": "Broadcasting",
      "color": "pastel_honey"
    },
    {
      "name": "Building Materials",
      "color": "pastel_lilac"
    },
    {
      "name": "Business Supplies",
      "color": "pastel_orchid"
    },
    {
      "name": "Chemicals",
      "color": "pastel_sage"
    },
    {
      "name": "Civil Engineering",
      "color": "pastel_pistachio"
    },
    {
      "name": "Cloud Services",
      "color": "pastel_indigo"
    },
    {
      "name": "Communications",
      "color": "pastel_yellow"
    },
    {
      "name": "Computer Hardware",
      "color": "pastel_blue"
    },
    {
      "name": "Construction",
      "color": "pastel_red"
    },
    {
      "name": "Construction Contractors & Services",
      "color": "pastel_slate"
    },
    {
      "name": "Consulting & Professional Services",
      "color": "pastel_lime"
    },
    {
      "name": "Consumer Discretionary",
      "color": "pastel_blush"
    },
    {
      "name": "Consumer Electronics",
      "color": "pastel_lime"
    },
    {
      "name": "Consumer Goods",
      "color": "pastel_periwinkle"
    },
    {
      "name": "Consumer Staples",
      "color": "pastel_teal"
    },
    {
      "name": "Corporate & Business",
      "color": "pastel_blush"
    },
    {
      "name": "Cosmetics",
      "color": "pastel_jade"
    },
    {
      "name": "Design",
      "color": "pastel_orange"
    },
    {
      "name": "E-commerce",
      "color": "pastel_crimson"
    },
    {
      "name": "E-Commerce & Marketplaces",
      "color": "pastel_lilac"
    },
    {
      "name": "E-Learning",
      "color": "pastel_purple"
    },
    {
      "name": "Education",
      "color": "pastel_amber"
    },
    {
      "name": "Electrical",
      "color": "pastel_aqua"
    },
    {
      "name": "Energy",
      "color": "pastel_ruby"
    },
    {
      "name": "Energy & Utilities",
      "color": "pastel_pearl"
    },
    {
      "name": "Enterprise",
      "color": "pastel_burgundy"
    },
    {
      "name": "Entertainment & Recreation",
      "color": "pastel_pistachio"
    },
    {
      "name": "Events",
      "color": "pastel_gold"
    },
    {
      "name": "Eyewear",
      "color": "pastel_sage"
    },
    {
      "name": "Facilities",
      "color": "pastel_turquoise"
    },
    {
      "name": "Fashion & Leather Goods",
      "color": "pastel_saffron"
    },
    {
      "name": "Family Services",
      "color": "pastel_magenta"
    },
    {
      "name": "Finance",
      "color": "pastel_peach"
    },
    {
      "name": "Financial Services",
      "color": "pastel_plum"
    },
    {
      "name": "Fine Art",
      "color": "pastel_charcoal"
    },
    {
      "name": "Firearms",
      "color": "pastel_sand"
    },
    {
      "name": "Fishery",
      "color": "pastel_mint"
    },
    {
      "name": "Food",
      "color": "pastel_peach"
    },
    {
      "name": "Food Production",
      "color": "pastel_periwinkle"
    },
    {
      "name": "Forums",
      "color": "pastel_sky"
    },
    {
      "name": "Fundraising",
      "color": "pastel_indigo"
    },
    {
      "name": "Gambling & Casinos",
      "color": "pastel_charcoal"
    },
    {
      "name": "Government",
      "color": "pastel_turquoise"
    },
    {
      "name": "Ground Transportation",
      "color": "pastel_ruby"
    },
    {
      "name": "Health & Wellness",
      "color": "pastel_peach"
    },
    {
      "name": "Health Care",
      "color": "pastel_indigo"
    },
    {
      "name": "Higher Education",
      "color": "pastel_crimson"
    },
    {
      "name": "Home & Furniture",
      "color": "pastel_beige"
    },
    {
      "name": "Home Improvement",
      "color": "pastel_lime"
    },
    {
      "name": "Human Resources",
      "color": "pastel_ivory"
    },
    {
      "name": "Import & Export",
      "color": "pastel_periwinkle"
    },
    {
      "name": "Industrials & Manufacturing",
      "color": "pastel_pearl"
    },
    {
      "name": "Information Technology & Services",
      "color": "pastel_sand"
    },
    {
      "name": "Insurance",
      "color": "pastel_orange"
    },
    {
      "name": "International Relations",
      "color": "pastel_gold"
    },
    {
      "name": "International Trade",
      "color": "pastel_olive"
    },
    {
      "name": "Internet",
      "color": "pastel_blue"
    },
    {
      "name": "Investment",
      "color": "pastel_turquoise"
    },
    {
      "name": "Investment Banking",
      "color": "pastel_mauve"
    },
    {
      "name": "Investment Management",
      "color": "pastel_sand"
    },
    {
      "name": "ISP",
      "color": "pastel_pistachio"
    },
    {
      "name": "Jewelry Watches & Luxury Goods",
      "color": "pastel_mauve"
    },
    {
      "name": "Judiciary",
      "color": "pastel_saffron"
    },
    {
      "name": "Law Enforcement",
      "color": "pastel_crimson"
    },
    {
      "name": "Legal Services",
      "color": "pastel_gold"
    },
    {
      "name": "Libraries",
      "color": "pastel_slate"
    },
    {
      "name": "Machinery",
      "color": "pastel_lavender"
    },
    {
      "name": "Maritime",
      "color": "pastel_cyan"
    },
    {
      "name": "Market Research",
      "color": "pastel_crimson"
    },
    {
      "name": "Marketing & Advertising",
      "color": "pastel_ivory"
    },
    {
      "name": "Marketplace",
      "color": "pastel_indigo"
    },
    {
      "name": "Mechanical Engineering",
      "color": "pastel_burgundy"
    },
    {
      "name": "Media",
      "color": "pastel_copper"
    },
    {
      "name": "Medicine",
      "color": "pastel_orange"
    },
    {
      "name": "Military",
      "color": "pastel_lilac"
    },
    {
      "name": "Mining & Metals",
      "color": "pastel_slate"
    },
    {
      "name": "Mobile",
      "color": "pastel_charcoal"
    },
    {
      "name": "Movies & TV",
      "color": "pastel_mint"
    },
    {
      "name": "Museums",
      "color": "pastel_saffron"
    },
    {
      "name": "Music",
      "color": "pastel_slate"
    },
    {
      "name": "Nanotechnology",
      "color": "pastel_lime"
    },
    {
      "name": "Networking",
      "color": "pastel_orange"
    },
    {
      "name": "Non-Profit & Philanthropy",
      "color": "pastel_ruby"
    },
    {
      "name": "Oil & Gas",
      "color": "pastel_ruby"
    },
    {
      "name": "Outsourcing",
      "color": "pastel_lime"
    },
    {
      "name": "Packaging & Containers",
      "color": "pastel_orange"
    },
    {
      "name": "Paper Goods",
      "color": "pastel_gold"
    },
    {
      "name": "Payments",
      "color": "pastel_ivory"
    },
    {
      "name": "Performing Arts",
      "color": "pastel_green"
    },
    {
      "name": "Pharmaceuticals",
      "color": "pastel_mocha"
    },
    {
      "name": "Pharmacy",
      "color": "pastel_red"
    },
    {
      "name": "Photography",
      "color": "pastel_red"
    },
    {
      "name": "Plastics",
      "color": "pastel_slate"
    },
    {
      "name": "Plumbing",
      "color": "pastel_olive"
    },
    {
      "name": "Political Organization",
      "color": "pastel_lemon"
    },
    {
      "name": "Primary & Secondary Education",
      "color": "pastel_sky"
    },
    {
      "name": "Printing",
      "color": "pastel_lime"
    },
    {
      "name": "Public Relations",
      "color": "pastel_blue"
    },
    {
      "name": "Publishing",
      "color": "pastel_orange"
    },
    {
      "name": "Ranching",
      "color": "pastel_jade"
    },
    {
      "name": "Real Estate",
      "color": "pastel_mauve"
    },
    {
      "name": "Religion",
      "color": "pastel_mocha"
    },
    {
      "name": "Renewables & Environment",
      "color": "pastel_mocha"
    },
    {
      "name": "Restaurants",
      "color": "pastel_mint"
    },
    {
      "name": "Retail",
      "color": "pastel_turquoise"
    },
    {
      "name": "SAAS",
      "color": "pastel_gold"
    },
    {
      "name": "Sanitization Services",
      "color": "pastel_silver"
    },
    {
      "name": "Scientific & Academic Research",
      "color": "pastel_cobalt"
    },
    {
      "name": "Security",
      "color": "pastel_pistachio"
    },
    {
      "name": "Services",
      "color": "pastel_mint"
    },
    {
      "name": "Shipbuilding",
      "color": "pastel_coral"
    },
    {
      "name": "Shipping & Logistics",
      "color": "pastel_ruby"
    },
    {
      "name": "Society",
      "color": "pastel_pearl"
    },
    {
      "name": "Sporting Goods",
      "color": "pastel_mint"
    },
    {
      "name": "Sports & Fitness",
      "color": "pastel_red"
    },
    {
      "name": "Stores",
      "color": "pastel_blush"
    },
    {
      "name": "Talent Agencies",
      "color": "pastel_jade"
    },
    {
      "name": "Technology",
      "color": "pastel_peach"
    },
    {
      "name": "Telecommunications",
      "color": "pastel_beige"
    },
    {
      "name": "Textiles",
      "color": "pastel_green"
    },
    {
      "name": "Tobacco",
      "color": "pastel_honey"
    },
    {
      "name": "Tools",
      "color": "pastel_purple"
    },
    {
      "name": "Translation",
      "color": "pastel_coral"
    },
    {
      "name": "Transportation",
      "color": "pastel_amber"
    },
    {
      "name": "Travel & Leisure",
      "color": "pastel_sage"
    },
    {
      "name": "Utilities",
      "color": "pastel_gold"
    },
    {
      "name": "Venture Capital",
      "color": "pastel_teal"
    },
    {
      "name": "Veterinary",
      "color": "pastel_lemon"
    },
    {
      "name": "Video Games",
      "color": "pastel_yellow"
    },
    {
      "name": "Warehousing",
      "color": "pastel_blush"
    },
    {
      "name": "Web Services & Apps",
      "color": "pastel_slate"
    },
    {
      "name": "Wholesale",
      "color": "pastel_mauve"
    },
    {
      "name": "Wines & Spirits",
      "color": "pastel_purple"
    }
  ]

  const getCategories = (categoryNames: string[]) => {
    return categoryNames.map((categoryName) => {
      const categoryOption = categories.find(cat => cat.name.toLowerCase() === categoryName.toLowerCase());
      return categoryOption ? { name: categoryOption.name, color: categoryOption.color } : { name: capitalize.words(categoryName), color: 'defaultColor' };
    });
  }

    return (
        <div style={{ border: '1px solid #DFE1E6'}}>
            <Table className="table" stickyHeader >
                <TableHead >

                    <TableRow>
                        <TableCell size="small" style={{ fontWeight: 'bold', fontSize: '14px', color: '#637381' }} sx={{ paddingLeft: '10px' }}>
                            <Stack direction="row" alignItems="center" spacing={0.3} sx={{ flexShrink: 0 }}>{columns[0].icon} <span style={{ paddingLeft: '5px' }}>{columns[0].name}</span></Stack>
                        </TableCell>
                  
                        <TableCell size="small" style={{ fontWeight: 'bold', fontSize: '14px', color: '#637381' }} sx={{ paddingLeft: '13px' }}>
                            <Stack direction="row" alignItems="center" spacing={0.3} sx={{ flexShrink: 0 }}>{columns[1].icon} <span style={{ paddingLeft: '5px' }}>{columns[1].name}</span></Stack>
                        </TableCell>
                    
                        <TableCell size="small" style={{ fontWeight: 'bold', fontSize: '14px', color: '#637381' }} sx={{ paddingLeft: '13px', minWidth: '150px' }}>
                            <Stack direction="row" alignItems="center" spacing={0.3} sx={{ flexShrink: 0 }}>{columns[3].icon} <span style={{ paddingLeft: '5px' }}>{columns[3].name}</span></Stack>
                        </TableCell>
                            <TableCell size="small" style={{ fontWeight: 'bold', fontSize: '14px', color: '#637381' }} sx={{ paddingLeft: '13px', minWidth: '180px' }}>
                            <Stack direction="row" alignItems="center" spacing={0.3}>{columns[4].icon} <span style={{ paddingLeft: '5px' }}>{columns[4].name}</span></Stack>
                        </TableCell>
                        <TableCell size="small" style={{ fontWeight: 'bold', fontSize: '14px', color: '#637381' }} sx={{ paddingLeft: '13px', minWidth: '210px' }}>
                            <Stack direction="row" alignItems="center" spacing={0.3} sx={{ flexShrink: 0 }}>{columns[columns.length - 1].icon} <span style={{ paddingLeft: '5px' }}>{columns[columns.length - 1].name}</span></Stack>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody sx={{ backgroundColor: 'white' }}>
                    {rows.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell size="small" sx={{ paddingLeft: '10px' }}>
                                <Stack direction="row" alignItems="center" spacing={.9} sx={{ width: '100%' }}>
                                    <CheckboxIcon  />
                                    <Stack direction="row" alignItems="center" spacing={0.3} sx={{ border: '1px solid #DFE1E6', borderRadius: '15px', paddingRight: '5px', paddingLeft: '3px', width: 'auto', paddingTop: '2px', paddingBottom: '2px' }}> <CompanyAvatar companyName={row.company} /> <span style={{ paddingLeft: '5px' }}>{row.company}</span></Stack>
                                </Stack>
                            </TableCell>
                  
                            <TableCell size="small" sx={{ paddingLeft: '13px' }}>
                                {row.list && <Chip 
                                    label={capitalize.words(row.list)} 
                                    size="small" 
                                    sx={{ 
                                        backgroundColor: getColorByName(lists.find((list) => list.name.toLowerCase() === row.list.toLowerCase())?.color || 'defaultColor')?.backgroundColor, 
                                        color: getColorByName(lists.find((list) => list.name.toLowerCase() === row.list.toLowerCase())?.color || 'defaultColor')?.textColor 
                                    }} 
                                />}

                            </TableCell>
                            
                            
                            <TableCell size="small" sx={{ paddingLeft: '13px' }}>
                                <Chip label={row.status.name} size="small" sx={{ backgroundColor: getColorByName(row.status.color)?.backgroundColor, color: getColorByName(row.status.color)?.textColor }} />

                            </TableCell>
                            <TableCell size="small" sx={{ paddingLeft: '13px' }}>
                                <ActivityCell content={row.last_activity} />
                            </TableCell>
                            <TableCell size="small" sx={{ paddingLeft: '13px'}}> <Stack direction="row" alignItems="center" alignContent="left" justifyContent="left" spacing={1} sx={{ width: '100%' }}>
                                {getCategories(row.categories).map((category) => (
                                    <Chip key={category.name} label={category.name} size="small" sx={{ backgroundColor: getColorByName(category.color)?.backgroundColor, color: getColorByName(category.color)?.textColor }} />
                                ))}
                                </Stack></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

export default CRMTable;

const ActivityCell = ({ content }: { content: any }) => {


    const [latestActivityIcon, setLatestActivityIcon] = useState('fluent:shifts-activity-20-filled');
    const [formattedlast_activityDate, setFormattedlast_activityDate] = useState('');

    useEffect(() => {
        if (content && typeof content === 'object' && 'created_at' in content) {
            const updateFormattedDate = () => {
                if (content.created_at) {
                    let icon = 'fluent:shifts-activity-20-filled';
                    if (content.type === 'note') {
                        icon = 'material-symbols:sticky-note-2-outline';
                    } else if (content.type === 'email') {
                        icon = 'ic:outline-email';
                    } else if (content.type === 'call') {
                        icon = 'mdi:call-outline';
                    } else if (content.type === 'sms') {
                        icon = 'material-symbols:sms-outline';
                    } else if (content.type === 'people') {
                        icon = 'mdi:user-outline';
                    } else if (content.type === 'deal') {
                        icon = 'mdi:deal-outline';
                    } else if (content.type === 'task') {
                        icon = 'material-symbols:task-outline';
                    }
                    setLatestActivityIcon(icon);
                    setFormattedlast_activityDate(moment(content.created_at).fromNow());
                }
            };

            updateFormattedDate();
            const now = moment();
            const createdAt = moment(content.created_at);
            const interval = now.diff(createdAt, 'minutes') < 1 ? 1000 : 60000; // Update every second if less than a minute, else every minute
            const intervalId = setInterval(updateFormattedDate, interval);

            return () => clearInterval(intervalId); // Cleanup interval on component unmount
        }
    }, [content]);

    return <div>
     <Stack direction="row" alignItems="center" alignContent="center" justifyContent="center" spacing={1} sx={{ width: '100%' }}>
            {latestActivityIcon && formattedlast_activityDate && <Icon icon={latestActivityIcon} color={'#637381'} />}
            {formattedlast_activityDate ? (
                    <span style={{ flexGrow: 1, textOverflow: 'ellipsis', overflow: 'hidden' }}>
                        {formattedlast_activityDate}
                    </span>
            ) : (
                <span style={{ flexGrow: 1, textOverflow: 'ellipsis', overflow: 'hidden', color: 'text.disabled' }}>
                    No activity
                </span>
            )}
        </Stack>
    </div>
}

const BrandLogo = ({ brandDomain, brandName }: { brandDomain: string, brandName: string }) => {

    const getAvatarSrc = () => {
        switch (brandDomain.toLowerCase()) {
            case 'apple.com':
                return apple;
            case 'a16z.com':
                return a16z;
            case 'airbnb.com':
                return airbnb;
            case 'disney.com':
                return disney;
            case 'google.com':
                return google;
            case 'microsoft.com':
                return microsoft;
            case 'nvidia.com':
                return nvidia;
            case 'stripe.com':
                return stripe;
            case 'warnerbros.com':
                return warnerbros;
            case 'shopify.com':
                return shopify;
            case 'meta.com':
                return meta;
            case 'sequoiacap.com':
                return sequoia;
            case 'microsoft.com':
                return microsoft;
            default:
                return '';
        }
    }

    return (
        <Avatar src={getAvatarSrc()} alt={brandName} sx={{ width: 24, height: 24, borderRadius: '50%' }}>
            {brandName.charAt(0).toUpperCase()}
        </Avatar>
    );
}


const CompanyAvatar = ({ companyName }: { companyName: string }) => {

    const getAvatarSrc = () => {
       switch (companyName.toLowerCase()) {
        case 'apple':
            return apple;
        case 'google':
            return google;
        case 'sequoia capital':
            return sequoia;
        case 'stripe':
            return stripe;
        case 'nvidia':
            return nvidia;
        case 'warner bros':
            return warnerbros;
        case 'meta':
            return meta;
        case 'disney':
            return disney;
        case 'shopify':
            return shopify;
        case 'a16z':
            return a16z;
        case 'microsoft':
            return microsoft;
        case 'airbnb':
            return airbnb;
        default:
            return '';
       }
    }

    return (
        <Avatar src={getAvatarSrc()} alt={companyName} sx={{ width: 20, height: 20, borderRadius: '50%', border: '0.5px solid #DCE0E4' }} >
            {companyName.charAt(0).toUpperCase()}
        </Avatar>
    );
}




// Using for Checkbox
function CheckboxIcon(props: SvgIconProps) {
    return (
      <SvgIcon {...props} >
        <path d="M17.9 2.318A5 5 0 0 1 22.895 7.1l.005.217v10a5 5 0 0 1-4.783 4.995l-.217.005h-10a5 5 0 0 1-4.995-4.783l-.005-.217v-10a5 5 0 0 1 4.783-4.996l.217-.004h10Zm-.5 1.5h-9a4 4 0 0 0-4 4v9a4 4 0 0 0 4 4h9a4 4 0 0 0 4-4v-9a4 4 0 0 0-4-4Z" fill="#637381" />
      </SvgIcon>
    );
  }
  



// ----------------------------------------------------------------------


