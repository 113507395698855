import React from 'react';
import Page from './components/Page';
import { Divider } from '@mui/material';

const TermsOfService = () => {
  return (
    <Page title="Terms of service">
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
      <h3>{`Effective: Tue Nov 05, 2024`}</h3>
    </div>
    <Divider sx={{borderTop: '1px solid #DFE1E6', width: '100%'}} />

    <div style={{ padding: '20px' }}>
    <p><strong>Welcome to Zweelie!</strong> Before accessing any of our services, please read these Terms of Service ("Terms") carefully. These Terms, along with our Privacy Policy, govern your use of <strong>Zweelie</strong> and <strong>Emily</strong>, including any content, features, and applications (such as the iPhone app) that are part of our platform. By using our Services, you agree to comply with these Terms and our Fair Use Policy.</p>

<p>We may update these Terms periodically, so please review them from time to time. If you continue to use the Services after updates, you accept the modified Terms.</p>
<h3>1. Who We Are and What We Do</h3>
  <p><strong>Zweelie</strong> is a productivity platform that provides a suite of tools for task management, CRM, calendar organization, and more. Paired with <strong>Emily</strong>, our voice-enabled assistant, <strong>Zweelie</strong> helps businesses and individuals streamline their workflows, manage tasks, and stay organized. <strong>Emily</strong> is available on the <strong>Zweelie</strong> iPhone app, offering unique, emotionally aware assistance to enhance productivity.</p>
  <h3>2. Account Creation and Access</h3>
  <p>To access <strong>Zweelie</strong>, you must create an account, providing accurate and complete information. You are responsible for maintaining the confidentiality of your account credentials and for any activity that occurs under your account. We may require additional verification, such as a phone number, to secure your account.</p>

  <h3>3. Use of Our Services</h3>
  <p>You may use <strong>Zweelie</strong> and <strong>Emily</strong> only in compliance with these Terms and our Fair Use Policy. Your use of the Services must be lawful and respectful to others. We prohibit certain activities, including but not limited to:</p>
  <ul>
    <li><strong>Prohibited Content:</strong> Do not use vulgar language, send explicit content, promote violence, or engage in NSFW (not safe for work) or adult content. Threats or abusive behavior toward others, including <strong>Zweelie</strong> employees, is not tolerated and may result in immediate termination of your account.</li>
    <li><strong>Medical and Legal Limitations:</strong> <strong>Emily</strong> is not a licensed medical professional or therapist. While <strong>Emily</strong> may pick up on emotional cues and offer general support, she is not qualified to provide medical or legal advice. Always consult a licensed doctor or attorney for such needs.</li>
    <li><strong>Reliance on Outputs:</strong> <strong>Emily</strong> is designed to assist with tasks, but her outputs may contain inaccuracies. You should verify all outputs before relying on them, as <strong>Zweelie</strong> is not liable for any errors or inaccuracies.</li>
  </ul>

  <h3>4. Prohibited Activities</h3>
  <p>You agree not to:</p>
<ul>
  <li>Use <strong>Zweelie</strong> or <strong>Emily</strong> to develop competing products, train AI models, or engage in unauthorized data scraping or data harvesting.</li>
  <li>Access or attempt to access parts of the Services through unauthorized methods, including decompiling, reverse-engineering, or bypassing security measures.</li>
  <li>Introduce harmful software such as viruses or malware, spam, or attempt to overload our systems through DDoS attacks or other means.</li>
  <li>Upload files or software that may cause harm to the platform or impede other users.</li>
  <li>Engage in behavior that restricts others' use of <strong>Zweelie</strong>, exposes us or other users to liability, or disrupts the Services.</li>
  <li>Engage in any activities that violate applicable laws or regulations of any country, including laws related to data privacy, export control, and intellectual property rights.</li>
  <li>Infringe upon or attempt to replicate anyone's copyright, including but not limited to <strong>Zweelie's</strong> copyrights, trademarks, or other intellectual property rights.</li>
</ul>
<p>We reserve the right to terminate accounts without notice for any violations of these prohibited activities, particularly if we detect attempts to circumvent our security measures.</p>
  <h3>5. Unlimited Access During Beta</h3>
  <p>During the beta phase, <strong>Zweelie</strong> offers unlimited access to <strong>Emily</strong> and all platform tools at no additional cost. However, we monitor usage to prevent abuse. If we detect system abuse or attacks, we reserve the right to impose limitations or suspend accounts. Users will be notified in advance if pricing or access policies change after the beta period.</p>

  <h3>6. Data Collection, Usage, and Privacy</h3>
  <p>Our Privacy Policy outlines how we collect, store, and use your personal information. Key points include:</p>
  <ul>
    <li><strong>Data Collection:</strong> We collect data you provide directly, as well as device and usage data.</li>
    <li><strong>Data Security:</strong> All personal data, including interactions with <strong>Emily</strong>, is encrypted both at rest and in transit. However, no method of transmission is 100% secure.</li>
    <li><strong>Data Accessibility:</strong> Only you can access your chat history with <strong>Emily</strong> in your workspace. For workspaces with multiple team members, data such as tasks or shared files are only visible if explicitly shared by you or a workspace admin.</li>
  </ul>

  <h3>7. Reliance on AI and Outputs</h3>
  <p>Artificial intelligence and large language models are evolving technologies, and <strong>Emily</strong> may occasionally produce inaccurate outputs. By using <strong>Emily</strong>, you agree to:</p>
  <ul>
    <li>Independently confirm the accuracy of outputs before relying on them.</li>
    <li>Understand that outputs may not reflect accurate, current, or complete information, and may not represent the views of <strong>Zweelie</strong>.</li>
  </ul>

  <h3>8. Fair Use and Rate Limits</h3>
  <p>To maintain a secure and reliable service, we have implemented fair use and rate limits. If usage exceeds normal limits or appears abusive, we reserve the right to restrict access or terminate accounts to protect our platform.</p>

  <h3>9. Intellectual Property and Usage Restrictions</h3>
  <p><strong>Zweelie</strong> and <strong>Emily</strong> are proprietary services owned and operated by <strong>Zweelie Inc.</strong>, along with its affiliates and partners. You may not copy, modify, distribute, or reverse-engineer any part of our Services without express permission. Unauthorized use of <strong>Zweelie</strong>'s intellectual property, including our name and trademarks, is prohibited.</p>

  <h3>10. Subscription Fees and Payments</h3>
  <ul>
    <li><strong>Fees and Billing:</strong> Access to some features of <strong>Zweelie</strong> may require a subscription. You agree to provide accurate billing information and authorize us to charge your Payment Method for any applicable fees. Fees are generally non-refundable, so please check your order carefully.</li>
    <li><strong>Recurring Charges and Auto-Renewal:</strong> Subscriptions automatically renew at the end of each billing period unless canceled. You will be charged at the beginning of each renewal period until you cancel.</li>
    <li><strong>Subscription Cancellation:</strong> You may cancel your subscription at any time through your account settings. To avoid being charged for the next billing period, cancel at least 24 hours before the end of the current subscription term. Upon cancellation, you will retain access to the Services for the remainder of your paid period, but no refunds will be issued for unused time.</li>
    <li><strong>Changes in Fees:</strong> We may update subscription fees periodically. If fees increase, we will notify you at least 30 days in advance. If you do not accept the new fees, you may cancel before the updated fees apply.</li>
    <li><strong>Third-Party Payment Processors:</strong> If you subscribe through a third-party app distributor (e.g., the App Store), billing policies of the third party will apply.</li>
  </ul>

  <h3>11. Third-Party Integrations</h3>
  <p>Our Services may include third-party integrations or links. We are not responsible for any loss or damage that may result from the use of third-party content, services, or integrations. Use of third-party services is at your own risk.</p>

  <h3>12. Feedback and No Compensation</h3>
<p>We welcome feedback from our users as it helps us improve and enhance <strong>Zweelie</strong> and <strong>Emily</strong> to better serve your needs. If you choose to submit ideas, suggestions, or other feedback about our Services ("Feedback"), you acknowledge and agree to the following terms:</p>

<ul>
  <li><strong>Ownership and Use of Feedback:</strong> By submitting Feedback, you grant <strong>Zweelie Inc.</strong> a perpetual, irrevocable, worldwide, royalty-free, and transferable license to use, modify, distribute, and incorporate your Feedback into our products and services, or otherwise utilize it in any manner, without any obligation to you.</li>
  
  <li><strong>No Expectation of Compensation:</strong> You understand and agree that providing Feedback is entirely voluntary and does not entitle you to any compensation, monetary or otherwise. <strong>Zweelie Inc.</strong> has no obligation to provide payment, credit, or acknowledgment to you in connection with any Feedback you submit, regardless of how it is used or implemented.</li>
  
  <li><strong>No Confidentiality:</strong> Any Feedback you submit is considered non-confidential. <strong>Zweelie Inc.</strong> is under no obligation to keep any submitted Feedback confidential, even if marked as such by you.</li>

  <li><strong>Independent Development:</strong> <strong>Zweelie Inc.</strong> reserves the right to independently develop or obtain ideas, features, or improvements similar to or the same as your Feedback without any obligation to you.</li>
</ul>

<p>By submitting Feedback, you acknowledge that you have read and understood these terms, and you agree to relinquish any claim you may have regarding the use, ownership, or compensation for your Feedback.</p>


  <h3>13. Disclaimer of Warranties and Limitation of Liability</h3>
  <p><strong>YOUR USE OF THE SERVICES IS AT YOUR OWN RISK. WE PROVIDE THE SERVICES "AS IS" WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED. WE ARE NOT LIABLE FOR INDIRECT, INCIDENTAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF YOUR USE OF THE SERVICES. OUR TOTAL LIABILITY FOR DAMAGES WILL NOT EXCEED THE AMOUNT YOU PAID FOR ACCESS TO OUR SERVICES IN THE PAST SIX MONTHS.</strong></p>

  <h3>14. Termination</h3>
  <p>We reserve the right to terminate or suspend your access to <strong>Zweelie</strong> and <strong>Emily</strong> at any time if you violate these Terms, abuse the platform, or fail to comply with applicable laws. In non-severe cases, we may notify you of the issue before taking action.</p>

  <h3>15. Dispute Resolution</h3>
  <p>These Terms are governed by the laws of the State of Delaware. You agree that any disputes will be resolved exclusively in the state or federal courts located in Delaware.</p>

  <h3>16. General Terms</h3>
  <ul>
    <li><strong>Modification of Services:</strong> We may change or discontinue parts of <strong>Zweelie</strong> and <strong>Emily</strong> at any time without notice.</li>
    <li><strong>Severability:</strong> If any provision of these Terms is deemed unenforceable, the remaining provisions remain in effect.</li>
    <li><strong>No Assignment:</strong> You may not transfer your rights or obligations under these Terms to anyone else.</li>
    <li><strong>Export Compliance:</strong> You must comply with all applicable export laws when using our Services.</li>
    <li><strong>Legal Compliance:</strong> We may cooperate with law enforcement and government agencies to enforce our Terms and ensure safety.</li>
  </ul>

  <h3>Contact Us</h3>
  <p>If you have questions or concerns about these Terms, please contact us at:</p>
  <p>Email: support@zweelie.com</p>

    </div>
    </Page>
  );
};

export default TermsOfService;
