import React from 'react';
import './landing.css';
import Home from './Home';
import Header from './components/Header';
import TermsOfService from './TermsOfService'; 
import PrivacyPolicy from './PrivacyPolicy'; 
import FairUsePolicy from './FairUsePolicy';
import Footer from './components/Footer';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'; 
import ScrollToAnchor from './ScrollToAnchor';

function Landing() {
  return (
    <div>  
      <Router>
        <ScrollToAnchor />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/fair-use" element={<FairUsePolicy />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default Landing;
