import React from 'react';
import logo from './logo.svg';
import Landing from './Landing';
function App() {

  return (
    <Landing />
  );
}

export default App;
