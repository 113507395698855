import { Stack, useMediaQuery, useTheme, } from "@mui/material";
import Logo from "./Logo";
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import { CSSProperties } from "react";
import SectionBG from './sectionBG.svg'

const Footer = () => {

    const btnStyle: CSSProperties = {
        padding: '10px 20px',
        border: '1px solid black',
        borderRadius: '4px',
        backgroundColor: 'transparent',
        color: 'black',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        textAlign: 'center',
        fontFamily: 'Public Sans',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        height: 'auto',
    };


    const btnAppleStyle = {
        ...btnStyle,
        backgroundColor: '#000',
        border: 'none',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    return (
        <Stack direction="column" gap="30px" sx={{
            paddingY: !isDesktop ? '40px' : '30px', px: isDesktop ? '10%' : '30px',
            backgroundImage: `url(${SectionBG})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            borderTop: '1px solid #DFE1E6',
        }}>

            <Stack gap={!isDesktop ? '20px' : '0px'} direction={isDesktop ? 'row' : 'column'} justifyContent={isDesktop ? 'space-between' : 'center'} alignItems={isDesktop ? 'center' : 'center'} sx={{ color: '#878499' }}>
                <Logo color="black" />

                <div style={{ color: '#0C082D', textAlign: isDesktop ? 'center' : 'center', whiteSpace: !isDesktop ? 'normal' : 'nowrap', fontSize: '19px', width: !isDesktop ? '100%' : 'auto' }}>
                    Save time and increase your productivity 10x with Zweelie
                </div>
                <a target="_blank" href="https://testflight.apple.com/join/D9dnCU4K" style={{ ...btnAppleStyle, textDecoration: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', width:'173.84px' }}>
                    <div style={{ marginRight: '4px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24"><path fill="white" d="M17.05 20.28c-.98.95-2.05.8-3.08.35c-1.09-.46-2.09-.48-3.24 0c-1.44.62-2.2.44-3.06-.35C2.79 15.25 3.51 7.59 9.05 7.31c1.35.07 2.29.74 3.08.8c1.18-.24 2.31-.93 3.57-.84c1.51.12 2.65.72 3.4 1.8c-3.12 1.87-2.38 5.98.48 7.13c-.57 1.5-1.31 2.99-2.54 4.09zM12.03 7.25c-.15-2.23 1.66-4.07 3.74-4.25c.29 2.58-2.34 4.5-3.74 4.25"/></svg>

                    </div>
                    <span style={{ fontSize: '16px', fontWeight: 600 }}>Get TestFlight</span>
                </a>
            </Stack>


            <Stack gap={!isDesktop ? '30px' : '0px'} direction={!isDesktop ? 'column-reverse' : 'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ color: '#878499', borderTop: '1px solid #DFE1E6', paddingTop: '30px' }}>


                <div style={{ color: '#6A687E' }}>&copy; {new Date().getFullYear()} Zweelie Inc. All rights reserved.</div>
                <Stack direction={!isDesktop ? 'column-reverse' : 'row'} gap={!isDesktop ? '25px' : '25px'} alignItems={'center'} justifyContent={'center'}>

                    <Stack direction={'row'} gap={!isDesktop ? '25px' : '25px'} alignItems={ 'center' } justifyContent={'center'}>

                        <Link to="/terms" style={{ fontSize: '16px', color: '#6A687E', textDecoration: 'none', textAlign: 'center' }} onMouseOver={(e) => e.currentTarget.style.color = '#A9A7A5'} onMouseOut={(e) => e.currentTarget.style.color = '#6A687E'}>Terms of Service</Link>
                        <Link to="/privacy" style={{ fontSize: '16px', color: '#6A687E', textDecoration: 'none', textAlign: 'center' }} onMouseOver={(e) => e.currentTarget.style.color = '#A9A7A5'} onMouseOut={(e) => e.currentTarget.style.color = '#6A687E'}>Privacy Policy</Link>
                        <Link to="/fair-use" style={{ fontSize: '16px', color: '#6A687E', textDecoration: 'none', textAlign: 'center' }} onMouseOver={(e) => e.currentTarget.style.color = '#A9A7A5'} onMouseOut={(e) => e.currentTarget.style.color = '#6A687E'}>Fair Use Policy</Link>

                    </Stack>

                    <Stack direction={'row'} gap={'25px'} alignItems={'center'}>
                        <div style={{ display: 'flex', gap: '10px', width: 'auto' }}>
                            <div style={{ cursor: 'pointer', width: '32px', height: '32px', backgroundColor: '#B5B3BF', borderRadius: '4px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => window.open('https://www.facebook.com/zweelie', '_blank')} >
                                <Icon icon="gg:facebook" color={'#fff'} width={'20px'} height={'20px'} />
                            </div>
                            <div style={{ cursor: 'pointer', width: '32px', height: '32px', backgroundColor: '#B5B3BF', borderRadius: '4px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => window.open('https://www.instagram.com/zweelie_official', '_blank')} >
                                <Icon icon="gg:instagram" color={'#fff'} width={'20px'} height={'20px'} />
                            </div>
                            <div style={{ cursor: 'pointer', width: '32px', height: '32px', backgroundColor: '#B5B3BF', borderRadius: '4px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => window.open('https://www.linkedin.com/company/zweelie', '_blank')} >
                                <Icon icon="ri:linkedin-fill" color={'#fff'} width={'20px'} height={'20px'} />
                            </div>
                            <div style={{ cursor: 'pointer', width: '32px', height: '32px', backgroundColor: '#B5B3BF', borderRadius: '4px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => window.open('https://x.com/officialzweelie', '_blank')} >
                                <Icon icon="pajamas:twitter" color={'#fff'} width={'20px'} height={'20px'} />
                            </div>
                        </div>

                    </Stack>
                </Stack>
            </Stack>
        </Stack>

    )
}

export default Footer;